const MONTH = [
  { label: '1', value: '01' },
  { label: '2', value: '02' },
  { label: '3', value: '03' },
  { label: '4', value: '04' },
  { label: '5', value: '05' },
  { label: '6', value: '06' },
  { label: '7', value: '07' },
  { label: '8', value: '08' },
  { label: '9', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

export const getDate = (type: 'day' | 'month' | 'year') => {
  if (type === 'month') return MONTH;
  let value = [];
  let startValue = new Date().getFullYear();
  let endValue = 1940;
  if (type === 'day') {
    startValue = 31;
    endValue = 1;
  }
  for (let i = startValue; i >= endValue; i--) {
    value.push({ label: i, value: i.toString().length === 1 ? `0${i}` : `${i}` });
  }
  return value;
};
