import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { TextField, Button } from '../../../components';

interface Props {
  mutate: any;
  notificationData?: any;
}

const Form = styled.form`
  .textfield {
    margin-bottom: 20px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
    .submit-button {
      width: 245px;
      margin-top: 20px;
    }
  }
  .publish-status {
    ion-radio {
      --color: #eeeeee;
      --color-checked: #b80629;
    }
  }
`;

const AdminNotificationForm: React.FC<Props> = ({ mutate, notificationData }) => {
  const initialValues = notificationData
    ? notificationData
    : {
        team: [],
        title: '',
        description: '',
        urlTitle: '',
        url: '',
      };

  const validationSchema = yup.object().shape({
    team: yup.array().min(1, 'Please select'),
    title: yup.string().required('Please enter'),
    description: yup.string().required('Please enter'),
    urlTitle: yup.string().required('Please enter'),
    url: yup.string().required('Please enter'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      mutate(values);
    },
  });

  const onTeamChange = (e: any) => {
    formik.setFieldValue('team', e);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        label="Team"
        required={true}
        name="team"
        onChange={onTeamChange}
        value={formik.values.team}
        type="select"
        options={[
          { value: 'team1', label: 'Team1' },
          { value: 'team2', label: 'Team2' },
        ]}
        multiple={true}
        className="textfield"
        error={formik.touched.team && formik.errors.team}
      />
      <TextField
        label="Title"
        required={true}
        name="title"
        count={true}
        onChange={formik.handleChange}
        value={formik.values.title}
        maxlength={50}
        className="textfield"
        error={formik.touched.title && formik.errors.title}
      />
      <TextField
        label="Description"
        required={true}
        name="description"
        type="textarea"
        onChange={formik.handleChange}
        value={formik.values.description}
        count={true}
        maxlength={500}
        className="textfield"
        error={formik.touched.description && formik.errors.description}
      />
      <TextField
        label="URL Title"
        required={true}
        name="urlTitle"
        maxlength={500}
        onChange={formik.handleChange}
        value={formik.values.urlTitle}
        className="textfield"
        error={formik.touched.urlTitle && formik.errors.urlTitle}
      />
      <TextField
        label="URL"
        required={true}
        name="url"
        maxlength={1000}
        onChange={formik.handleChange}
        value={formik.values.url}
        className="textfield"
        error={formik.touched.url && formik.errors.url}
      />
      <div className="button-wrapper">
        <Button size="large" className="submit-button" onClick={() => formik.handleSubmit()}>
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export { AdminNotificationForm };
