import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import React, { useMemo, useState } from 'react';
import { IonIcon, IonSpinner } from '@ionic/react';

import { Header, Pagination, Search, TableComponent } from '../components';
import { fetchOwnersList } from '../services/owners';
import { CSVLink } from 'react-csv';
import { t } from 'i18next';

const Container = styled.div`
  .body {
    .tableTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .download-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #000;
      span {
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
      }
    }

    .table {
      margin-top: 26px;

      .paginate {
        margin-top: 30px;
      }
    }
  }
`;

const differentStatus: any = {
  using: '#0fba53',
  suspended: '#FC6C04',
  deleted: '#B80629',
};

const Text = styled.div<any>`
  color: ${(props) => differentStatus[`${props.value.toLowerCase()}`]};
  font-size: 14px;
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const Email = styled.span`
  line-break: anywhere;
`;

const OwnersList = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<any>(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '120px',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (props: { value: string }) => {
          return <Email>{props.value}</Email>;
        },
        width: '180px',
      },
      {
        Header: 'Account Status',
        accessor: 'status',
        Cell: (props: { value: string }) => {
          return <Text value={props.value}>{props.value}</Text>;
        },
      },
      {
        Header: 'Last Logged in',
        accessor: 'last_logged_in',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span
              onClick={() => {
                history.push(`/owners/edit/${props.row.original.id}`);
              }}
            >
              <IonIcon icon="./assets/icon/edit.svg" className="icon" />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    []
  );

  const { data: ownersData, isLoading } = useQuery('ownersList', fetchOwnersList, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return (
    <Container>
      <Header title="Owners List" />
      <div className="body body-border">
        <div className="tableTop">
          <Search placeholder={t('Search from title')} />
          <CSVLink data={ownersData ? ownersData : []} className="download-wrapper">
            <IonIcon icon="./assets/icon/red-download.svg" className="icon" />
            <span>CSVダウンロード</span>
          </CSVLink>
        </div>
        <div className="table">
          {isLoading ? (
            <IonSpinner name="circles" className="loader" />
          ) : (
            <>
              <TableComponent columns={columns} data={ownersData} />
              <Pagination
                onPageChange={onPageChange}
                totalPages={5}
                currentPage={currentPage}
                pageSize={5}
                totalCount={ownersData?.length}
                className="paginate"
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default OwnersList;
