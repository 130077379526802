import styled from 'styled-components';
import { IonModal } from '@ionic/react';
import DateInput from '../../atoms/DateInput';
import { useTranslation } from 'react-i18next';
import { isoToDateObject } from '../../../utils';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  dateFrom?: string;
  setDateFrom?: any;
  onClick?: any;
  isDownloading?: boolean;
}

const ModalWrapper = styled(IonModal)`
  --height: 495px;
  --width: 500px;
  .m-body {
    padding: 20px;

    .footer {
      width: 100%;
      position: absolute;
      bottom: 20px;

      .divider {
        height: 1px;
        width: 90%;
        background-color: grey;
      }

      .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        width: 90%;

        .close-btn {
          color: #e63051;
        }

        .btn-gray {
          color: green;
        }

        .disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        button {
          background-color: transparent;
          border: 1px solid #b7b7b7;
          cursor: pointer;
          padding: 10px 14px;
          border-radius: 10px;
          width: 45%;

          :hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .react-datepicker-popper {
    transform: translate3d(20px, 100px, 0px) !important;
  }
`;

export const DatePickerModal = ({
  isOpen,
  closeModal,
  dateFrom,
  setDateFrom,
  onClick,
  isDownloading,
}: Props) => {
  const { t } = useTranslation();

  const handleDateChange = (_: string, value: Date | null) => {
    return setDateFrom(value ? value : '');
  };

  const disableDateChange = (): boolean => {
    if (dateFrom === '' || isDownloading) {
      return true;
    } else return false;
  };

  return (
    <ModalWrapper isOpen={isOpen} onDidDismiss={closeModal}>
      <div className="m-body">
        <DateInput
          label={`${t('Please select the start date')}`}
          name="dateFrom"
          value={isoToDateObject(dateFrom)}
          onChange={handleDateChange}
          showTimeSelect={false}
          placeholder="YYYY年MM月DD日"
          customDateFormat="yyyy年MM月dd日"
          maxDate={new Date()}
          disabled={isDownloading}
        />
        <div className="footer">
          <div className="divider"></div>
          <div className="button-wrapper">
            <button
              className={`${disableDateChange() ? 'disabled' : 'btn-gray'}`}
              disabled={disableDateChange()}
              onClick={onClick}
            >
              {t('Download')}
            </button>
            <button
              className={`close-btn ${isDownloading && 'disabled'}`}
              onClick={closeModal}
              disabled={isDownloading}
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
