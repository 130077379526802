import React from 'react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';

import Login from './pages/Login';
import AddVote from './pages/AddVote';
import Inquiry from './pages/Inquiry';
import EditVote from './pages/EditVote';
import NotFound from './pages/NotFound';
import EditOwner from './pages/EditOwner';
import OwnersList from './pages/OwnersList';
import Notifications from './pages/Notifications';
import InquiryDetails from './pages/InquiryDetails';
import AddNotification from './pages/AddNotification';
import AdminEditNotification from './pages/AdminEditNotification';

import AddTeamUser from './pages/AddTeamUser';
import VoteMembers from './pages/VoteMembers';
import EditTeamUser from './pages/EditTeamUser';
import MembershipCard from './pages/MembershipCardPage';
import TeamNotifications from './pages/TeamNotifications';
import TeamUserManagement from './pages/TeamUserManagement';
import TeamVoteManagement from './pages/TeamVoteManagement';
import TeamAddNotification from './pages/TeamAddNotification';
import TeamEditNotification from './pages/TeamEditNotification';
import TeamMemberManagement from './pages/TeamMemberManagement';
import TeamEditMemberManagement from './pages/TeamEditMemberManagement';

import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './utils/ProtectedRoute';

/* Core CSS required for Ionic components to work properly */
import './App.css';
import '@ionic/react/css/core.css';

import { Amplify } from 'aws-amplify';
import { authContext, USER_ROLES } from './utils';
import ToastContainer from './utils/ToastContainer';
import { Sidebar, SuperAdminSidebar } from './components';

// Admin
import AdminAddVote from './pages/AdminAddVote';
import AddAdminUser from './pages/AddAdminUser';
import EditAdminUser from './pages/EditAdminUser';
import AdminEditVote from './pages/AdminEditVote';
import AdminMembershipCard from './pages/AdminMembershipCard';
import AdminUserManagement from './pages/AdminUserManagement';
import AdminVoteManagement from './pages/AdminVoteManagement';
import AdminMemberManagement from './pages/AdminMemberManagement';
import SuperAdminUserManagement from './pages/SuperAdminUserManagement';
import AdminEditMemberManagement from './pages/AdminEditMemberManagement';
import SuperAdminAddUserManagement from './pages/SuperAdminAddUserManagement';
import SuperAdminEditUserManagement from './pages/SuperAdminEditUserManagement';

setupIonicReact();

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
});

const App: React.FC = () => {
  const { user } = authContext();

  return (
    <IonApp>
      <IonReactRouter>
        {user ? user?.role === USER_ROLES.ADMIN ? <SuperAdminSidebar /> : <Sidebar /> : null}

        <ToastContainer />

        <IonRouterOutlet>
          <Route exact path="/" render={() => <Login />} />
          <ProtectedRoute
            exact
            path="/vote-management"
            superAdminComponent={''}
            component={TeamVoteManagement}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/vote-management/add"
            superAdminComponent={''}
            component={AddVote}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/vote-management/edit/:id"
            superAdminComponent={''}
            component={EditVote}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/notification"
            superAdminComponent={''}
            component={TeamNotifications}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/notification/add"
            superAdminComponent={''}
            component={TeamAddNotification}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/notification/edit/:id"
            superAdminComponent={''}
            component={TeamEditNotification}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/user-management"
            superAdminComponent={SuperAdminUserManagement}
            component={TeamUserManagement}
            allowedRoles={[USER_ROLES.TEAM_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/user-management/add"
            superAdminComponent={SuperAdminAddUserManagement}
            component={AddTeamUser}
            allowedRoles={[USER_ROLES.TEAM_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/user-management/edit/:id"
            superAdminComponent={SuperAdminEditUserManagement}
            component={EditTeamUser}
            allowedRoles={[USER_ROLES.TEAM_ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/inquiry"
            superAdminComponent={Inquiry}
            component={''}
            allowedRoles={[]}
          />
          <ProtectedRoute
            exact
            path="/inquiry/edit/:id"
            superAdminComponent={InquiryDetails}
            component={''}
            allowedRoles={[]}
          />
          <ProtectedRoute
            exact
            path="/member-management"
            superAdminComponent={''}
            component={TeamMemberManagement}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/member-management/edit/:id"
            superAdminComponent={''}
            component={TeamEditMemberManagement}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/owners"
            superAdminComponent={OwnersList}
            component={''}
            allowedRoles={[]}
          />
          <ProtectedRoute
            exact
            path="/owners/edit/:id"
            superAdminComponent={EditOwner}
            component={''}
            allowedRoles={[]}
          />
          <ProtectedRoute
            exact
            path="/membership-card"
            superAdminComponent={''}
            component={MembershipCard}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <ProtectedRoute
            exact
            path="/vote-members/:id"
            superAdminComponent={''}
            component={VoteMembers}
            allowedRoles={[USER_ROLES.TEAM_ADMIN, USER_ROLES.TEAM_USER]}
          />
          <Route exact path="/reset-password" render={() => <ResetPassword />} />
          <Route>
            <NotFound />
          </Route>

          {/* ROUTE FOR ADMIN */}
          <ProtectedRoute
            exact
            path="/:id/user-management/"
            superAdminComponent={AdminUserManagement}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/user-management/add"
            superAdminComponent={AddAdminUser}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/user-management/edit/:slug"
            superAdminComponent={EditAdminUser}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />

          <ProtectedRoute
            exact
            path="/:id/vote-management/"
            superAdminComponent={AdminVoteManagement}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/vote-management/add"
            superAdminComponent={AdminAddVote}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/vote-management/edit/:slug"
            superAdminComponent={AdminEditVote}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/vote-members/:slug"
            superAdminComponent={VoteMembers}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />

          <ProtectedRoute
            exact
            path="/:id/member-management/"
            superAdminComponent={AdminMemberManagement}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/member-management/edit/:slug"
            superAdminComponent={AdminEditMemberManagement}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />

          <ProtectedRoute
            exact
            path="/:id/notification"
            superAdminComponent={Notifications}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/notification/add"
            superAdminComponent={AddNotification}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
          <ProtectedRoute
            exact
            path="/:id/notification/edit/:slug"
            superAdminComponent={AdminEditNotification}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />

          <ProtectedRoute
            exact
            path="/:id/membership-card/"
            superAdminComponent={AdminMembershipCard}
            component={''}
            allowedRoles={[USER_ROLES.ADMIN]}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
