import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { Header, TeamUserManagementForm } from '../../../../components';

interface Props {
  handleSubmit: (v: any) => void;
  isMutating: boolean;
}

const Container = styled.div`
  .add-section {
    border: none;
    .add-icon {
      display: none;
    }
    .label {
      color: #b80629;
      font-weight: 400;
      font-size: 16px;
      text-decoration: underline;
    }
  }
`;

const AddAdminUserTemplate = ({ handleSubmit, isMutating }: Props) => {
  const initialValues = {
    username: '',
    name_sei: '',
    name_mei: '',
    furigana_sei: '',
    furigana_mei: '',
    email: '',
    phone: '',
    department: '',
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required('Please enter'),
    name_sei: yup.string().required('Please enter'),
    name_mei: yup.string().required('Please enter'),
    furigana_sei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    furigana_mei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    email: yup.string().email('Invalid email address').required('Please enter'),
    phone: yup.number().required('Please enter').typeError('Please enter a number'),
  });

  const formik = useFormik<any>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Container>
      <Header title="Add User" backButton />
      <div className="body-border">
        <TeamUserManagementForm formik={formik} isMutating={isMutating} type="add" />
      </div>
    </Container>
  );
};

export { AddAdminUserTemplate };
