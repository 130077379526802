import React from 'react';
import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { UploadSection } from '../UploadSection';

interface ImageValue {
  id: string;
  src: string;
}

interface Props {
  formik: any;
  handleUpload: (url: string, fileFormat: string, file: any, fetch: boolean) => void;
  loading?: boolean;
  hasClose?: boolean;
}

const Wrapper = styled.div`
  .upload-wrapper {
    margin-top: 10px;
  }

  .error {
    margin-top: -20px;
    color: red;
    font-weight: 100;
    font-size: 14px;
  }
`;

const ImageOptions: React.FC<Props> = ({
  formik,
  handleUpload,
  loading = false,
  hasClose = true,
}) => {
  const { t } = useTranslation();

  const onDeleteHandler = (id: string) => {
    const newImagesList = formik.values.images.filter((image: ImageValue) => image.id !== id);
    formik.setFieldValue('images', newImagesList);
  };

  return (
    <Wrapper>
      <IonLabel>
        {t('Options')}
        <span className="required">*</span>
        <p className="info">
          {t(
            "You can add up to 5 images. Images must be less than 2 MB with '.jpg/ .png/ .jpeg' file format"
          )}
        </p>
        <span className="info">
          ( {t('Recommended image size')} : 1024 x 768 {t('pixels')} )
        </span>
      </IonLabel>
      <UploadSection
        className="upload-wrapper"
        onDelete={onDeleteHandler}
        formik={formik}
        handleUpload={handleUpload}
        loading={loading}
        hasClose={hasClose}
      />
      <p className="error">{formik.touched.images && t(formik.errors.images)}</p>
    </Wrapper>
  );
};

export { ImageOptions };
