import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { queryClient } from '..';
import { authContext } from '../utils';
import { useToast } from '../utils/ToastContext';
import { MembershipCardTemplate } from '../components';
import { editMembershipCard, editMembershipSalesPeriod, fetchMembershipCards } from '../services';

const MembershipCardPage = () => {
  const { user } = authContext();
  const { showToast } = useToast();

  const [updatingCardId, setUpdatingCardId] = useState<string | undefined>('');
  const { data, isLoading, isFetching } = useQuery(
    ['fetchMembershipCards', user?.teamId],
    fetchMembershipCards,
    {
      cacheTime: 0,
      select: (response) => {
        // sorting array for specific order
        response?.data?.statusMasters?.sort((a: any, b: any) => a?.levelFrom - b?.levelFrom);
        return response?.data;
      },
    }
  );
  const { mutate, isLoading: isMutating } = useMutation(editMembershipCard, {
    onSuccess: () => {
      showToast('Membership card updated successfully', 'success');
      setUpdatingCardId('');
      setTimeout(() => queryClient.invalidateQueries('fetchMembershipCards'), 1000);
    },
    onError: () => {
      showToast('Something went wrong', 'error');
    },
  });
  const { mutate: mutateSalesPeriod, isLoading: isMutatingSalesPeriod } = useMutation(
    editMembershipSalesPeriod,
    {
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries('fetchMembershipCards'), 1000);
        setDateRange(['', '']);
      },
    }
  );

  // For Date Changer
  const [dateRange, setDateRange] = useState<[string | Date, string | Date]>(['', '']);

  return (
    <MembershipCardTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutatingSalesPeriod={isMutatingSalesPeriod}
      mutate={mutate}
      isMutating={isMutating}
      mutateSalesPeriod={mutateSalesPeriod}
      updatingCardId={updatingCardId}
      setUpdatingCardId={setUpdatingCardId}
      dateRange={dateRange}
      setDateRange={setDateRange}
    />
  );
};

export default MembershipCardPage;
