import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { addCircleOutline, chevronBackOutline } from 'ionicons/icons';

const HeaderContainer = styled.div`
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dedbd5;
  width: 100%;
  .back-section {
    display: flex;
    margin-left: 48px;
    align-items: center;
  }
  .back-icon {
    height: 1.5em;
    width: 1.5em;
    cursor: pointer;
  }
  span {
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    opacity: 0.7;
  }
  .add-section {
    margin-right: 35px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #f4758c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 6px;
    .add-icon {
      height: 1.5em;
      width: 1.5em;
      color: #f4758c;
      margin-right: 8px;
    }
    .label {
      font-weight: 400;
      font-size: 18px;
      margin: 0px;
    }
  }

  .csv-section {
    margin-right: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1020px) {
    min-width: calc(1000px + 70px);
  }
`;

interface Props {
  title: string;
  backButton?: boolean;
  addLabel?: string;
  onClick?: any;
  csv?: any;
}

const PAGE_TITLE: any = {
  '/user-management': 'ユーザー一覧',
  '/vote-management': '投票一覧',
  '/vote-members': '投票一覧',
  '/member-management': '会員一覧',
  '/notification': 'お知らせ一覧',
  '/membership-card': 'メンバシップ管理',
  '/owners': 'オーナー一覧',
  '/inquiry': 'お問い合わせ',
};

function Header({ title, backButton, addLabel, onClick, csv }: Props) {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.split('/').length === 2) {
      const path = `/${pathname.split('/')[1]}`;
      document.title = `${PAGE_TITLE[path]} - Fanique`;
    } else if (pathname.split('/').length > 2) {
      const pathcheck = `/${pathname.split('/')[1]}`;
      if (Object.prototype.hasOwnProperty.call(PAGE_TITLE, pathcheck)) {
        const path = `/${pathname.split('/')[1]}`;
        document.title = `${PAGE_TITLE[path]} - Fanique`;
      } else {
        const path = `/${pathname.split('/')[2]}`;
        document.title = `${PAGE_TITLE[path]} - Fanique`;
      }
    }
  }, [title]);

  return (
    <HeaderContainer>
      <div className="back-section">
        {backButton && (
          <IonIcon
            className="back-icon"
            icon={chevronBackOutline}
            onClick={() => history.goBack()}
          />
        )}
        <span>{t(title)}</span>
      </div>
      {addLabel && title && (
        <button className="add-section" onClick={onClick}>
          <IonIcon icon={addCircleOutline} className="add-icon" />
          <p className="label">{t(addLabel)}</p>
        </button>
      )}
      {csv && <div className="csv-section">{csv}</div>}
    </HeaderContainer>
  );
}

export { Header };
