import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { authContext, USER_ROLES } from '../../../utils';
import { Auth } from 'aws-amplify';

const Container = styled.div`
  z-index: 10;
  position: sticky;
  overflow: auto;
  width: 18em;
  .title {
    font-weight: 700;
    text-align: center;
    font-size: 26px;
  }
  ion-icon {
    color: #ffffff;
    height: 25px;
    width: 25px;
  }
`;
const SidebarContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  background: #29c8d2;
  ul {
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    padding-inline-start: 0px !important;
  }
  li {
    margin-left: 10px;
    padding: 0px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
  }

  span {
    margin-right: 12px;
  }

  .active-nav {
    background: #ffffff;
    box-shadow: -3px -2px 3px #f9f4f4 inset, 2px 2px 3px #cccccc inset, -2px -2px 3px #cccccc,
      3px 2px 3px #ffffff;
    border-radius: 30px 0px 0px 30px;
    color: #b80629;
  }
  .active-icon {
    filter: brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(6615%) hue-rotate(343deg)
      brightness(72%) contrast(101%);
  }
`;

const adminMenuItems = [
  {
    key: '1',
    label: 'Vote Management',
    icon: './assets/icon/vote.svg',
    link: '/vote-management',
  },
  {
    key: '2',
    label: 'User Management',
    icon: './assets/icon/addUser.svg',
    link: '/user-management',
  },
  {
    key: '3',
    label: 'Owners List',
    icon: './assets/icon/users.svg',
    link: '/owners',
  },
  {
    key: '4',
    label: 'Notification',
    icon: './assets/icon/notification.svg',
    link: '/notification',
  },
  {
    key: '7',
    label: 'Member Management',
    icon: './assets/icon/users.svg',
    link: '/member-management',
  },
  {
    key: '5',
    label: 'Inquiry',
    icon: './assets/icon/inquiry.svg',
    link: '/inquiry',
  },
  {
    key: '6',
    label: 'Logout',
    icon: './assets/icon/logout.svg',
    link: '/logout',
  },
];

const teamMenuItems = [
  {
    key: '2',
    label: 'User Management',
    icon: './assets/icon/addUser.svg',
    link: '/user-management',
  },
  {
    key: '1',
    label: 'Vote Management',
    icon: './assets/icon/vote.svg',
    link: '/vote-management?list=beforeApplicationVote',
  },
  {
    key: '7',
    label: 'Member Management',
    icon: './assets/icon/users.svg',
    link: '/member-management',
  },
  {
    key: '4',
    label: 'Notification',
    icon: './assets/icon/notification.svg',
    link: '/notification',
  },
  {
    key: '8',
    label: 'Membership Card',
    icon: './assets/icon/membershipCard.svg',
    link: '/membership-card',
  },
  {
    key: '6',
    label: 'Logout',
    icon: './assets/icon/logout.svg',
    link: '/logout',
  },
];

const teamUserMenuItems = [
  {
    key: '1',
    label: 'Vote Management',
    icon: './assets/icon/vote.svg',
    link: '/vote-management?list=beforeApplicationVote',
  },
  {
    key: '7',
    label: 'Member Management',
    icon: './assets/icon/users.svg',
    link: '/member-management',
  },
  {
    key: '4',
    label: 'Notification',
    icon: './assets/icon/notification.svg',
    link: '/notification',
  },
  {
    key: '8',
    label: 'Membership Card',
    icon: './assets/icon/membershipCard.svg',
    link: '/membership-card',
  },
  {
    key: '6',
    label: 'Logout',
    icon: './assets/icon/logout.svg',
    link: '/logout',
  },
];

function Sidebar() {
  const { user } = authContext();

  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [activeNav, setActiveNav] = useState<any>('');
  const [menuList, setMenuList] = useState<any[]>([]);

  const onClickHandler = async (item: any) => {
    if (item?.link === '/logout') {
      setActiveNav(item.key);
      await Auth.signOut();
      history.push('/');
    } else {
      setActiveNav(item.key);
      history.push(item?.link);
    }
  };

  const getNavItemIndexFromPath = (path: string) => {
    if (path.split('/')[1] === 'vote-management') {
      return '1';
    } else if (path.split('/')[1] === 'user-management') {
      return '2';
    } else if (path.split('/')[1] === 'owners') {
      return '3';
    } else if (path.split('/')[1] === 'notification') {
      return '4';
    } else if (path.split('/')[1] === 'inquiry') {
      return '5';
    } else if (path.split('/')[1] === 'logout') {
      return '6';
    } else if (path.split('/')[1] === 'member-management') {
      return '7';
    } else if (path.split('/')[1] === 'membership-card') {
      return '8';
    } else if (path.split('/')[1] === 'vote-members') {
      return '1';
    }
  };

  useEffect(() => {
    const navValue = getNavItemIndexFromPath(location.pathname);
    setActiveNav(navValue);
  }, [location.pathname]);

  useEffect(() => {
    if (user?.role === USER_ROLES.SUPER_ADMIN) {
      setMenuList(adminMenuItems);
    } else if (user?.role === USER_ROLES.TEAM_USER) {
      setMenuList(teamUserMenuItems);
    } else {
      setMenuList(teamMenuItems);
    }
  }, [user?.role]);

  return (
    <Container>
      <SidebarContainer>
        <div className="title">
          <p>Fanique</p>
        </div>
        <ul>
          {menuList &&
            menuList.map((item: any, key: number) => (
              <li
                key={key}
                onClick={() => onClickHandler(item)}
                className={`${activeNav === item.key ? 'active-nav' : ''}`}
              >
                <span>
                  <img
                    src={item.icon}
                    className={`${activeNav === item.key ? 'active-icon' : ''}`}
                  />
                </span>
                <p>{t(item.label)}</p>
              </li>
            ))}
        </ul>
      </SidebarContainer>
    </Container>
  );
}

export { Sidebar };
