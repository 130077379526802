import SubMenu from './SubMenu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { personCircleOutline, chevronUpOutline, chevronDownOutline } from 'ionicons/icons';

import { Loader } from '../../atoms';
import { teamListContext } from '../../../utils/TeamContext';

const NavIcon = styled.span`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 26px;
  color: #000;
`;

const SidebarNav = styled.nav`
  background-color: #29c8d2;
  width: 18em;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const SuperAdminSidebar = () => {
  const [teams, setTeams] = useState<any>([]);
  const { teamList, teamListLoading } = teamListContext();

  const { t } = useTranslation();

  const FANIQUE_ROW = {
    title: 'Fanique',
    icon: personCircleOutline,
    iconClosed: chevronDownOutline,
    iconOpened: chevronUpOutline,
    subNav: [
      {
        title: t('User Management'),
        path: '/user-management',
        icon: '/assets/icon/addUser.svg',
        className: 'custom-icon',
      },
    ],
  };

  useEffect(() => {
    if (teamList) {
      let result: any = {
        title: `チーム`,
        icon: '/assets/icon/teams.svg',
        iconClosed: chevronDownOutline,
        iconOpened: chevronUpOutline,
        subNav: [],
      };

      teamList?.teams?.forEach((t: any) => {
        result = {
          ...result,
          subNav: [
            ...result.subNav,
            {
              title: t?.teamName,
              id: t?.id,
              icon: '/assets/icon/teams.svg',
              iconClosed: chevronDownOutline,
              iconOpened: chevronUpOutline,
              subNav: [
                {
                  title: 'User Management',
                  path: `/${t?.id}/user-management`,
                  icon: '/assets/icon/addUser.svg',
                },
                {
                  title: 'Vote Management',
                  icon: './assets/icon/vote.svg',
                  path: `/${t?.id}/vote-management?list=beforeApplicationVote`,
                },
                {
                  title: 'Member Management',
                  icon: './assets/icon/users.svg',
                  path: `/${t?.id}/member-management`,
                },
                {
                  title: 'Notification',
                  icon: './assets/icon/notification.svg',
                  path: `/${t?.id}/notification`,
                },
                {
                  title: 'Membership Card',
                  icon: './assets/icon/membershipCard.svg',
                  path: `/${t?.id}/membership-card`,
                },
              ],
            },
          ],
        };
      });
      setTeams(result);
    }
  }, [teamList]);

  const LOGOUT_ROW = {
    title: `${t('Logout')}`,
    path: '/logout',
    icon: '/assets/icon/logout.svg',
    className: 'custom-icon',
  };

  return (
    <div>
      <SidebarNav>
        <SidebarWrap>
          <NavIcon>Fanique</NavIcon>
          <SubMenu item={FANIQUE_ROW} />
          {/* {teams?.map((item: any, index: any) => {
            <SubMenu item={item} />;
          })} */}
          {!teamListLoading ? (
            <SubMenu item={teams} />
          ) : (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          <SubMenu item={LOGOUT_ROW} />
        </SidebarWrap>
      </SidebarNav>
    </div>
  );
};

export { SuperAdminSidebar };
