import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { addTeamVote } from '../services';

import { useToast } from '../utils/ToastContext';
import { AddAdminVoteTemplate } from '../components/organisms/CommonTemplate/VoteManagement/AddAdminVoteTemplate';

const AddVote = () => {
  const history = useHistory();
  const { showToast } = useToast();

  const { mutate, isLoading: isMutating } = useMutation(addTeamVote, {
    onSuccess: async () => {
      showToast('Vote added successfully', 'success');
      history.push({
        pathname: `/vote-management`,
        search: '?list=onGoingVote',
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  return <AddAdminVoteTemplate mutate={mutate} isMutating={isMutating} />;
};

export default AddVote;
