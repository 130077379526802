import moment from 'moment';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useState, useMemo } from 'react';

import { API } from '../../../../services/api';
import { useSorting } from '../../../../hooks/useSorting';
import { useToast } from '../../../../utils/ToastContext';
import { authContext, convertToNDigits, extractCoinBalance, toNormalDate } from '../../../../utils';
import {
  Header,
  Loader,
  Pagination,
  TableComponent,
  DatePickerModal,
  SearchMemberMgmt,
} from '../../../../components';

interface Props {
  redirectLink: string;
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchFields: any;
  currentPage: number;
  queryLoading: boolean;
  queryFetching: boolean;
}

const Container = styled.div`
  .table-wrapper {
    margin-top: 26px;

    .table {
      width: 100%;
      overflow: auto !important;

      table {
        width: 250vw;

        th:last-child,
        td:last-child {
          position: sticky;
          right: 0;
          border-left: 1px solid rgba(0, 0, 0, 0.2);
          padding: 0 5px;
        }

        th:last-child {
          background-color: #e4f9fb;
        }

        tr:nth-child(even) td:last-child {
          background-color: #f2f2f2;
        }

        tr:nth-child(odd) td:last-child {
          background-color: #ffffff;
        }
      }
    }

    .paginate {
      margin-top: 30px;
    }
  }
  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download {
      display: flex;
      flex-direction: column;
      align-items: end;
      .download-note {
        font-size: 13px;
        color: red;
      }
    }

    .download-wrapper {
      cursor: pointer;
      color: #000;
      display: flex;
      align-items: center;
      margin-left: 4px;

      .icon {
        margin-right: 15px;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
      }

      ion-spinner {
        width: 16px;
        height: 16px;
        margin-right: 18px;
      }
    }
    .download-disabled {
      cursor: default;
      opacity: 0.5;
    }

    .search {
      margin: 20px 0;
      display: flex;
      align-items: center;

      .input {
        width: 225px;
        margin-right: 10px;
      }

      .button {
        width: 65px;
        height: 42px;
        margin-top: 10px;

        ion-icon {
          width: 20px;
        }
      }
    }
  }
`;

const CsvText = styled.span`
  font-weight: 400 !important;
  font-size: 16px !important;
  text-decoration: underline;
  margin-left: 15px;
  opacity: 1 !important;
`;

const ForCSV = styled.div`
  .d-disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const Text = styled.div<any>`
  color: ${(props) => differentStatus[`${props?.value?.toLowerCase()}`]};

  span {
    padding: 0 8px;
    border: 1px solid ${(props) => differentStatus[`${props?.value?.toLowerCase()}`]};
    border-radius: 4px;
    background-color: ${(props) => cardBackgroundColor[`${props?.value?.toLowerCase()}`]};
  }
`;

const AccStatus = styled.div<{ value?: boolean }>`
  color: ${(props) => (!props?.value ? '#0fba53' : '#B80629')};
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  @media (max-width: 1020px) {
    padding: 0 25px;
  }
`;

interface cards {
  [key: string]: string;
}

const differentStatus: cards = {
  diamond: '#2D2D2D',
  platinum: '#666666',
  gold: '#C69C6D',
  silver: '#747474',
  red: '#E92F4C',
};

const cardBackgroundColor: cards = {
  diamond: '#EAEAEA',
  platinum: '#F0F0F0',
  gold: '#FFF5EA',
  silver: '#F3F3F3',
  red: '#FFE3E8',
};

const MemberManagementTemplate = ({
  redirectLink,
  data,
  isLoading,
  isFetching,
  setCurrentPage,
  setSearchFields,
  currentPage,
  queryLoading,
  queryFetching,
}: Props) => {
  const history = useHistory();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { user } = authContext();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { sortField, sortDirection, handleSort } = useSorting();

  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const openPreview = () => setPreviewOpen(true);
  const closePreview = () => setPreviewOpen(false);

  // For CSV Download
  const [downloading, setDownloading] = useState<boolean>(false);

  const [dateFrom, setDateFrom] = useState<any>('');
  const [isDownloading, setIsDownloading] = useState(false);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  // For Search
  const initialValues = {
    title: '',
    birthday: '',
    exDate: '',
    membershipStatus: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      setSearchFields({
        searchWord: values?.title,
        searchExDate: values?.exDate,
        searchBirthday: values?.birthday,
        searchStatus: values?.membershipStatus,
      });
      onPageChange(1);
      setHasSubmitted(true);
    },
  });

  const setDownloadTimer = () => {
    localStorage.setItem(
      'downloadTimer',
      JSON.stringify({
        userId: user?.userId,
        timer: new Date().getTime() + 5 * 60 * 1000,
      })
    );
  };

  const commonCell = ({ value }: any) => {
    return value ? value : '-';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Membership Status',
        accessor: 'statusnameenglish',
        width: '180px',
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>
              <span>{props?.value}</span>
            </Text>
          );
        },
      },
      {
        Header: 'Membership ID',
        accessor: 'number',
        isSortable: true,
        width: '180px',
        Cell: ({ value }: any) => <div>{convertToNDigits(value)}</div>,
      },
      {
        Header: 'Purchased Date',
        accessor: 'purchasedate',
        Cell: ({ value }: any) => <div>{value ? toNormalDate(value) : '-'}</div>,
      },
      {
        Header: 'Coin Balance',
        accessor: 'totalteamcoin',
        Cell: ({ value }: any) => <div>{extractCoinBalance(value)?.toLocaleString('en-US')}</div>,
      },
      {
        Header: 'Total Coin',
        accessor: 'cumulativeteamcoin',
        Cell: ({ value }: any) => <div>{extractCoinBalance(value)?.toLocaleString('en-US')}</div>,
      },
      {
        Header: 'Expiry Date',
        accessor: 'exdate',
        isSortable: true,
        Cell: ({ value }: any) => <div>{value ? toNormalDate(value) : '-'}</div>,
      },
      {
        Header: 'Name',
        accessor: (row: any) =>
          `${row?.name_sei ? row?.name_sei : ''} ${row?.name_mei ? row?.name_mei : ''}`,
      },
      {
        Header: 'Furigana',
        accessor: (row: any) =>
          `${row?.furigana_sei ? row?.furigana_sei : ''} ${
            row?.furigana_mei ? row?.furigana_mei : ''
          }`,
      },
      {
        Header: 'Email address',
        accessor: 'email',
      },
      {
        Header: 'Date of Birth',
        accessor: 'birthday',
      },
      {
        Header: 'Address',
        accessor: 'address',
        width: '200px',
        Cell: ({ value }: any) => <div>{value !== ' ' ? value : '-'}</div>,
      },
      {
        id: 1,
        Header: 'T-shirt size',
        accessor: 'choices',
        Cell: ({ value }: any) => {
          let result = '-';
          if (value) {
            const parsed = JSON?.parse(value);
            parsed?.forEach((p: any) => {
              if (p?.giftMasterName === 'Tシャツ') {
                result = JSON.parse(p?.userGiftChoice)?.size;
              }
            });
          }
          return result;
        },
      },
      {
        Header: 'Uniform size',
        accessor: 'choices',
        width: '200px',
        Cell: ({ value }: any) => {
          let result = '-';
          if (value) {
            const parsed = JSON.parse(value);
            parsed?.forEach((p: any) => {
              if (p?.giftMasterName === 'ユニフォーム') {
                result = JSON.parse(p?.userGiftChoice)?.size;
              }
            });
          }
          return result;
        },
      },
      {
        id: 2,
        Header: 'Uniform back number',
        accessor: 'choices',
        Cell: ({ value }: any) => {
          let result = '-';
          if (value) {
            const parsed = JSON.parse(value);
            parsed?.forEach((p: any) => {
              if (p?.giftMasterName === 'ユニフォーム') {
                result = JSON.parse(p?.userGiftChoice)?.number;
              }
            });
          }
          return result;
        },
      },
      {
        id: 3,
        Header: 'Uniform back name',
        accessor: 'choices',
        Cell: ({ value }: any) => {
          let result = '-';
          if (value) {
            const parsed = JSON.parse(value);
            parsed?.forEach((p: any) => {
              if (p?.giftMasterName === 'ユニフォーム') {
                if (JSON.parse(p?.userGiftChoice)?.name) {
                  result = JSON.parse(p?.userGiftChoice)?.name;
                }
              }
            });
          }
          return result;
        },
      },
      {
        Header: 'Phone number',
        accessor: 'phone',
        Cell: commonCell,
      },
      {
        Header: 'Discord username',
        accessor: 'discordUser',
        Cell: commonCell,
        width: '290px',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
        Cell: commonCell,
      },
      {
        Header: 'Occupation',
        accessor: 'occupation',
        Cell: commonCell,
      },
      {
        Header: 'Member type',
        accessor: 'istestuser',
        Cell: (props: { value: boolean }) => {
          return props?.value === true ? t('Test Member') : '';
        },
      },
      {
        Header: 'Account Status',
        accessor: 'deleteflag',
        width: '280px',
        Cell: (props: { value: boolean }) => {
          return (
            <AccStatus value={props?.value}>
              {props?.value === true ? t('Deleted') : t('Active')}
            </AccStatus>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: '150px',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span
              onClick={() =>
                history.push(`${redirectLink}/edit/${props.row.original.usermembershipstatusid}`)
              }
            >
              <IonIcon icon="/assets/icon/edit.svg" className="icon" />
            </Span>
          );
        },
        textAlign: 'center',
      } as any,
    ],
    [redirectLink]
  );

  // To download CSV
  const statusUploadInfoCSV = async () => {
    setIsDownloading(true);
    try {
      const { data } = await API.get(
        `${process.env.REACT_APP_API_URL}/admin/statusuplogs/csv?teamId=${
          user?.teamId
        }&dateFrom=${moment(dateFrom).format('YYYY-MM-DD')}`
      );

      const secondAPIResponse = await API.get(
        `/admin/statusuplogs/csvInfo?path=statusupHistory/${user?.teamId}`
      );

      if (data && secondAPIResponse) {
        const csvFile: any = await API.get(
          `/admin/statusuplogs/presignedUrl?path=${data?.path}&expiresIn=60`
        );
        if (csvFile?.data?.url) {
          // Create an anchor element to trigger the download
          const downloadLink = document.createElement('a');
          downloadLink.href = csvFile?.data?.url;
          downloadLink.download = 'file.csv'; // Specify the filename here

          // Append the anchor element to the body and click it to trigger the download
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Clean up: remove the anchor element from the body
          document.body.removeChild(downloadLink);
          showToast('CSV download successful', 'success');
        }
      }
    } catch (err: any) {
      showToast(err?.response?.data?.message || 'Something went wrong', 'error');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleCsvDownload = async () => {
    try {
      setDownloading(true);
      await API.get(
        `/admin/membership/csv?teamId=${user?.teamId}${
          sortField && sortDirection ? `&sortField=${sortField}&direction=${sortDirection}` : ''
        }`
      );
      const csvInfoRes = await API.get(`/admin/membership/csvInfo?teamId=${user?.teamId}`);
      if (csvInfoRes?.data?.status) {
        const urlRes = await API.get(
          `/admin/membership/presignedUrl?path=${csvInfoRes?.data?.path}`
        );
        if (urlRes?.data?.url) {
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = urlRes?.data?.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // Set Download Timer
          setDownloadTimer();
        }
        showToast('CSV download successful', 'success');
      } else {
        // Set Download Timer
        setDownloadTimer();
      }
    } catch (error: any) {
      setDownloading(false);
      if (error) {
        showToast('Something went wrong', 'error');
      }
    } finally {
      setDownloading(false);
    }
  };

  const handleInitCsv = () => {
    handleCsvDownload();
  };

  const checkDownloadTimer = (): boolean => {
    const downloadTimer = localStorage.getItem('downloadTimer');
    if (downloadTimer) {
      const timerObj = JSON.parse(downloadTimer);
      if (timerObj && timerObj?.userId === user?.userId && timerObj?.timer) {
        return timerObj?.timer > new Date().getTime();
      }
      return false;
    }
    return false;
  };

  return (
    <Container>
      <Header
        title="Member Management"
        csv={
          isLoading || isFetching || !data?.memberships || !data?.memberships?.length ? (
            <ForCSV>
              <a className="d-disabled">
                <IonIcon icon="./assets/icon/red-download.svg" />
                <CsvText>{t('Status upload info CSV')}</CsvText>
              </a>
            </ForCSV>
          ) : (
            <ForCSV>
              <a
                onClick={() => {
                  openPreview();
                }}
              >
                <IonIcon icon="./assets/icon/red-download.svg" />
                <CsvText>{t('Status upload info CSV')}</CsvText>
              </a>
            </ForCSV>
          )
        }
      />
      <div className="body-border">
        <div className="topRow">
          <SearchMemberMgmt formik={formik} hasSubmitted={hasSubmitted} />
          {isLoading || isFetching || !data?.memberships || !data?.memberships?.length ? (
            <a className="download-wrapper download-disabled">
              <IonIcon icon="./assets/icon/red-download.svg" className="icon" />
              <span>CSVダウンロード</span>
            </a>
          ) : downloading ? (
            <a className="download-wrapper download-disabled">
              <Loader />
              <span>CSVダウンロード</span>
            </a>
          ) : checkDownloadTimer() ? (
            <div className="download">
              <a className="download-wrapper download-disabled">
                <IonIcon icon="./assets/icon/red-download.svg" className="icon" />
                <span>CSVダウンロード</span>
              </a>
              <span className="download-note">{t('Please try again after sometime.')}</span>
            </div>
          ) : (
            <a className="download-wrapper" onClick={handleInitCsv}>
              <IonIcon icon="./assets/icon/red-download.svg" className="icon" />
              <span>CSVダウンロード</span>
            </a>
          )}
        </div>
        <DatePickerModal
          isOpen={isPreviewOpen}
          closeModal={closePreview}
          setDateFrom={setDateFrom}
          dateFrom={dateFrom}
          onClick={statusUploadInfoCSV}
          isDownloading={isDownloading}
        />
        <div className="table-wrapper">
          <div className="table">
            <TableComponent
              columns={columns}
              data={data?.memberships ?? []}
              handleServerSideSort={handleSort}
              loading={isLoading || isFetching || queryLoading || queryFetching}
            />
          </div>
          <Pagination
            onPageChange={onPageChange}
            totalPages={data?.total ?? 0}
            currentPage={currentPage}
            pageSize={data?.memberships?.length}
            totalCount={data?.memberships?.length}
            className="paginate"
          />
        </div>
      </div>
    </Container>
  );
};

export { MemberManagementTemplate };
