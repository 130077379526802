import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { searchOutline } from 'ionicons/icons';
import { IonIcon, IonInput } from '@ionic/react';

const SearchContainer = styled.div`
  .search-input {
    margin: 10px 20px 10px 0px;
    display: flex;
    align-items: center;
    width: 280px;
    input,
    input:focus {
      background: #ffffff;
      border: 1px solid #8c8989;
      padding: 0px 12px;
      height: 42px;
      border-radius: 5px 0px 0px 5px;
      font-size: 12px;
    }
    .search-icon {
      border: 1px solid #7e7c7c;
      border-radius: 0px 5px 5px 0px;
      border-left: none;
      cursor: pointer;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      ion-icon {
        font-size: 18px;
      }
    }
  }
`;

interface Props {
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  placeholder?: string | null;
  reset?: boolean;
}

export function Search({ setSearchText, setCurrentPage, placeholder, reset = false }: Props) {
  const [searchInput, setSearchInput] = useState<any>('');

  const handleSearch = () => {
    if (setSearchText) setSearchText(searchInput);
    if (setCurrentPage) setCurrentPage(1);
  };

  useEffect(() => {
    if (reset) setSearchInput('');
  }, [reset]);

  return (
    <SearchContainer>
      <div className="search-input">
        <IonInput
          value={searchInput}
          onIonChange={(e) => setSearchInput(e.target.value)}
          placeholder={placeholder ? placeholder : ''}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <div className="search-icon" onClick={handleSearch}>
          <IonIcon icon={searchOutline} />
        </div>
      </div>
    </SearchContainer>
  );
}
