import React, { useState } from 'react';

const useImageUpload = (): {
  fetchUpload: boolean;
  setFetchUpload: React.Dispatch<React.SetStateAction<boolean>>;
  urlPath: string;
  setUrlPath: React.Dispatch<React.SetStateAction<string>>;
  fileFormat: string;
  setFileFormat: React.Dispatch<React.SetStateAction<string>>;
  file: string;
  setFile: React.Dispatch<React.SetStateAction<any>>;
} => {
  const [fetchUpload, setFetchUpload] = useState<boolean>(false);
  const [urlPath, setUrlPath] = useState<string>('');
  const [fileFormat, setFileFormat] = useState<string>('');
  const [file, setFile] = useState<any>();

  return {
    fetchUpload,
    setFetchUpload,
    urlPath,
    setUrlPath,
    fileFormat,
    setFileFormat,
    file,
    setFile,
  };
};

export { useImageUpload };
