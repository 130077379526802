import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonLabel } from '@ionic/react';
import { addCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { TextField } from '../../../components';
interface Props {
  formik: any;
}

const TextFieldOptions: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation();
  const initialOptions = [
    { id: '1', value: '' },
    { id: '2', value: '' },
  ];

  const handleOptionsChange = (e: React.ChangeEvent<HTMLInputElement>, id?: string) => {
    const result = getOptions()?.map((option) => {
      if (option?.id === id) {
        return {
          ...option,
          value: e?.target?.value ?? '',
        };
      } else {
        return option;
      }
    });
    formik?.setFieldValue('selections', getSelectionsStringified(result));
  };

  const onTextFieldDeleteHandler = (index?: number) => {
    const result = getOptions()?.filter((_, idx) => idx !== index);
    formik.setFieldValue('selections', getSelectionsStringified(result));
  };

  // Parses string data into array of {id, value}.
  const getOptions = (): { id?: string; value?: string }[] | [] => {
    if (formik?.values?.selections) {
      const parsedData = JSON.parse(formik?.values?.selections);
      const entries = Object?.entries(parsedData);
      return entries?.map((d: any) => ({ id: d[0], value: d[1] }));
    } else {
      return initialOptions;
    }
  };

  // Adds one more data to string object: {"id1": "value1", "id2": "value2"} to {"id1": "value1", "id2": "value2", "id3": ""}.
  function addOptions(objString: string): string {
    let objToParse =
      objString && objString?.length ? objString : getSelectionsStringified(initialOptions);

    const parsedObj = JSON.parse(objToParse);
    const keys = Object.keys(parsedObj);

    // Find the maximum key and increment it by 1
    const maxKey = Math.max(...keys.map((key) => parseInt(key)));
    const newKey = (maxKey + 1).toString();

    parsedObj[newKey] = '';
    return JSON.stringify(parsedObj);
  }

  // Stringifies array of [{id1, value1}, {id2, value2}] into {"id1": "value1", "id2": "value2"}.
  function getSelectionsStringified(data: { id?: string; value?: string }[]): string {
    const result: { [id: string]: string } = {};
    data?.forEach((item, idx) => {
      if (item) {
        result[idx] = item?.value ?? '';
      }
    });
    if (data) return JSON.stringify(result);
    else return '';
  }

  return (
    <div>
      <IonLabel>
        {t('Options')}
        <span className="required">*</span>
        <span className="info">{t('You can add up to 15 options')}</span>
      </IonLabel>
      <div className="options-wrapper">
        {getOptions()?.map((option, index: number) => (
          <div key={option?.id} className="text-wrapper">
            <TextField
              count
              maxlength={50}
              required
              name="selections"
              placeholder={`${t('Option')} ${index + 1}`}
              className={`option-textfield ${index >= 2 ? 'has-close-icon' : ''}`}
              value={option?.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOptionsChange(e, option?.id)
              }
              error={formik?.touched?.selections ? (option?.value ? '' : 'Please enter') : ''}
            />
            {index >= 2 && (
              <IonIcon
                icon={closeCircleOutline}
                className="close-icon"
                onClick={() => onTextFieldDeleteHandler(index)}
              />
            )}
          </div>
        ))}
        {getOptions()?.length < 15 && (
          <span
            onClick={() =>
              formik?.setFieldValue(`selections`, addOptions(formik?.values?.selections))
            }
          >
            <IonIcon icon={addCircleOutline} className="icon" />
            {t('Add Option')}
          </span>
        )}
      </div>
    </div>
  );
};

export { TextFieldOptions };
