import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SortingRule, useRowSelect, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { Loader } from '../../atoms';

const StyledTable = styled.div`
  width: 100%;
  background-color: #fff;
  position: relative;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      tr {
        background-color: #e4f9fb;

        th {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 12px 0;
          padding-left: 26px;
          text-align: left;
          color: #000;
        }

        th:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        th:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
        .sortable-up-down {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 30px;
          margin-left: 10px;
          ion-icon {
            width: 11px;
            height: 11px;
            background-color: #fff;
            padding: 1px;
            cursor: pointer;
          }
          ion-icon:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
          }
          .selected {
            color: #18a0a9;
          }
        }
      }
    }

    tbody {
      .even {
        background-color: #fff;
      }

      .odd {
        background-color: #eee;
      }

      td {
        padding: 12px 0;
        padding-left: 26px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        max-width: 100px;
      }
      td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .row-selector {
      width: 17px;
      height: 17px;
    }
  }
`;

const LoaderWrapper = styled.tbody`
  tr {
    height: 90px;

    td {
      position: absolute;
      left: 50%;
      border-left: none !important;
      transform: translateX(-50%);
    }
  }
`;

const NoDataWrapper = styled.tbody`
  tr {
    height: 90px;

    td {
      max-width: 200px !important;
      padding-left: 0px !important;
      position: absolute;
      left: 50%;
      border-left: none !important;
      transform: translateX(-50%);
      word-break: keep-all;
    }
  }
`;

interface Props {
  columns: any;
  data: any;
  handleServerSideSort?: (sortBy: SortingRule<object>[]) => void;
  loading?: boolean;
  // enableRowSelect?: boolean;
  // onRowSelection?: any;
  // allDeselect?: any;
}

// const IndeterminateCheckbox: React.FC<any> = React.forwardRef(
//   ({ indeterminate, ...rest }, ref: any) => {
//     const defaultRef = React.useRef<HTMLInputElement>();
//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       if (resolvedRef.current) {
//         resolvedRef.current.indeterminate = indeterminate;
//       }
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input type="checkbox" className="row-selector" ref={resolvedRef} {...rest} />
//       </>
//     );
//   }
// );

const TableComponent: React.FC<Props> = ({
  columns,
  data = [],
  handleServerSideSort,
  loading,
  // enableRowSelect = false,
  // onRowSelection,
  // allDeselect,
}) => {
  const { t } = useTranslation();

  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    state: { sortBy },
    // selectedFlatRows,
    // toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      // This disables default sorting provided by react-table itself, allowing us to do server-side sorting.
      manualSortBy: true,
    },
    useSortBy,
    useRowSelect
    // For row selection
    // (hooks) => {
    //   if (enableRowSelect) {
    //     hooks.visibleColumns.push((columns) => [
    //       {
    //         id: 'selection',
    //         Header: '',
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //         width: '0px',
    //       },
    //       ...columns,
    //     ]);
    //   }
    // }
  );

  const sortableThStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  useEffect(() => {
    if (handleServerSideSort && sortBy?.length) {
      handleServerSideSort(sortBy);
    }
  }, [handleServerSideSort, sortBy]);

  // For row selection
  // useEffect(() => {
  //   if (enableRowSelect) {
  //     const selectedRows = selectedFlatRows.map((row) => row.original);
  //     onRowSelection([...selectedRows]); // Create a new array with the selected rows
  //   }
  // }, [selectedFlatRows]);

  // useEffect(() => {
  //   if (allDeselect) {
  //     toggleAllRowsSelected(false); // Deselect all rows
  //   }
  // }, [allDeselect]);

  return (
    <StyledTable {...getTableProps()}>
      <table>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
              {headerGroup.headers.map((column: any) => (
                <th
                  key={column}
                  {...column.getHeaderProps([
                    {
                      style: column?.isSortable
                        ? {
                            width: column.width,
                            textAlign: column.textAlign,
                          }
                        : {
                            width: column.width,
                            textAlign: column.textAlign,
                          },
                    },
                    // Enabling this will make the entire <th> tag clickable allowing us to sort by clicking anywhere on the <th> area.
                    // column?.isSortable ? column.getSortByToggleProps() : {},
                  ])}
                  title={''}
                >
                  <div style={column?.isSortable ? sortableThStyle : {}}>
                    <span>{t(column.render('Header'))}</span>
                    {/* Sort Indicator */}
                    <div>
                      {column?.isSortable ? (
                        <div className="sortable-up-down">
                          <IonIcon
                            icon={chevronUpOutline}
                            className={column?.isSorted && !column?.isSortedDesc ? 'selected' : ''}
                            onClick={() => column?.toggleSortBy(false)} // toggleSortBy(descending: bool)
                          />
                          <IonIcon
                            icon={chevronDownOutline}
                            className={column?.isSorted && column?.isSortedDesc ? 'selected' : ''}
                            onClick={() => column?.toggleSortBy(true)} // toggleSortBy(descending: bool)
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {loading ? (
          <LoaderWrapper>
            <tr>
              <td>
                <Loader hasWrapper />
              </td>
            </tr>
          </LoaderWrapper>
        ) : data && data?.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, index: any) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row?.id}
                  className={index % 2 === 0 ? 'even' : 'odd'}
                >
                  {row.cells.map((cell: any) => {
                    return (
                      <td
                        key={cell}
                        {...cell.getCellProps({
                          style: {
                            width: cell.column.width,
                            textAlign: cell.column.textAlign,
                            wordBreak: 'break-word',
                          },
                        })}
                      >
                        {/* For Row Selection */}
                        {/* {cell?.column?.id !== 'action' &&
                        !cell.render('Cell').props.cell.value &&
                        cell?.column?.id !== 'selection'
                          ? '-'
                          : cell.render('Cell')} */}
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <NoDataWrapper>
            <tr>
              <td>
                <p>{t('No Data Available')}</p>
              </td>
            </tr>
          </NoDataWrapper>
        )}
      </table>
    </StyledTable>
  );
};

export { TableComponent };
