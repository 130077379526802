import { QueryFunctionContext } from 'react-query';
import { API } from './api';

export const fetchTeamMemberQueryExecutionId = async ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  // For Sort
  const sortField = queryKey[2];
  const sortDirection = queryKey[3];
  // For Search
  const searchWord = queryKey[4];
  const searchExDate = queryKey[5];
  const searchBirthday = queryKey[6];
  const searchStatus = queryKey[7];

  const checkSortDirection = (direction: any): string => {
    return direction === 'asc' ? 'ASC' : 'DESC';
  };

  return API.get(`/admin/membership/startList`, {
    params: {
      teamId,
      // NOTE: params that are null or undefined are not rendered in the URL. But if it is an empty string, then it is rendered in url.
      byNumber: sortField === 'number' ? checkSortDirection(sortDirection) : undefined,
      byExDate: sortField === 'exDate' ? checkSortDirection(sortDirection) : undefined,
      searchWord: searchWord || undefined,
      exDate: searchExDate ? searchExDate : undefined,
      birthday: searchBirthday ? `/${searchBirthday}/` : undefined,
      status: searchStatus || undefined,
    },
  });
};

export const fetchTeamMemberList = async ({ queryKey }: QueryFunctionContext) => {
  const queryExecutionId = queryKey[1];
  const page = queryKey[2];

  return API.get(`/admin/membership/list`, {
    params: {
      queryExecutionId,
      page,
    },
  });
};

export const fetchSingleMemberDataTeam = async ({ queryKey }: QueryFunctionContext) => {
  const userId = queryKey[1];
  return API.get(`/admin/user?userId=${userId}`);
};

export const editMemberManagement = async (details: any) => {
  return API.put(`admin/user?userId=${details?.id}`, details);
};
