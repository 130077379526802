import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

import { authContext } from '../utils';
import { addNewTeamUser } from '../services';
import { AddAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/AddAdminUserTemplate';
import { useToast } from '../utils/ToastContext';

const AddTeamUser = () => {
  const history = useHistory();
  const { showToast } = useToast();

  const { user } = authContext();

  const { mutate, isLoading: isMutating } = useMutation(addNewTeamUser, {
    onSuccess: () => {
      showToast('User added successfully', 'success');
      history.push('/user-management');
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  const handleFormSubmit = (values: any) => {
    mutate({ ...values, teamId: user?.teamId });
  };

  return <AddAdminUserTemplate isMutating={isMutating} handleSubmit={handleFormSubmit} />;
};

export default AddTeamUser;
