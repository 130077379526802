import { useQuery } from 'react-query';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchAdminUserList } from '../services';
import { Header, Pagination, Search, TableComponent } from '../components';

const Container = styled.div`
  .body {
    .table {
      margin-top: 26px;

      .paginate {
        margin-top: 30px;
      }
    }
  }
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const SuperAdminUserManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { data, isLoading, isFetching } = useQuery(
    ['fetchFaniqueAdminUserList', currentPage, searchText],
    fetchAdminUserList,
    {
      select(response) {
        return response?.data;
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        width: '120px',
        accessor: (row: any) =>
          `${row?.name_sei ? row?.name_sei : ''} ${row?.name_mei ? row?.name_mei : ''}`,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: '180px',
      },
      {
        Header: 'Admin Type',
        accessor: 'adminType',
      },
      {
        Header: 'Team name',
        accessor: 'teamId',
        Cell: (props: { value: string }) => {
          if (props?.value === '9e69c197-0261-4bbd-9116-6e0243a5cbb1') {
            return <span>筑波大学</span>;
          } else if (props?.value === '6ae89d3c-0a33-40a0-abdb-c5f01e6e9d4d') {
            return <span>さいたまブロンコス</span>;
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span onClick={() => history.push(`/user-management/edit/${props.row.original.id}`)}>
              <IonIcon icon="./assets/icon/edit.svg" className="icon" />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    []
  );

  return (
    <Container>
      <Header
        title="User List"
        addLabel="Add"
        onClick={() => history.push('/user-management/add')}
      />
      <div className="body body-border">
        <Search
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          placeholder={t('Search from name')}
        />
        <div className="table">
          <TableComponent
            columns={columns}
            data={data?.items ?? []}
            loading={isLoading || isFetching}
          />
          <Pagination
            onPageChange={onPageChange}
            totalPages={data?.total ?? 0}
            currentPage={currentPage}
            pageSize={data?.items?.length}
            totalCount={data?.items?.length}
            className="paginate"
          />
        </div>
      </div>
    </Container>
  );
};

export default SuperAdminUserManagement;
