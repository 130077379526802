import { API } from './api';
import { QueryFunctionContext } from 'react-query';

export const fetchAdminUserList = ({ queryKey }: QueryFunctionContext) => {
  const page = queryKey[1];
  const searchWord = queryKey[2];

  return API.get(`/admin/teamAdministrator/list?page=${page}&searchWord=${searchWord}`);
};

export const addNewAdminUser = (payload: any) => {
  return API.post('/admin/teamAdministrator', payload);
};

export const fetchSingleAdminUserData = async ({ queryKey }: QueryFunctionContext) => {
  return API.get(`/admin/teamAdministrator?adminId=${queryKey[1]}`);
};

export const editAdminUser = (payload: any) => {
  const { id, data } = payload;
  return API.put(`/admin/teamAdministrator?adminId=${id}`, data);
};
