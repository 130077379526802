import moment from 'moment';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authContext, isoToDateArray } from '../../../../utils';
import DateInput from '../../../../components/atoms/DateInput';
import { Header, Loader, MembershipCard as MembershipCardComponent } from '../../../../components';

interface Props {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  isMutatingSalesPeriod: boolean;
  mutate: any;
  isMutating: boolean;
  mutateSalesPeriod: any;
  updatingCardId: string | undefined;
  setUpdatingCardId: React.Dispatch<React.SetStateAction<any>>;
  dateRange: any;
  setDateRange: React.Dispatch<React.SetStateAction<any>>;
  teamId?: string;
}

const Container = styled.div`
  .emptyData {
    display: flex;
    justify-content: center;
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin: 0px 30px 10px;

    @media (max-width: 1020px) {
      width: 1000px;
    }
  }
`;

const DateChanger = styled.div`
  display: flex;
  justify-content: end;
  margin: 10px 40px;
  @media (max-width: 1300px) {
    margin: 10px 30px;
  }
  @media (max-width: 1020px) {
    justify-content: start;
  }
  .label {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
  .date-wrapper {
    width: 270px;
    margin-right: 12px;
  }
  .date-change-btn {
    width: 60px;
    height: 40px;
    background-color: #d9eeef;
    border-radius: 5px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    color: #817f7f;
    margin-top: 10px;
  }
  .active {
    background-color: #0b969e;
    color: #ffffff;
    cursor: pointer;
  }
  .inactive {
    opacity: 0.5;
  }
`;

const MembershipCardTemplate = ({
  data,
  isLoading,
  isFetching,
  isMutatingSalesPeriod,
  mutate,
  isMutating,
  mutateSalesPeriod,
  updatingCardId,
  setUpdatingCardId,
  dateRange,
  setDateRange,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { user } = authContext();

  const [urlData, setUrlData] = useState<string>('');

  // For Date Changer
  const changeDateRange = (value: [string | Date, string | Date]) => {
    return setDateRange(value);
  };
  const disableDateChange = (): boolean => {
    if (Boolean(dateRange[0]) && Boolean(dateRange[1])) {
      return dateRange[0]?.toString()?.length && dateRange[1]?.toString()?.length ? false : true;
    } else return true;
  };

  const handleSalesPeriod = () => {
    mutateSalesPeriod({
      teamId: teamId ? teamId : user?.teamId,
      data: {
        sellingPeriodFrom: moment(dateRange[0]).format('YYYYMMDD'),
        sellingPeriodTo: moment(dateRange[1]).format('YYYYMMDD'),
      },
    });
  };

  return (
    <Container>
      <Header title="Membership Card Management" />
      <DateChanger>
        <div className="label">
          <span>{t('Selling Period')}</span>
        </div>
        <div className="date-wrapper">
          <DateInput
            name="dateRange"
            hasDateRange
            dateRange={isoToDateArray(dateRange)}
            onRangeChange={(name: string, value: any) => changeDateRange(value)}
          />
        </div>
        <button
          className={`date-change-btn ${
            disableDateChange() || isMutatingSalesPeriod ? '' : 'active'
          }`}
          type="button"
          onClick={handleSalesPeriod}
          disabled={disableDateChange() || isMutatingSalesPeriod}
        >
          {t('Save')}
        </button>
      </DateChanger>
      {isLoading || isFetching ? (
        <Loader hasWrapper />
      ) : data?.statusMasters?.length !== 0 ? (
        <div className="cards-wrapper">
          {data?.statusMasters?.map((card: any) => (
            <div key={card?.id}>
              <MembershipCardComponent
                data={card}
                updateCard={mutate}
                loading={isMutating}
                updatingCardId={updatingCardId}
                setUpdatingCardId={setUpdatingCardId}
                urlData={urlData}
                setUrlData={setUrlData}
                teamId={teamId ?? null}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="emptyData">{t('No Data Available')}</div>
      )}
    </Container>
  );
};

export { MembershipCardTemplate };
