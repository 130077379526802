import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { authContext } from '../utils';
import { useToast } from '../utils/ToastContext';
import { editTeamNotification, fetchSingleNotificationTeam } from '../services';
import { EditAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/EditAdminNotificationTemplate';

const TeamEditNotification = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const id = pathname.split('/')[3];
  const { user } = authContext();
  const { showToast } = useToast();

  const { mutate, isLoading: isMutating } = useMutation(editTeamNotification, {
    onSuccess: async () => {
      showToast('Notification updated successfully', 'success');

      history.push({
        pathname: `/notification`,
        search: `?list=${search?.split('?list=')[1] || 'beforePublishing'}`,
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleNotification', id],
    fetchSingleNotificationTeam,
    {
      enabled: !!id,
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <EditAdminNotificationTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default TeamEditNotification;
