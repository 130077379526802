import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { isJSON } from '../../../../utils';
import { Header, Loader, TeamNotificationForm, Toast } from '../../../../components';

interface Props {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  isMutating: boolean;
  mutate: any;
}

const EditAdminNotificationTemplate = ({
  data,
  isLoading,
  isFetching,
  isMutating,
  mutate,
}: Props) => {
  const [initialValues, setInitialValues] = useState<any>({
    id: '',
    title: '',
    content: '',
    urlTitle: '',
    url: '',
    status: '',
    publishDate: undefined,
  });

  const validationSchema = yup.object().shape({
    title: yup.string().required('Please enter'),
    content: yup.string().required('Please enter'),
    status: yup.boolean().required('Please enter'),
    publishDate: yup.string().nullable().required('Please enter'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      mutate({
        id: values?.id,
        title: values?.title,
        content: JSON.stringify({
          content: values?.content,
          urlTitle: values?.urlTitle,
          url: values?.url,
        }),
        status: values?.status,
        publishDate: values?.publishDate,
      });
    },
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        ...data,
        content: isJSON(data?.content) ? JSON.parse(data?.content as any)?.content : data?.content,
        urlTitle: isJSON(data?.content) ? JSON.parse(data?.content as any)?.urlTitle : '',
        url: isJSON(data?.content) ? JSON.parse(data?.content as any)?.url : '',
        status: data?.status ?? '',
      });
    }
  }, [data]);

  return (
    <div>
      <Header title="Edit Notification" backButton />
      {isLoading || isFetching ? (
        <Loader hasWrapper />
      ) : (
        <div className="body-border">
          <TeamNotificationForm loading={isMutating} formik={formik} />
        </div>
      )}
    </div>
  );
};

export { EditAdminNotificationTemplate };
