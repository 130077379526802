import React, { useMemo } from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Header, Search, TableComponent, Pagination } from '../../../../components';

interface Props {
  data: any;
  isLoading: boolean;
  isFetching: boolean;
  redirectLink?: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
}

const Container = styled.div`
  .table {
    margin-top: 26px;

    .paginate {
      margin-top: 30px;
    }
  }
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const Email = styled.span`
  line-break: anywhere;
`;

const UserManagementTemplate = ({
  data,
  isLoading,
  isFetching,
  redirectLink,
  currentPage,
  setCurrentPage,
  setSearchText,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Name',
        accessor: (row: any) =>
          `${row?.name_sei ? row?.name_sei : ''} ${row?.name_mei ? row?.name_mei : ''}`,
      },
      {
        Header: 'Furigana',
        accessor: (row: any) =>
          `${row?.furigana_sei ? row?.furigana_sei : ''} ${
            row?.furigana_mei ? row?.furigana_mei : ''
          }`,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (props: { value: string }) => {
          return <Email>{props.value}</Email>;
        },
        width: '220px',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span onClick={() => history.push(`${redirectLink}/edit/${props.row.original.id}`)}>
              <IonIcon icon="./assets/icon/edit.svg" className="icon" />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [redirectLink]
  );

  return (
    <Container>
      <Header
        title="User List"
        addLabel="Add"
        onClick={() => history.push(`${redirectLink}/add`)}
      />
      <div className="body-border">
        <Search
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          placeholder={t('Search from name')}
        />
        <div className="table">
          <TableComponent
            columns={columns}
            data={data?.TeamUsers ?? []}
            loading={isLoading || isFetching}
          />
          <Pagination
            onPageChange={onPageChange}
            totalPages={data?.total ?? 0}
            currentPage={currentPage}
            pageSize={data?.TeamUsers?.length}
            totalCount={data?.TeamUsers?.length}
            className="paginate"
          />
        </div>
      </div>
    </Container>
  );
};

export { UserManagementTemplate };
