import {
  IonInput,
  IonLabel,
  IonNote,
  IonRadio,
  IonText,
  IonTextarea,
  IonCheckbox,
  IonRadioGroup,
} from '@ionic/react';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div<any>`
  font-size: 15px;
  ion-label,
  ion-text {
    width: ${(props) => (!props.textarea ? '5em' : null)};
    margin-right: 1em;
    margin-bottom: 0.6em;
    font-weight: 400;
    font-size: 14px;
  }

  ion-input {
    caret-color: black;
    font-size: 13px;
    display: block;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;

    input {
      height: 40px;
      padding-right: 8px !important;
    }
  }
  .native-textarea {
    font-size: 13px;
  }
  .white-input {
    caret-color: black;
    background: white;
    border-radius: 0.3em;
    width: 100%;
    font-size: 13px;
    min-height: 3em;
  }
  .white-input .native-input,
  .white-input {
    min-height: 3em;
    width: inherit;
  }
  .textarea-wrapper {
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;
  }
  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }

  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-right {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
    ion-text {
      margin-right: 0px;
      font-size: 12px;
    }
  }
  .text-content {
    width: 100%;

    .label {
      margin-bottom: 10px;
    }
  }
  .required {
    color: #b80629;
    margin-left: 3px;
  }
  ion-item {
    margin-right: 0px;
  }

  .multi-select {
    .select__control {
      border: 1px solid #d8d8d8 !important;
      font-size: 13px;
    }
  }

  .select__control,
  .select__control:focus {
    height: 42px;
    box-shadow: none;
    border-color: hsl(0deg 0% 70%) !important;

    svg {
      fill: #29c8d2;
    }
  }
  .select__multi-value {
    background: #f5f5f5;
    border-radius: 6px;
    svg {
      fill: #b80629;
    }
  }
  .select__indicator-separator,
  .select__clear-indicator {
    display: none;
  }
  .select__menu {
    overflow: auto;
    z-index: 999;
  }

  ion-radio-group {
    display: flex;
    align-items: center;
  }

  .radio-item {
    display: flex;
    align-items: center;

    ion-label {
      min-width: 6.9em;
      width: auto;
      margin-left: 0.5em;
      margin-bottom: 0em;
      font-weight: 400;
      font-size: 12px;
    }
    ion-radio {
      --color: #eeeeee;
    }
    ion-radio.ios {
      width: 20px;
      height: 20px;
      border: 2px solid #d9d9d9;
      border-radius: 50%;
      --color-checked: #29c8d2;
    }
    .radio-checked.ios {
      border-color: #29c8d2;
    }
  }
`;

interface RadioProps {
  error?: any;
  options: any;
  value?: string;
  onChange: (e: any) => any;
  label?: string;
  required?: boolean;
  name: string;
  className?: string;
  disabled?: boolean;
}

interface Props {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  mode?: 'white' | 'normal';
  name: string;
  onKeyDown?: (e: any) => any;
  maxlength?: number;
  type?: string;
  error?: any;
  onChange?: any;
  ref?: any;
  count?: boolean;
  required?: boolean;
  multiple?: boolean;
  options?: any;
  textType?: any;
  passwordInfo?: boolean;
  disabled?: boolean;
  rows?: number;
}

const MODE = {
  normal: 'neomorphs-shadow-in',
  white: 'white-input',
};

export const TextField: React.FC<Props> = React.forwardRef(
  (
    {
      className,
      label,
      placeholder = '',
      name,
      value,
      mode = 'white',
      onKeyDown,
      maxlength = 50,
      error,
      onChange,
      options,
      required = false,
      count = false,
      multiple = false,
      type = 'input',
      textType,
      passwordInfo,
      disabled = false,
      rows = 5,
    }: Props,
    ref: any
  ) => {
    const { t } = useTranslation();

    if (type === 'textarea') {
      return (
        <Wrapper lines="none" textarea={true} error={error} className={className}>
          <div className="text-content">
            <div className="label">
              {label && (
                <IonLabel>
                  {t(label)}
                  {required ? <span className="required">*</span> : null}
                </IonLabel>
              )}
            </div>
            <IonTextarea
              name={name}
              placeholder={t(`${placeholder}`) ?? ''}
              onIonChange={(e) => onChange(e)}
              className={`${MODE[mode]}`}
              maxlength={maxlength}
              onKeyDown={onKeyDown}
              rows={rows}
              cols={100}
              value={value?.toString()}
              disabled={disabled}
            />
            <div className="bottom-wrapper">
              <IonNote slot="error">{error && t(error)}</IonNote>
              {count && (
                <div className="text-right">
                  <IonText>{`${value?.toString().length}/${maxlength}`}</IonText>
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      );
    } else if (type === 'select') {
      return (
        <Wrapper lines="none" error={error} className={className}>
          <div className="text-content">
            <div className="label">
              {label && (
                <IonLabel>
                  {t(label)}
                  {required ? <span className="required">*</span> : null}
                </IonLabel>
              )}
            </div>
            <Select
              isDisabled={disabled}
              isMulti={multiple}
              name={name}
              placeholder={t(`${placeholder}`) ?? ''}
              className={`basic-multi-select multi-select`}
              classNamePrefix="select"
              options={options}
              onChange={(e: any) => onChange(e)}
              value={
                multiple
                  ? value
                  : options.filter(function (option: any) {
                      return option.value === value;
                    })
              }
            />
            <div className="bottom-wrapper">
              <IonNote slot="error">{error && t(error)}</IonNote>
            </div>
          </div>
        </Wrapper>
      );
    }
    return (
      <Wrapper
        lines="none"
        className={`${className} ${error && 'ion-invalid'}`}
        textType={textType}
      >
        <div className="text-content">
          <div className="label">
            {label && (
              <IonLabel className="ion-label">
                {t(label)}
                {required ? <span className="required">*</span> : null}
              </IonLabel>
            )}
          </div>
          <IonInput
            name={name}
            placeholder={t(`${placeholder}`) ?? ''}
            onIonChange={(e) => onChange(e)}
            className={`${MODE[mode]}`}
            maxlength={maxlength}
            onKeyDown={onKeyDown}
            value={value}
            ref={ref}
            type={textType}
            disabled={disabled}
          />
          <div className="bottom-wrapper">
            <IonNote slot="error">{error && t(error)}</IonNote>

            {count && (
              <div className="text-right">
                <IonText>{`${value?.toString().length}/${maxlength}`}</IonText>
              </div>
            )}
            {passwordInfo && (
              <div className="text-right">
                <IonText>{t('※Please enter more than 8 characters')}</IonText>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
);

export const RadioButton: React.FC<RadioProps> = ({
  error,
  value,
  options,
  onChange,
  label,
  required,
  name,
  className,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper lines="none" error={error} className={className}>
      <div className="text-content">
        <div className="label">
          {label && (
            <IonLabel className="ion-label">
              {t(label)}
              {required ? <span className="required">*</span> : null}
            </IonLabel>
          )}
        </div>

        <IonRadioGroup name={name} value={value} onIonChange={onChange}>
          {options?.map((item: any, idx: number) => (
            <div className="radio-item" key={idx}>
              <IonRadio value={item.value} disabled={disabled} />
              <IonLabel>{t(item.label)}</IonLabel>
            </div>
          ))}
        </IonRadioGroup>
        <IonNote slot="error">{error && t(error)}</IonNote>
      </div>
    </Wrapper>
  );
};

interface CheckboxProps {
  label: string;
  checked?: boolean;
  name: string;
  disabled?: boolean;
  onChange: (e: any) => void;
}

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  ion-label {
    font-size: 14px;
  }
  ion-checkbox {
    --border-color: #d9d9d9;
    --border-width: 1px;
    margin-right: 10px;
  }
`;

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, name, onChange, disabled }) => {
  const { t } = useTranslation();

  return (
    <CheckboxWrapper>
      <IonCheckbox
        slot="start"
        checked={checked}
        onIonChange={onChange}
        name={name}
        disabled={disabled}
      />
      <IonLabel>{t(label)}</IonLabel>
    </CheckboxWrapper>
  );
};
