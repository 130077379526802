import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { useToast } from '../utils/ToastContext';
import { editTeamUser, fetchSingleTeamUserData } from '../services';
import { EditAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/EditAdminUserTemplate';

const EditAdminUser = () => {
  const { showToast } = useToast();
  const history = useHistory();
  const id = useParams<{ id: string; slug: string }>();

  const { data, isFetching } = useQuery(['singleUserData', id.slug], fetchSingleTeamUserData, {
    enabled: !!id.slug,
    select: (response) => {
      return response?.data;
    },
  });

  const { mutate, isLoading: isMutating } = useMutation(editTeamUser, {
    onSuccess: () => {
      showToast('User updated successfully', 'success');
      history.push(`/${id.id}/user-management`);
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });
  return (
    <EditAdminUserTemplate
      data={data}
      id={id.slug}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default EditAdminUser;
