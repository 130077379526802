import { IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  filename: string;
  receivedData?: number;
  totalData?: number;
}

const Container = styled.div`
  background-color: #f8f6f5;
  width: 330px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #9de1e6;
  border-radius: 10px 10px 0px 0px;
  position: absolute;
  bottom: 0px;
  right: 45px;
  @media (max-width: 1440px) {
    right: 30px;
  }
  @media (max-width: 1020px) {
    right: 0px;
  }
  .header {
    padding: 12px 16px;
    border-bottom: 1px solid #dcdcdc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0px;
      color: #000000;
    }
    .icons {
      display: flex;
      align-items: center;
      .progress-no-text-icon {
        width: 18px;
        height: 18px;
        margin-right: 24px;
        display: flex;
        align-items: center;
      }
      .down-icon {
        font-size: 14px;
      }
    }
  }
  .description {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filename {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0px;
      color: rgba(0, 0, 0, 0.7);
    }
    .progress-icon {
      width: 26px;
      height: 26px;
    }
  }
`;

export const DownloadStatus = ({
  show,
  setShow,
  title,
  filename,
  receivedData,
  totalData,
}: Props) => {
  // Expand
  const [expand, setExpand] = useState<boolean>(true);
  const handleExpand = () => {
    setExpand(!expand);
  };

  // Progress Bar Duration Animation
  const [progress, setProgress] = useState<number>(20);

  useEffect(() => {
    if (receivedData && totalData) {
      let progressPercent = Math.ceil((receivedData / totalData) * 100);
      setProgress(progressPercent >= 100 ? 100 : progressPercent);
    }
  }, [receivedData, totalData]);

  // Automatically close progress box after "100"%.
  useEffect(() => {
    if (progress >= 100)
      setTimeout(() => {
        setShow(false);
        setProgress(0);
      }, 1000);
  }, [progress]);

  const showProgressBar = (hasText: boolean) => (
    <CircularProgressbar
      value={progress}
      text={hasText ? `${progress}%` : ''}
      styles={buildStyles({ textSize: '24px', pathColor: '#29C8D2', textColor: '#000000' })}
    />
  );

  if (show) {
    return (
      <Container>
        <div className="header" onClick={handleExpand}>
          <p className="title">{title}</p>
          <div className="icons">
            {!expand && <div className="progress-no-text-icon">{showProgressBar(false)}</div>}
            <IonIcon icon={expand ? chevronDownOutline : chevronUpOutline} className="down-icon" />
          </div>
        </div>
        {expand && (
          <div className="description">
            <p className="filename">{filename}</p>
            <div className="progress-icon">{showProgressBar(true)}</div>
          </div>
        )}
      </Container>
    );
  } else return null;
};
