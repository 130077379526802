import { QueryFunctionContext } from 'react-query';
import { API } from './api';
import { UploadImagePayload, UploadImageUrlResponse } from '../types/image-upload';
import axios from 'axios';

export const getUploadImageUrl = async ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const path = queryKey[2];
  const fileFormat = queryKey[3];
  return API.get<UploadImageUrlResponse>(
    `/admin/vote/getIconUploadUrl?teamId=${teamId}&path=${path}&fileFormat=${fileFormat}`
  );
};

export const uploadImage = async (payload: UploadImagePayload) => {
  const { url, data } = payload;
  return axios.post(url ?? '', data);
};
