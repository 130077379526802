import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router';
import React, { useMemo, useState, useEffect } from 'react';

import { Header, TableComponent } from '../components';
import { fetchSingleTeamVoteData, fetchTeamVoteResultData } from '../services';
import { Link } from 'react-router-dom';

interface MemberDetailProps {
  id?: string;
  name?: string;
}

const Container = styled.div`
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 30px;

    @media (max-width: 1020px) {
      margin-left: 15px;
    }
  }

  .link {
    color: #27b0b9;
    font-weight: 500;
  }

  .table-image {
    width: 150px;
  }
`;

const VoteMembers = () => {
  const id = useParams<{ id: string; slug: string }>();

  const { pathname } = useLocation();
  const [mainData, setMainData] = useState<any>(null);

  const {
    data: singleVoteData,
    isLoading: isLoadingSingleVote,
    isFetching: isFetchingSingleVote,
  } = useQuery(['votePreview', id.slug], fetchSingleTeamVoteData, {
    enabled: !!id.slug,
    select: (response) => {
      return response?.data;
    },
  });

  const {
    data: voteResultData,
    isLoading: isLoadingResult,
    isFetching: isFetchingResult,
  } = useQuery(['fetchTeamVoteResultData', id.slug], fetchTeamVoteResultData, {
    enabled: !!id,
    select: (response) => {
      return response?.data;
    },
  });

  const renderMemberData = (memberValue: MemberDetailProps[]) => {
    return memberValue?.map((value: MemberDetailProps, id: number) => (
      <span key={value.id}>
        <Link
          to={
            pathname.split('/')[1] === 'vote-members'
              ? `/member-management/edit/${value?.id}`
              : `/${pathname.split('/')[1]}/member-management/edit/${value?.id}`
          }
          className="link"
        >
          {value?.name}
        </Link>
        {memberValue.length - 1 != id ? <span>, </span> : ''}
      </span>
    ));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Options',
        accessor: 'options',
        Cell: (props: { value: string }) => {
          return (
            <>
              {singleVoteData?.selectionType === 'ImageSelect' ? (
                <IonImg src={props?.value} className="table-image" />
              ) : singleVoteData?.selectionType === 'SingleSelect' ||
                singleVoteData?.selectionType === 'MultipleSelect' ? (
                <p>{props?.value}</p>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'memberDetails',
        Cell: (props: { value: MemberDetailProps[] }) => {
          return <>{props.value?.length !== 0 ? renderMemberData(props?.value) : '-'}</>;
        },
      },
    ],
    [mainData]
  );

  const transformObjectToArray = (
    obj: {
      [key: string]: { voteUsers: any };
    },
    selections?: string
  ): { options: string; memberDetails: any }[] => {
    if (obj && selections) {
      let parsedSelections = JSON.parse(selections);
      let data = Object.keys(obj)?.map((key: string) => ({
        options: key === 'notVoted' ? 'Not Voted' : parsedSelections[key] ?? '-',
        memberDetails: obj[key]?.voteUsers,
      }));
      return data;
    } else return [];
  };

  useEffect(() => {
    if (singleVoteData && voteResultData) {
      const data = transformObjectToArray(voteResultData, singleVoteData?.selections);
      setMainData(data);
    }
  }, [singleVoteData, voteResultData]);

  return (
    <Container>
      <Header title="Vote List" />
      <p className="title">{singleVoteData?.title}</p>
      <div className="body body-border">
        <div className="table">
          <TableComponent
            columns={columns}
            data={mainData ? mainData.slice(0, mainData?.length - 1) : []}
            loading={
              isLoadingResult || isLoadingSingleVote || isFetchingResult || isFetchingSingleVote
            }
          />
        </div>
      </div>
    </Container>
  );
};

export default VoteMembers;
