// Stringifies array of [{id1, src1}, {id2, src2}] into {"id1": "src1", "id2": "src2"}.
export const getImageSelectionsStringified = (data: { id?: string; src?: string }[]): string => {
  const result: { [id: string]: string } = {};
  data?.forEach((item, idx) => {
    if (item) {
      result[idx] = item?.src ?? '';
    }
  });
  if (data) return JSON.stringify(result);
  else return '';
};

// Parses string data into array of {id, src}.
export const getImageOptions = (imageSelections?: string): { id?: string; src?: string }[] | [] => {
  if (imageSelections) {
    const parsedData = JSON.parse(imageSelections);
    const entries = Object?.entries(parsedData);
    return entries?.map((d: any) => ({ id: d[0], src: d[1] }));
  } else {
    return [{ id: '1', src: '' }];
  }
};
