import React from 'react';
import styled from 'styled-components';
import { searchOutline } from 'ionicons/icons';

import { TextField, Button } from '../../atoms';
import DateInput from '../../atoms/DateInput';
import { isoToDateObject } from '../../../utils';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.form`
  display: flex;
  align-items: center;

  .input {
    width: 200px;
    margin-right: 10px;
  }

  .button {
    width: 65px;
    height: 42px;
    margin-top: 10px;

    ion-icon {
      width: 20px;
    }
  }
  .date-wrapper {
    width: 200px;
    margin-right: 10px;
    > div {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 1500px) {
    .input,
    .date-wrapper {
      width: 180px;
    }
  }
  @media (max-width: 1400px) {
    .input,
    .date-wrapper {
      width: 170px;
    }
  }
  @media (max-width: 1360px) {
    .input,
    .date-wrapper {
      width: 160px;
    }
  }
  @media (max-width: 1320px) {
    .input,
    .date-wrapper {
      width: 140px;
    }
  }
  @media (max-width: 1240px) {
    .input,
    .date-wrapper {
      width: 120px;
    }
  }
  @media (max-width: 1150px) {
    .input,
    .date-wrapper {
      width: 110px;
    }
    .select__control {
      overflow: hidden;
    }
  }
  @media (max-width: 1020px) {
    .input,
    .date-wrapper {
      width: 180px;
    }
  }
`;

interface Props {
  formik: any;
  hasSubmitted?: boolean;
}

const SearchMemberMgmt: React.FC<Props> = ({ formik, hasSubmitted }) => {
  const { t } = useTranslation();

  const handleDateChange = (name: string, value: Date | null) => {
    formik.setFieldValue(name, value);
  };

  const handleStatusChange = (e: any) => {
    formik.setFieldValue('membershipStatus', e?.value);
  };

  const handleReset = () => {
    formik?.handleReset();
    if (hasSubmitted) {
      formik?.handleSubmit();
    }
  };

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <TextField
        name="title"
        placeholder={`${t('Search from name')}`}
        className="input"
        onChange={formik.handleChange}
        value={formik.values.title}
      />
      <div className="date-wrapper">
        <DateInput
          name="birthday"
          value={isoToDateObject(formik.values.birthday)}
          onChange={handleDateChange}
          error={formik.touched.birthday && formik.errors.birthday}
          placeholder={`${t('生年月')}`}
          showTimeSelect={false}
          showMonthOnlyWithNoYear
          required
        />
      </div>
      <div className="date-wrapper">
        <DateInput
          name="exDate"
          value={isoToDateObject(formik.values.exDate)}
          onChange={handleDateChange}
          error={formik.touched.exDate && formik.errors.exDate}
          placeholder={`${t('Expiry Date')}`}
          showTimeSelect={false}
          showMonthYearPicker
          required
        />
      </div>
      <TextField
        required
        name="membershipStatus"
        type="select"
        className="input"
        onChange={handleStatusChange}
        value={formik?.values?.membershipStatus}
        options={[
          { value: 'RED', label: t('Red') },
          { value: 'SILVER', label: t('Silver') },
          { value: 'GOLD', label: t('Gold') },
          { value: 'PLATINUM', label: t('Platinum') },
          { value: 'PLATINUM FIRE', label: t('Platinum Fire') },
          { value: 'DIAMOND', label: t('Diamond') },
        ]}
        placeholder="Membership Status"
      />
      <Button slot="icon-only" className="button" icon={searchOutline} />
      <Button
        size="small"
        onClick={handleReset}
        type="button"
        disabled={
          !formik?.values?.title &&
          !formik?.values?.birthday &&
          !formik?.values?.exDate &&
          !formik?.values?.membershipStatus
        }
      >
        {t('Reset')}
      </Button>
    </Wrapper>
  );
};

export { SearchMemberMgmt };
