import { useQuery } from 'react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { isJSON } from '../utils';
import { NotificationTemplate } from '../components';
import { fetchSingleNotificationTeam, fetchTeamNotifications } from '../services';

const TeamNotifications = () => {
  const id = useParams<{ id: string }>();
  const [searchText, setSearchText] = useState<any>('');
  const [currentScreen, setCurrentScreen] = useState('');
  const [currentPage, setCurrentPage] = useState<any>(1);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const savedLocation = useRef({ pathname: '', search: '' });

  const [notificationModalId, setNotificationModalId] = useState<string | undefined>('');

  const {
    data: ownersData,
    isLoading,
    isFetching,
  } = useQuery(
    ['fetchTeamNotification', id.id, currentPage, currentScreen, searchText],
    fetchTeamNotifications,
    {
      enabled: currentScreen !== '',
      select: (response) => {
        return response?.data;
      },
    }
  );

  const {
    data: notification,
    isLoading: isLoadingNotification,
    isFetching: isFetchingNotification,
  } = useQuery(['fetchSingleNotification', notificationModalId], fetchSingleNotificationTeam, {
    enabled: !!notificationModalId,
    select: (response: any) => {
      return {
        ...response.data,
        content: isJSON(response?.data?.content)
          ? JSON.parse(response?.data?.content)
          : response?.data?.content?.replaceAll('\\n', '\n'),
      };
    },
  });

  useEffect(() => {
    const screen = search?.split('?')[1]?.split('=')[1];

    if (history.location.search)
      savedLocation.current = {
        pathname: history.location.pathname,
        search: history.location.search,
      };
    if (pathname === `/${pathname.split('/')[1]}/notification` && screen === undefined) {
      if (
        savedLocation.current.search &&
        savedLocation.current.pathname === history.location.pathname
      ) {
        history.push({
          pathname: savedLocation.current.pathname,
          search: savedLocation.current.search,
        });
      } else {
        history.push(`/${pathname.split('/')[1]}/notification?list=beforePublishing`);
      }
    }
  }, [search]);

  return (
    <NotificationTemplate
      redirectLink={`/${id.id}/notification`}
      ownersData={ownersData}
      isLoading={isLoading}
      isFetching={isFetching}
      currentScreen={currentScreen}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setCurrentScreen={setCurrentScreen}
      setSearchText={setSearchText}
      searchText={searchText}
      notification={notification}
      isLoadingNotification={isLoadingNotification}
      isFetchingNotification={isFetchingNotification}
      setNotificationModalId={setNotificationModalId}
    />
  );
};

export default TeamNotifications;
