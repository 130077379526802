import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IonSpinner } from '@ionic/react';
import { authContext, USER_ROLES } from './utils';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const RestrictedRoute = (AuthComponent: any) => {
  function PublicComponent({ children }: any) {
    const { authenticated, loading, user } = authContext();

    const history = useHistory();

    React.useEffect(() => {
      if (authenticated) {
        if (user?.role === USER_ROLES.SUPER_ADMIN || user?.role === USER_ROLES.TEAM_USER) {
          history.push('/vote-management');
        } else {
          history.push('/user-management');
        }
      }
    }, [authenticated, user]);

    if (loading) {
      return (
        <LoaderWrapper>
          <IonSpinner className="loader" />
        </LoaderWrapper>
      );
    }
    return <>{children}</>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PublicComponent>
          <AuthComponent {...this.props} />
        </PublicComponent>
      );
    }
  };
};

export default RestrictedRoute;
