import { Auth, Hub } from 'aws-amplify';
import React, { useCallback, useState, useEffect, createContext, useContext } from 'react';

type ContextProps = {
  user: any;
  setUser: any;
  loading: any;
  authenticated: any;
};

export const AuthContext = createContext<Partial<ContextProps>>({});

type AuthProviderProps = {
  role?: string | null | undefined;
  children: React.ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const separateRole = (role: string[], data: 'role' | 'teamId') => {
    if (data === 'teamId') {
      return role.filter((item) => item.includes('-') && data === 'teamId');
    } else if (data === 'role') {
      return role.filter((item) => !item.includes('-') && data === 'role');
    }
  };

  const getCurrentSession = async () => {
    try {
      setLoading(true);
      const session = await Auth.currentSession();
      const sessionData = session.getIdToken();

      if (sessionData) {
        const { payload } = sessionData;
        const { 'cognito:username': email, sub, 'cognito:groups': role } = payload;
        const user: any = {
          userId: sub,
          email: email,
          role: separateRole(role, 'role')?.[0],
          teamId:
            separateRole(role, 'role')?.[0] === 'admin'
              ? '14f714cd-48c4-4008-a4f2-cd2e9b68a81f'
              : separateRole(role, 'teamId')?.[0].split('-').slice(0, -1).join('-'),
        };
        setUser(user);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  const initialLoad = useCallback(async () => {
    Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn': {
          getCurrentSession();
          break;
        }
        case 'signUp':
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          break;
        default:
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    initialLoad();
    getCurrentSession();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        authenticated: user !== null,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const authContext = () => useContext(AuthContext);
