import { IonToast } from '@ionic/react';
import React from 'react';

interface Props {
  show: boolean;
  setShow: any;
  message: string;
  type?: string;
}

export const Toast: React.FC<Props> = ({ show, setShow, message, type = 'error' }) => {
  return (
    <IonToast
      isOpen={show}
      onDidDismiss={() => setShow()}
      message={message}
      duration={3000}
      position="top"
      cssClass={type === 'success' ? 'custom-success-toast' : 'custom-error-toast'}
    />
  );
};
