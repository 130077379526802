// import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { authContext } from '../utils';
import { VoteManagementTemplate } from '../components';
import { fetchSingleTeamVoteData, fetchTeamVoteList, fetchTeamVoteResultData } from '../services';

interface ModalData {
  voteModalId?: string;
  selectionType?: 'SingleSelect' | 'MultipleSelect' | 'ImageSelect';
  selections?: string;
  modalTitle?: string;
}

// const CSVWrapper = styled.div<{ selectedRows?: string[] }>`
//   pointer-events: ${(props) => (props?.selectedRows?.length === 0 ? 'none' : 'normal')};

//   .download-wrapper {
//     cursor: pointer;
//     color: ${(props) => (props?.selectedRows?.length === 0 ? '#eee' : '#000')};

//     .icon {
//       margin-right: 15px;
//     }

//     span {
//       font-weight: 400;
//       font-size: 16px;
//       text-decoration: underline;
//     }
//   }
// `;

const TeamVoteManagement = () => {
  const history = useHistory();
  const { user } = authContext();

  const { search, pathname } = useLocation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentScreen, setCurrentScreen] = useState('');
  const [searchText, setSearchText] = useState<string>('');

  const [modalData, setModalData] = useState<ModalData>({
    voteModalId: '',
    selectionType: undefined,
    selections: '',
    modalTitle: '',
  });

  // For Preview Modal
  const [votePreviewId, setVotePreviewId] = useState<string | undefined>('');

  const {
    data: voteData,
    isLoading,
    isFetching,
  } = useQuery(
    ['fetchTeamVoteList', user?.teamId, currentPage, currentScreen, searchText],
    fetchTeamVoteList,
    {
      enabled: currentScreen !== '',
      select: (response) => {
        return response?.data;
      },
    }
  );

  const {
    data: voteResultData,
    isLoading: isLoadingResult,
    isFetching: isFetchingResult,
  } = useQuery(['fetchTeamVoteResultData', modalData?.voteModalId], fetchTeamVoteResultData, {
    enabled: modalData?.voteModalId !== '',
    select: (response) => {
      return response?.data;
    },
  });

  const {
    data: previewData,
    isLoading: loadingPreview,
    isFetching: fetchingPreview,
  } = useQuery(['votePreview', votePreviewId], fetchSingleTeamVoteData, {
    enabled: !!votePreviewId,
    select: (response) => {
      return response?.data;
    },
  });

  const resetPageAndSearch = () => {
    setSearchText('');
    setCurrentPage(1);
  };

  useEffect(() => {
    const screen = search?.split('?')[1]?.split('=')[1];
    if (pathname === '/vote-management' && screen === undefined) {
      history.push('/vote-management?list=beforeApplicationVote');
    }
    if (screen === 'onGoingVote') {
      setCurrentScreen('accepting');
      resetPageAndSearch();
    } else if (screen === 'endedVote') {
      setCurrentScreen('reception_end');
      resetPageAndSearch();
    } else if (screen === 'privateVote') {
      setCurrentScreen('private');
      resetPageAndSearch();
    } else if (screen === 'beforeApplicationVote') {
      setCurrentScreen('before_reception');
      resetPageAndSearch();
    }
  }, [search]);

  return (
    <VoteManagementTemplate
      redirectLink={`/vote-management`}
      voteMemberLink={`/vote-members`}
      currentScreen={currentScreen}
      voteData={voteData}
      isLoading={isLoading}
      isFetching={isFetching}
      voteResultData={voteResultData}
      isLoadingResult={isLoadingResult}
      isFetchingResult={isFetchingResult}
      previewData={previewData}
      loadingPreview={loadingPreview}
      fetchingPreview={fetchingPreview}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      searchText={searchText}
      setSearchText={setSearchText}
      setVotePreviewId={setVotePreviewId}
      modalData={modalData}
      setModalData={setModalData}
    />
  );
};

export default TeamVoteManagement;
