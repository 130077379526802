import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { addTeamVote } from '../services';
import { useToast } from '../utils/ToastContext';
import { AddAdminVoteTemplate } from '../components/organisms/CommonTemplate/VoteManagement/AddAdminVoteTemplate';

const AdminAddVote = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { showToast } = useToast();

  const { mutate, isLoading: isMutating } = useMutation(addTeamVote, {
    onSuccess: async () => {
      showToast('Vote added successfully', 'success');
      history.push({
        pathname: `/${pathname.split('/')[1]}/vote-management`,
        search: '?list=onGoingVote',
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  return <AddAdminVoteTemplate mutate={mutate} isMutating={isMutating} />;
};

export default AdminAddVote;
