export const convertToNDigits = (
  num?: number,
  digits: number = 6,
  preceedingNum: string = '0'
): string => {
  const paddedNum = num?.toString().padStart(digits, preceedingNum);
  return paddedNum ?? '-';
};

export const extractCoinBalance = (value?: string): string | number | undefined => {
  if (typeof value === 'string') {
    const parsedObject = JSON?.parse(value);
    if (parsedObject) {
      const values = Object.values(parsedObject);
      if (values.length > 0) {
        const firstValue = values[values.length - 1];
        if (typeof firstValue === 'string' || typeof firstValue === 'number') return firstValue;
      }
    }
  }
  return undefined;
};
