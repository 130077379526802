export const fetchOwnersList = () => {
  const data = [
    {
      id: 11,
      name: 'Dianne Russell',
      email: 'jackson.graham@example.com',
      status: 'Using',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 12,
      name: 'Jane Cooper',
      email: 'michelle.rivera@example.com',
      status: 'Using',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 13,
      name: 'Floyd Miiles',
      email: 'debbie.baker@example.com',
      status: 'Suspended',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 13,
      name: 'Floyd Miiles',
      email: 'debbie.baker@example.com',
      status: 'Using',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 14,
      name: 'Devon Lane',
      email: 'curtis.weaver@example.com',
      status: 'Deleted',
      last_logged_in: '2022年11月12日 14:12',
    },
  ];
  return data;
};

export const editOwner: any = (values: any) => {
  return values;
};
