import axios from 'axios';
import { EditMembershipCardPayload, EditMembershipSalesPeriodPayload } from '../types';
import { UploadImagePayload, UploadImageUrlResponse } from '../types/image-upload';
import { API } from './api';
import { QueryFunctionContext } from 'react-query';

export const fetchMembershipCards = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  return API.get(`/admin/status/list?teamId=${teamId}`);
};

export const editMembershipCard = async (payload: EditMembershipCardPayload) => {
  const { statusId, data } = payload;
  return API.put(`/admin/status?statusId=${statusId}`, data);
};

export const getMembershipUploadImageUrl = async ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const path = queryKey[2];
  const fileFormat = queryKey[3];
  return API.get<UploadImageUrlResponse>(
    `/admin/status/getStatusImageUploadUrl?teamId=${teamId}&path=${path}&fileFormat=${fileFormat}`
  );
};

export const uploadMembershipCardImage = async (payload: UploadImagePayload) => {
  const { url, data } = payload;
  return axios.post(url ?? '', data);
};

export const editMembershipSalesPeriod = async (payload: EditMembershipSalesPeriodPayload) => {
  const { teamId, data } = payload;
  return API.put(`/admin/membershipSalesPeriod?teamId=${teamId}`, data);
};
