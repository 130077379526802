import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { IonIcon, IonSpinner } from '@ionic/react';

import { fetchInquiryList } from '../services/inquiry';
import { Header, Pagination, Search, TableComponent } from '../components';

const Container = styled.div`
  .body {
    .table {
      margin-top: 26px;

      .paginate {
        margin-top: 30px;
      }
    }
  }
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const Email = styled.span`
  line-break: anywhere;
`;

const Inquiry = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<any>(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Inquiry Title',
        accessor: 'inquiry_title',
        isSortable: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (props: { value: string }) => {
          return <Email>{props.value}</Email>;
        },
        width: '180px',
      },
      {
        Header: 'Inquiry Date & Time',
        accessor: 'last_logged_in',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span
              onClick={() => {
                history.push(`/inquiry/edit/${props.row.original.id}`);
              }}
            >
              <IonIcon icon="./assets/icon/edit.svg" className="icon" />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    []
  );

  const { data, isLoading } = useQuery('inquiryList', fetchInquiryList, {
    retry: 1,
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  return (
    <Container>
      <Header title="Inquiry" />
      <div className="body body-border">
        <Search placeholder={t('Search from inquiry title / name')} />
        <div className="table">
          {isLoading ? (
            <IonSpinner name="circles" className="loader" />
          ) : (
            <>
              <TableComponent columns={columns} data={data} />
              <Pagination
                onPageChange={onPageChange}
                totalPages={5}
                currentPage={currentPage}
                pageSize={5}
                totalCount={data?.length}
                className="paginate"
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Inquiry;
