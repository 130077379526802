import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../utils/ToastContext';
import { editTeamUser, fetchSingleTeamUserData } from '../services';
import { EditAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/EditAdminUserTemplate';

const EditTeamUser = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const { data, isFetching } = useQuery(['singleUserData', id], fetchSingleTeamUserData, {
    enabled: !!id,
    select: (response) => {
      return response?.data;
    },
  });

  const { mutate, isLoading: isMutating } = useMutation(editTeamUser, {
    onSuccess: () => {
      showToast('User updated successfully', 'success');
      history.push('/user-management');
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  return (
    <EditAdminUserTemplate
      data={data}
      id={id}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default EditTeamUser;
