import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { useToast } from '../hooks/useToast';
import RestrictedRoute from '../withRestrictedRoute';
import { Button, TextField, Toast } from '../components';

interface LoginType {
  email: string;
  password: string;
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 500px;
    height: 385px;
    background: #ffffff;
    border: 1px solid rgba(201, 203, 211, 0.5);
    box-shadow: 3px 4px 29px rgba(133, 133, 133, 0.25);
    border-radius: 12px;
    padding: 40px 30px 60px;
    position: relative;

    .link {
      text-decoration: none;
      font-size: 14px;
      color: #29c8d2;
      position: absolute;
      right: 16px;
      bottom: 16px;

      :hover {
        text-decoration: underline;
      }
    }

    .logo {
      width: 100%;
      display: flex;
      justify-content: center;

      .img {
        width: 107px;
      }
    }

    form {
      margin-top: 60px;

      div {
        margin-bottom: 25px;

        ion-label {
          font-size: 18px;
        }
      }

      .btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .btn {
          width: 245px;
          margin-top: 15px;
        }
      }
    }
  }
`;

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useToast(false);
  const [error, setError] = useState('');

  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        const user = await Auth.signIn(formik?.values?.email, formik?.values?.password);
        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          history.push('/reset-password');
        }
      } catch (error: any) {
        setError(error?.message);
        setShowToast(true);
      } finally {
        setIsSigningIn(false);
        setLoading(false);
      }
    }
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required('Please enter'),
    password: yup.string().required('Please enter'),
  });

  const formik = useFormik<LoginType>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    document.title = '管理画面 - Fanique';
  }, []);

  return (
    <Container>
      <Toast show={showToast} setShow={setShowToast} message={t(error)} />

      <div className="wrapper">
        <div className="logo">
          <IonImg src="./assets/images/logo.png" className="img" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              onChange={(evt: any) => formik.handleChange(evt)}
              name="email"
              mode="white"
              label="Username"
              value={formik.values.email}
              required
              error={formik.touched.email && formik.errors.email}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div>
            <TextField
              onChange={(evt: any) => formik.handleChange(evt)}
              name="password"
              mode="white"
              label="Password"
              required
              value={formik.values.password}
              error={formik.touched.password && formik.errors.password}
              textType="password"
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="btn-wrapper">
            <Button
              onClick={formik.handleSubmit}
              size="large"
              className="btn"
              disabled={loading}
              type="submit"
            >
              Login
            </Button>
          </div>
        </form>

        <Link to="/reset-password" className="link">
          {t('If you are logging for the first time, click here')}
        </Link>
      </div>
    </Container>
  );
};

export default RestrictedRoute(Login);
