import React, { useState } from 'react';
import { SortingRule } from 'react-table';

const useSorting = (): {
  sortField: string;
  sortDirection: 'asc' | 'desc' | '';
  handleSort: (sortBy: SortingRule<object>[]) => void;
} => {
  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | ''>('');

  const handleSort = (sortBy: SortingRule<object>[]) => {
    if (sortBy) {
      setSortField(sortBy[0]?.id);
      setSortDirection(sortBy[0]?.desc ? 'desc' : 'asc');
    }
  };

  return { sortField, sortDirection, handleSort };
};

export { useSorting };
