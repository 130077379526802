import { API } from './api';
import { QueryFunctionContext } from 'react-query';
import { EditNotificationPayload, SingleNotificationResponse, TeamProps } from '../types';

export const fetchTeamNotifications = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const page = queryKey[2];
  const tabType = queryKey[3];
  const searchWord = queryKey[4];

  return API.get(
    `/admin/notification/list?teamId=${teamId}&page=${page}&tabType=${tabType}&searchWord=${searchWord}`
  );
};

export const addTeamNotification = async (values: TeamProps) => {
  const { teamId, data } = values;
  return API.post(`/admin/notification?teamId=${teamId}`, data);
};

export const editTeamNotification = async (payload: EditNotificationPayload) => {
  return API.put('/admin/notification', payload);
};

export const fetchSingleNotificationTeam = async ({ queryKey }: QueryFunctionContext) => {
  return API.get<SingleNotificationResponse>(
    `/admin/notification?notificationMasterId=${queryKey[1]}`
  );
};
