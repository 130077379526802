import { useQuery } from 'react-query';
import React, { createContext, useContext } from 'react';

import { fetchAllTeams } from '../services';
import { authContext } from './AuthContext';

export const TeamListContext = createContext<any>({});

export const TeamListProvider: React.FC<any> = ({ children }) => {
  const { user } = authContext();

  const { data, isLoading } = useQuery('fetchAllTeams', fetchAllTeams, {
    enabled: user?.role && user?.role === 'admin' ? true : false,
    select: (response) => {
      return response?.data;
    },
  });

  return (
    <TeamListContext.Provider value={{ teamList: data, teamListLoading: isLoading }}>
      {children}
    </TeamListContext.Provider>
  );
};

export const teamListContext = () => useContext(TeamListContext);
