import * as yup from 'yup';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import React, { useState } from 'react';

import { useToast } from '../hooks/useToast';
import RestrictedRoute from '../withRestrictedRoute';
import { Button, TextField, Toast } from '../components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    width: 500px;
    height: auto;
    background: #ffffff;
    border: 1px solid rgba(201, 203, 211, 0.5);
    box-shadow: 3px 4px 29px rgba(133, 133, 133, 0.25);
    border-radius: 12px;
    padding: 40px 30px 60px;

    .logo {
      text-align: center;
      h2 {
        margin-top: 0px;
      }
    }

    form {
      margin-top: 60px;

      div {
        margin-bottom: 25px;

        ion-label {
          font-size: 18px;
        }
      }

      .btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 0px;

        .btn {
          width: 245px;
          margin-top: 15px;
        }
      }
    }
  }
`;

const ResetPassword = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const [showToast, setShowToast] = useToast(false);

  const handleResetPassword = async () => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        setLoading(true);
        const user = await Auth.signIn(formik?.values?.username, formik?.values?.temporaryPassword);
        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(
            user, // the Cognito User Object
            formik?.values?.newPassword
          );
        }
      } catch (error: any) {
        setError(error?.message);
        setShowToast(true);
      } finally {
        setIsSigningIn(false);
      }
    }
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    username: yup.string().required('Please enter'),
    temporaryPassword: yup.string().required('Please enter'),
    newPassword: yup
      .string()
      .min(8, '※Please enter more than 8 characters')
      .required('Please enter'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      temporaryPassword: '',
      newPassword: '',
    },
    validationSchema,
    onSubmit: handleResetPassword,
  });

  return (
    <Container>
      <Toast show={showToast} setShow={setShowToast} message={t(error)} />

      <div className="wrapper">
        <div className="logo">
          <h2>{t('First time password update')}</h2>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div>
            <TextField
              onChange={(evt: any) => formik.handleChange(evt)}
              name="username"
              mode="white"
              label="Username"
              value={formik.values.username}
              required
              error={formik.touched.username && formik.errors.username}
            />
          </div>
          <div>
            <TextField
              onChange={(evt: any) => formik.handleChange(evt)}
              name="temporaryPassword"
              mode="white"
              label="Temporary password"
              required
              value={formik.values.temporaryPassword}
              error={formik.touched.temporaryPassword && formik.errors.temporaryPassword}
              textType="password"
            />
          </div>
          <div>
            <TextField
              onChange={(evt: any) => formik.handleChange(evt)}
              name="newPassword"
              mode="white"
              label="New password"
              required
              value={formik.values.newPassword}
              error={formik.touched.newPassword && formik.errors.newPassword}
              textType="password"
            />
          </div>
          <div className="btn-wrapper">
            <Button onClick={formik.handleSubmit} size="large" className="btn" disabled={loading}>
              Reset
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default RestrictedRoute(ResetPassword);
