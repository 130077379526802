import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';

import { getDate } from '../../../utils';
import { TextField, RadioButton, Checkbox, Button } from '../../atoms';

const Form = styled.form`
  .input {
    margin-bottom: 20px;
  }
  .disabled {
    input {
      background-color: #ebe9e9;
    }
  }

  ion-radio {
    --color-checked: #b80629;
  }

  .right-field {
    margin-left: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .date-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .delivery-address {
    background-color: #f9f8f8;
    padding: 20px;
    margin-bottom: 20px;
  }

  .phone-section {
    display: flex;

    .input {
      width: 202px;
    }

    span {
      margin: 40px 10px 0px;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 245px;
    }
  }
`;

interface Props {
  formik: any;
}

const AdminMemberManagementForm: React.FC<Props> = ({ formik }) => {
  const [isChecked, setIsChecked] = useState(false);

  const textField1 = useRef<any>(null);
  const textField2 = useRef<any>(null);
  const textField3 = useRef<any>(null);

  const phoneChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'ext_phone' && e.target.value.length === 3) {
      textField2.current.setFocus();
    } else if (e.target.name === 'first_phone') {
      if (e.target.value.length === 4) {
        textField3.current.setFocus();
      } else if (e.target.value.length === 0) {
        textField1.current.setFocus();
      }
    } else if (e.target.name === 'second_phone' && e.target.value.length === 0) {
      textField2.current.setFocus();
    }
  };

  useEffect(() => {
    if (formik?.values) {
      if (isChecked) {
        formik.setFieldValue('delivery_address', formik?.values?.address);
        formik.setFieldValue('delivery_address1', formik?.values?.address1);
        formik.setFieldValue('delivery_po_box', formik?.values?.po_box);
      } else {
        formik.setFieldValue('delivery_address', formik?.values?.delivery_address);
        formik.setFieldValue('delivery_address1', formik?.values?.delivery_address1);
        formik.setFieldValue('delivery_po_box', formik?.values?.delivery_po_box);
      }
    }
  }, [isChecked, formik?.values]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextField
        disabled
        name="team"
        label="Team"
        className="input disabled"
        value={formik?.values?.team}
        onChange={formik.handleChange}
        error={formik.touched.team && formik.errors.team}
      />
      <div className="grid">
        <TextField
          disabled
          name="membership_id"
          label="Membership ID"
          className="input disabled"
          value={formik?.values?.membership_id}
          onChange={formik.handleChange}
          error={formik.touched.membership_id && formik.errors.membership_id}
        />
        <TextField
          disabled
          name="membership_status"
          label="Membership Status"
          className="right-field input disabled"
          value={formik?.values?.membership_status}
          onChange={formik.handleChange}
          error={formik.touched.membership_status && formik.errors.membership_status}
        />
      </div>
      <div className="grid">
        <TextField
          disabled
          name="purchased_date"
          label="Purchased Date"
          className="input disabled"
          value={formik?.values?.purchased_date}
          onChange={formik.handleChange}
          error={formik.touched.purchased_date && formik.errors.purchased_date}
        />
        <TextField
          disabled
          name="expiry_date"
          label="Expiry Date"
          className="right-field input disabled"
          value={formik?.values?.expiry_date}
          onChange={formik.handleChange}
          error={formik.touched.expiry_date && formik.errors.expiry_date}
        />
      </div>
      <div className="grid">
        <TextField
          count
          disabled
          name="lastname"
          maxlength={100}
          label="Last name"
          className="input disabled"
          value={formik?.values?.lastname}
          onChange={formik.handleChange}
          error={formik.touched.lastname && formik.errors.lastname}
        />
        <TextField
          count
          disabled
          maxlength={100}
          name="firstname"
          label="First name"
          className="right-field input disabled"
          value={formik?.values?.firstname}
          onChange={formik.handleChange}
          error={formik.touched.firstname && formik.errors.firstname}
        />
      </div>
      <div className="grid">
        <TextField
          count
          disabled
          maxlength={100}
          name="last_furigana"
          label="Last Furigana"
          className="input disabled"
          value={formik?.values?.last_furigana}
          onChange={formik.handleChange}
          error={formik.touched.last_furigana && formik.errors.last_furigana}
        />
        <TextField
          count
          disabled
          maxlength={100}
          name="first_furigana"
          label="First Furigana"
          className="right-field input disabled"
          value={formik?.values?.first_furigana}
          onChange={formik.handleChange}
          error={formik.touched.first_furigana && formik.errors.first_furigana}
        />
      </div>
      <TextField
        disabled
        name="email_address"
        label="Email address"
        className="input disabled"
        value={formik?.values?.email_address}
        onChange={formik.handleChange}
        error={formik.touched.email_address && formik.errors.email_address}
      />
      <div className="grid">
        <TextField
          disabled
          name="coin_balance"
          label="Coin Balance"
          className="input disabled"
          value={formik?.values?.coin_balance}
          onChange={formik.handleChange}
          error={formik.touched.coin_balance && formik.errors.coin_balance}
        />
        <TextField
          disabled
          name="last_qr_scanned"
          label="Last QR code scanned on"
          className="right-field input disabled"
          value={formik?.values?.last_qr_scanned}
          onChange={formik.handleChange}
          error={formik.touched.last_qr_scanned && formik.errors.last_qr_scanned}
        />
      </div>
      <RadioButton
        name="gender"
        label="Gender"
        options={[
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
          { value: 'no_answer', label: 'No answer' },
        ]}
        onChange={formik.handleChange}
        value={formik?.values?.gender}
        className="input"
        error={formik.touched.gender && formik.errors.gender}
      />
      <div className="date-wrapper">
        <TextField
          required
          name="year"
          className="input"
          label="Date of Birth"
          type="select"
          options={getDate('year')}
          value={formik?.values?.year}
          onChange={formik.handleChange}
          error={formik.touched.year && formik.errors.year}
        />
        <TextField
          name="month"
          label=" "
          className="input right-field"
          type="select"
          options={getDate('month')}
          value={formik?.values?.month}
          onChange={formik.handleChange}
          error={formik.touched.month && formik.errors.month}
        />
        <TextField
          name="day"
          label=" "
          className="input right-field"
          type="select"
          options={getDate('day').reverse()}
          value={formik?.values?.day}
          onChange={formik.handleChange}
          error={formik.touched.day && formik.errors.day}
        />
      </div>
      <TextField
        count
        required
        maxlength={7}
        name="po_box"
        label="PO. BOX"
        className="input"
        value={formik?.values?.po_box}
        onChange={formik.handleChange}
        error={formik.touched.po_box && formik.errors.po_box}
      />

      <div className="grid">
        <TextField
          count
          required
          maxlength={100}
          name="address"
          label="Address One"
          className="input"
          value={formik?.values?.address}
          onChange={formik.handleChange}
          error={formik.touched.address && formik.errors.address}
        />
        <TextField
          count
          maxlength={100}
          name="address1"
          label="Address Two"
          className="input right-field"
          value={formik?.values?.address1}
          onChange={formik.handleChange}
          error={formik.touched.address1 && formik.errors.address1}
        />
      </div>

      <div className="delivery-address">
        <Checkbox
          label="Delivery Address same as address above"
          name="check"
          onChange={() => setIsChecked(!isChecked)}
          checked={isChecked}
        />

        <TextField
          count
          maxlength={7}
          name="delivery_po_box"
          label="Delivery PO. BOX"
          value={formik?.values?.delivery_po_box}
          onChange={formik.handleChange}
          error={formik.touched.delivery_po_box && formik.errors.delivery_po_box}
        />

        <div className="grid">
          <TextField
            count
            maxlength={100}
            name="delivery_address"
            label="Delivery Address One"
            value={formik?.values?.delivery_address}
            onChange={formik.handleChange}
            error={formik.touched.delivery_address && formik.errors.delivery_address}
          />
          <TextField
            count
            maxlength={100}
            name="delivery_address1"
            label="Delivery Address Two"
            className="right-field"
            value={formik?.values?.delivery_address1}
            onChange={formik.handleChange}
            error={formik.touched.delivery_address1 && formik.errors.delivery_address1}
          />
        </div>
      </div>

      <div className="phone-section">
        <TextField
          required
          maxlength={3}
          name="ext_phone"
          label="Phone Number"
          className="input"
          value={formik?.values?.ext_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.ext_phone && formik.errors.ext_phone}
          ref={textField1}
        />
        <span>-</span>
        <TextField
          label=" "
          maxlength={4}
          name="first_phone"
          className="input"
          value={formik?.values?.first_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.first_phone && formik.errors.first_phone}
          ref={textField2}
        />
        <span>-</span>
        <TextField
          label=" "
          maxlength={4}
          name="second_phone"
          className="input"
          value={formik?.values?.second_phone}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.handleChange(e);
          }}
          onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
            phoneChangeHandler(e);
          }}
          error={formik.touched.second_phone && formik.errors.second_phone}
          ref={textField3}
        />
      </div>

      <TextField
        name="nickname"
        label="Nickname"
        className="input"
        value={formik?.values?.nickname}
        onChange={formik.handleChange}
        error={formik.touched.nickname && formik.errors.nickname}
      />
      <TextField
        count
        maxlength={100}
        name="occupation"
        label="Occupation"
        className="input"
        value={formik?.values?.occupation}
        onChange={formik.handleChange}
        error={formik.touched.occupation && formik.errors.occupation}
      />
      <TextField
        count
        maxlength={1000}
        name="remarks"
        label="Remarks"
        className="input"
        type="textarea"
        rows={10}
        value={formik?.values?.remarks}
        onChange={formik.handleChange}
        error={formik.touched.remarks && formik.errors.remarks}
      />

      <div className="button-wrapper">
        <Button className="btn" size="large" type="submit">
          Update
        </Button>
      </div>
    </Form>
  );
};

export { AdminMemberManagementForm };
