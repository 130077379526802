import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { authContext } from '../../../../utils';
import { Header, TeamNotificationForm } from '../../../../components';

interface Props {
  isMutating: boolean;
  mutate: any;
  teamId?: string;
}

const AddAdminNotificationTemplate = ({ isMutating, mutate, teamId }: Props) => {
  const { user } = authContext();

  const initialValues = {
    title: '',
    content: '',
    urlTitle: '',
    url: '',
    status: '',
    publishDate: undefined,
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required('Please enter'),
    content: yup.string().required('Please enter'),
    status: yup.boolean().required('Please enter'),
    publishDate: yup.string().nullable().required('Please enter'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      mutate({
        teamId: teamId ? teamId : user?.teamId,
        data: {
          title: values?.title,
          content: JSON.stringify({
            content: values?.content,
            urlTitle: values?.urlTitle,
            url: values?.url,
          }),
          status: values?.status,
          publishDate: values?.publishDate,
        },
      });
    },
  });

  return (
    <div>
      <Header title="Notification set up" backButton />
      <div className="body-border">
        <TeamNotificationForm formik={formik} loading={isMutating} />
      </div>
    </div>
  );
};

export { AddAdminNotificationTemplate };
