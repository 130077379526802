import React, { useRef } from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { cloudUploadOutline } from 'ionicons/icons';
import { Loader } from '../Loader';

interface Props {
  color?: string;
  multiple?: boolean;
  className?: string;
  formik?: any;
  variant?: 'primary' | 'secondary';
  handleUpload?: (url: string, fileFormat: string, file: any, fetch: boolean) => void;
  loading?: boolean;
  setShowToast?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Wrapper = styled.div`
  input {
    display: none;
  }

  .img {
    width: 200px;
    height: 150px;
    position: relative;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(67, 67, 67, 0.4);
      border-radius: 5px;
    }
    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 35px;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .upload {
    width: 200px;
    height: 150px;
    background: rgba(255, 255, 255, 0.9);
    border: ${(props) => (props.color ? `1px solid ${props.color}` : '1px solid #29c8d2')};
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .icon {
      font-size: 20px;
      color: ${(props) => (props.color ? props.color : '#29c8d2')};
    }
  }
`;

const ImageUpload: React.FC<Props> = ({
  className,
  color,
  multiple,
  formik,
  variant = 'primary',
  handleUpload,
  setShowToast,
  loading = false,
}) => {
  const ref = useRef<any>(null);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<any>) => {
    if (e?.target?.files[0]?.size / 1000 / 1024 >= 2) {
      setShowToast && setShowToast(true);
      formik?.setFieldTouched('images', true, false);
      return formik.setFieldError('images', 'File should not be more than 2 MB');
    } else {
      if (handleUpload)
        handleUpload(
          e?.target?.files[0]?.name,
          e?.target?.files[0]?.type,
          e?.target?.files[0],
          true
        );
      // let fileReader = new FileReader();
      // fileReader.onload = (e1) => {
      //   if (e1?.type === 'load') {
      //     if (!handleUpload) onChange(e, fileReader?.result);
      //   }
      // };
      // if (e?.target?.files?.[0]) fileReader?.readAsDataURL(e?.target?.files?.[0]);
    }
  };

  return (
    <Wrapper color={color}>
      <div className={className}>
        {loading ? (
          <div className="upload">
            <Loader hasWrapper />
          </div>
        ) : (
          <>
            {' '}
            {variant === 'primary' ? (
              <div className="upload" onClick={() => ref?.current.click()}>
                <IonIcon icon={cloudUploadOutline} className="icon" />
                {t('Upload Image')}
              </div>
            ) : (
              <IonIcon
                icon="./assets/icon/upload-white.svg"
                className="icon"
                onClick={() => ref?.current.click()}
              />
            )}
            <input
              type="file"
              onChange={handleChange}
              ref={ref}
              accept="image/jpg, image/png, image/jpeg"
              className="neomorphs-shadow-in"
              multiple={multiple}
              value=""
            />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export { ImageUpload };
