import React from 'react';
import styled from 'styled-components';
import { searchOutline } from 'ionicons/icons';

import { TextField, Button } from '../../atoms';

const Wrapper = styled.form`
  margin: 0px 0px 26px 0px;
  display: flex;
  align-items: center;

  .input {
    width: 225px;
    margin-right: 10px;
  }

  .button {
    width: 65px;
    height: 42px;
    margin-top: 10px;

    ion-icon {
      width: 20px;
    }
  }
`;

interface MyArray {
  label: string;
  value: string;
}

interface Props {
  teamOptions?: MyArray[];
  publishStatusOptions?: MyArray[];
  formik: any;
}

const SearchGroupComponent: React.FC<Props> = ({ teamOptions, publishStatusOptions, formik }) => {
  const onTeamChange = (e: any) => {
    formik.setFieldValue('team', e?.value);
  };

  const onPublishStatusChange = (e: any) => {
    formik.setFieldValue('publish_status', e?.value);
  };

  return (
    <Wrapper onSubmit={formik.handleSubmit}>
      <TextField
        name="title"
        placeholder="Search from title"
        className="input"
        onChange={formik.handleChange}
        value={formik.values.title}
      />
      {/* Select team search is only for admin */}
      {teamOptions && (
        <TextField
          name="team"
          placeholder="Select Team"
          className="input"
          type="select"
          options={teamOptions}
          onChange={onTeamChange}
          value={formik?.values?.team}
        />
      )}

      {publishStatusOptions && (
        <TextField
          name="publish_status"
          placeholder="Select Publish Status"
          className="input"
          type="select"
          options={publishStatusOptions}
          onChange={onPublishStatusChange}
          value={formik?.values?.publish_status}
        />
      )}

      <Button
        slot="icon-only"
        className="button"
        icon={searchOutline}
        onClick={formik.handleSubmit}
      />
    </Wrapper>
  );
};

export { SearchGroupComponent };
