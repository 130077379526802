import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';

import { closeOutline } from 'ionicons/icons';

interface Props {
  src: string;
  className?: string;
  onClose?: any;
  isColored?: boolean;
  onOpen: any;
  hasClose?: boolean;
}

const ImageWrapper = styled.div`
  width: 200px;
  height: 150px;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 67, 67, 0.4);
    border-radius: 5px;
  }
  .icon {
    position: absolute;
    color: white;
    z-index: 1;
  }

  .eye-icon {
    width: 45px;
    height: 45px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .close-icon {
    font-size: 20px;
    top: 9px;
    right: 9px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const Image: React.FC<Props> = ({
  src,
  className,
  onClose,
  isColored,
  onOpen,
  hasClose = true,
}) => {
  return (
    <ImageWrapper>
      {hasClose && <IonIcon onClick={onClose} icon={closeOutline} className="icon close-icon" />}
      <IonIcon
        icon={isColored ? './assets/icon/eye-icon-red.svg' : './assets/icon/eye-icon.svg'}
        className="icon eye-icon"
        onClick={onOpen}
      />
      <div className="overlay"></div>

      <img className={className} src={src} alt="img" />
    </ImageWrapper>
  );
};

export { Image };
