import React from 'react';
import styled from 'styled-components';
import { TextField, Button } from '../../../components';
import { IonLabel, IonNote, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface Props {
  formik: any;
  isMutating?: boolean;
  type: 'add' | 'edit';
}

const Form = styled.form`
  .textfield {
    margin-bottom: 20px;
    ion-radio {
      --color-checked: #b80629;
    }
  }
  .required {
    color: #b80629;
    margin-left: 3px;
  }
  .info {
    color: #b80629;
    margin-bottom: 0px;
  }
  .message {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ion-button {
      width: 245px;
    }
  }

  .default-input {
    font-size: 13px;
    display: block;
    width: 98%;
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px;
    height: 40px;
    margin: 10px 0px 2px;
    padding: 0 10px;
    outline: none;

    @media (min-width: 1550px) {
      width: 98.5%;
    }

    @media (min-width: 1850px) {
      width: 98.8%;
    }
  }

  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }

  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-right {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    text-align: right;
    ion-text {
      margin-right: 0px;
      font-size: 12px;
    }
  }
  .disabled {
    input {
      background-color: rgba(235, 233, 233,0.5);
      color: rgba(0,0,0,0.4)
    }
`;

const TeamUserManagementForm: React.FC<Props> = ({ formik, isMutating, type }) => {
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    const pattern = /^[a-zA-Z0-9!@#$%^&*)(+=._-~`"/"?>,<'";:]*$/;
    const value = e.target.value;
    if (value === '' || pattern.test(value)) {
      formik.setFieldValue('username', value);
    }
  };
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="message">
        <IonLabel>
          {t('Username')}

          {type == 'edit' ? (
            ''
          ) : (
            <>
              <span className="required">*</span>
              <span className="info">
                {t('You can only enter english alphabets, numbers & symbols in username')}
              </span>
            </>
          )}
        </IonLabel>
      </div>

      <div className={type == 'edit' ? 'disabled textfield' : 'textfield'}>
        <input
          maxLength={50}
          name="username"
          placeholder="LoginName_123"
          disabled={type == 'edit' ? true : false}
          className="default-input"
          value={formik.values.username}
          onChange={type == 'edit' ? () => {} : handleChange}
        />
        <div className="bottom-wrapper">
          <IonNote slot="error">
            {formik.touched.username && formik.errors.username && t(formik.errors.username)}
          </IonNote>
          <div className="text-right">
            <IonText>{`${formik.values.username?.toString().length}/${50}`}</IonText>
          </div>
        </div>
      </div>

      <div className="two-columns">
        <TextField
          count
          required
          maxlength={20}
          name="name_sei"
          label="Name Sei"
          placeholder="山田"
          className="textfield"
          value={formik.values.name_sei}
          onChange={formik.handleChange}
          error={formik.touched.name_sei && formik.errors.name_sei}
        />
        <TextField
          count
          required
          maxlength={20}
          name="name_mei"
          label="Name Mei"
          placeholder="太朗"
          className="textfield"
          value={formik.values.name_mei}
          onChange={formik.handleChange}
          error={formik.touched.name_mei && formik.errors.name_mei}
        />
      </div>
      <div className="two-columns">
        <TextField
          count
          required
          maxlength={20}
          name="furigana_sei"
          label="Furigana Sei"
          placeholder="やまだ"
          className="textfield"
          value={formik.values.furigana_sei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_sei && formik.errors.furigana_sei}
        />
        <TextField
          count
          required
          maxlength={20}
          name="furigana_mei"
          label="Furigana Mei"
          placeholder="たろう"
          className="textfield"
          value={formik.values.furigana_mei}
          onChange={formik.handleChange}
          error={formik.touched.furigana_mei && formik.errors.furigana_mei}
        />
      </div>
      <div className="two-columns">
        <TextField
          count
          required
          name="email"
          label="Email"
          placeholder="fanique@example.com"
          className="textfield"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email}
        />
        <TextField
          count
          required
          maxlength={15}
          name="phone"
          label="Phone"
          placeholder="08012341234"
          className="textfield"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && formik.errors.phone}
        />
      </div>
      {type === 'edit' && (
        <TextField
          passwordInfo
          name="password"
          label="Password"
          placeholder="パスワードを入力してください"
          textType="password"
          className="textfield"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && formik.errors.password}
        />
      )}
      <TextField
        count
        name="department"
        label="Department"
        placeholder="Faniqueスポーツ部"
        className="textfield"
        value={formik.values.department}
        onChange={formik.handleChange}
        error={formik.touched.department && formik.errors.department}
      />

      <div className="button-wrapper">
        <Button size="large" type="submit" disabled={isMutating}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export { TeamUserManagementForm };
