import React from 'react';
import { IonIcon, IonImg, IonModal } from '@ionic/react';
import styled from 'styled-components';
import {
  caretDownOutline,
  closeOutline,
  home,
  menu,
  notifications,
  person,
  wallet,
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../atoms';
import { SingleNotificationResponse, SingleVoteResponse } from '../../../types';
import moment from 'moment';
import { isJSON } from '../../../utils';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  isLoading?: boolean;
  contentType: 'notification' | 'vote';
  notificationData?: SingleNotificationResponse;
  voteData?: SingleVoteResponse;
}

const ModalWrapper = styled(IonModal)`
  --width: 1200px;
  --height: 700px;
  @media (max-width: 1300px) {
    --width: 1000px;
    --height: 700px;
  }
  @media (max-width: 1024px) {
    --width: 700px;
    --height: 650px;
  }
  .heading {
    height: 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #7c7c7c;
    .title {
      width: 169px;
      height: 24px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin: 13px 0px;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .content {
    background-image: url('/assets/images/nft-owners-preview/main-background.png');
    background-size: cover;
    height: 94%;
    overflow-y: auto;
    .content-body {
      position: relative;
      margin: auto;
      max-width: 414px;
      min-height: 100%;
      background-color: #eeeeee;
      padding-bottom: 110px;
      .header {
        position: sticky;
        top: 0px;
        background-color: rgb(238, 238, 238);
        z-index: 999;
        padding: 14px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ion-img {
          width: 105px;
        }
        .buttons-wrapper {
          display: flex;
          button {
            border-radius: 15px;
            padding: 5px 12px;
            border: none;
            font-size: 16px;
            color: #b80629;
            background-color: transparent;
            margin-left: 20px;
            box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf,
              inset -3px -4px 4px -4px #a7a4a4, inset 3px 5px 9px -7px #f9e6e6;
          }
        }
      }
      .body {
      }
      .footer {
        padding: 3px 0px;
        position: fixed;
        width: 414px;
        background: transparent;
        z-index: 100;
        bottom: 2px !important;
        display: flex;
        justify-content: center;
        .footer-menu {
          display: flex;
          width: 85%;
          background: rgb(238, 238, 238);
          justify-content: space-between;
          padding: 10px 15px 5px;
          border-radius: 35px;
          overflow: hidden;
          box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
            inset 3px 5px 9px -7px #f9e6e6;
          .item {
            display: flex;
            width: 50px;
            flex-direction: column;
            align-items: center;
            .icon {
              font-size: 33px;
              color: #b80629;
            }
            span {
              font-size: 8px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
            }
          }
          .disabled {
            opacity: 0.4;
          }
        }
      }
    }
  }
`;

const NotificationDetail = styled.div`
  padding: 3px 25px;
  .title {
    font-weight: 500;
    font-size: 15px;
  }
  .time {
    font-weight: 300;
    margin-top: -10px;
    margin-bottom: 18px;
    span {
      font-size: 13px;
    }
  }
  .divider {
    box-shadow: rgb(206, 197, 197) -2px -0.8px 2px;
    border-width: 0;
    box-sizing: initial;
    height: 1px;
  }
  .details {
    font-weight: 400;
    font-size: 12px;
    color: rgb(77, 77, 77);
    margin-top: 12px;
    white-space: pre-line;
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
    .urlTitle {
      display: flex;
      .icon {
        font-size: 24px;
      }
      span {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
      }
    }
    a {
      font-size: 12px;
      font-weight: 500;
      text-decoration: underline;
      color: inherit;
      word-break: break-all;
    }
    .button-wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      button {
        width: 43%;
        font-size: 12px;
        background: #eeeeee;
        border: none;
        box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
          inset 3px 5px 9px -7px #f9e6e6;
        color: #b80629;
        border-radius: 2rem;
        padding: 0.4rem 0;
        height: auto;
        font-size: 12px;
        text-transform: none;
        font-weight: 600;
      }
    }
  }
`;

const VoteDetail = styled.div`
  padding: 3px 25px;
  .vote-page-heading {
    text-align: center;
    .subHeading {
      font-weight: 350;
      font-size: 15px;
      margin-bottom: 5px;
      margin-top: 0px;
    }
    .mainHeading {
      line-height: 35px;
      white-space: break-spaces;
      color: rgb(184, 6, 41);
      font-size: 36px;
      font-weight: 900;
      margin: 0;
    }
  }
  .vote-type-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    margin-top: 35px;
    button {
      width: 140px;
      font-size: 12px;
      background: #eeeeee;
      border: none;
      box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf, inset -3px -4px 4px -4px #a7a4a4,
        inset 3px 5px 9px -7px #f9e6e6;
      color: #b80629;
      border-radius: 2rem;
      padding: 0.4rem 0;
      height: auto;
      font-size: 12px;
      text-transform: none;
      font-weight: 600;
    }
    .selected {
      box-shadow: -3px -2px 3px #f9f4f4 inset, 2px 2px 3px #c7c7c7 inset, -2px -2px 3px #c7c7c7,
        3px 2px 3px #ffffff;
    }
  }
  .vote-body {
    margin-top: 30px;
    .vote-content {
      border-radius: 50px;
      padding: 15px;
      box-shadow: -3px -2px 3px #f9f4f4 inset, 2px 2px 3px #c7c7c7 inset, -2px -2px 3px #c7c7c7,
        3px 2px 3px #ffffff;
      .avatar-wrapper {
        padding: 15px 23px;
        display: flex;
        .avatar {
          box-shadow: -3px -2px 3px #f9f4f4 inset, 2px 2px 3px #c7c7c7 inset, -2px -2px 3px #c7c7c7,
            3px 2px 3px #ffffff;
          display: flex;
          width: 36px;
          min-width: 36px;
          height: 36px;
          padding: 2px;
          border-radius: 50%;
          .image-container {
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            ion-img {
              object-fit: cover;
            }
          }
        }
        .text-wrapper {
          padding: 7px 15px;
          .title {
            font-size: 18px;
            color: rgb(184, 6, 41);
            font-weight: 700;
            line-height: 26px;
            margin: 0;
          }
          .subtitle {
            padding-top: 4px;
            font-size: 15px;
            line-height: 22px;
            color: black;
            font-weight: 350;
            margin: 0;
          }
        }
      }
      .divider {
        border-width: 0;
        box-sizing: initial;
        height: 1px;
        box-shadow: rgb(206, 197, 197) -2px -0.8px 2px;
        filter: blur(1px);
        margin: 10px 10px 30px !important;
      }
      .poll {
        .single-select-options {
          button {
            margin-bottom: 20px;
            font-size: 18px;
            color: rgb(77, 77, 77);
            background-color: transparent;
            font-weight: 700;
            text-align: center;
            box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf,
              inset -3px -4px 4px -4px #a7a4a4, inset 3px 5px 9px -7px #f9e6e6;
            width: 100%;
            height: auto;
            border: none;
            border-radius: 2rem;
            padding: 0.8rem 0;
            letter-spacing: 1px;
            word-break: break-word;
          }
        }
        .multi-select-options {
          padding: 0px 30px;
          .option {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .checkcircle {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              align-self: center;
              position: relative;
              margin-right: 20px;
              box-shadow: -3px -2px 3px #f9f4f4 inset, 2px 2px 3px #c7c7c7 inset,
                -2px -2px 3px #c7c7c7, 3px 2px 3px #ffffff;
            }
            p {
              font-weight: bold;
              margin: 0;
              word-break: break-word;
            }
          }
        }
        .image-select-options {
          .option {
            box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf,
              inset -3px -4px 4px -4px #a7a4a4, inset 3px 5px 9px -7px #f9e6e6;
            border-radius: 50px;
            margin-bottom: 25px;
            padding: 35px;
          }
        }
        .deadline-wrapper {
          text-align: center;
          font-weight: 350;
          margin-top: 5px;
          margin-bottom: 30px;
          span {
            margin-right: 10px;
          }
        }
        .vote-button-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            width: 150px;
            font-size: 12px;
            margin-bottom: 22px;
            color: #cccccc;
            background-color: transparent;
            font-weight: 700;
            text-align: center;
            box-shadow: 2px 1px 3px #c1c1c1, -2px -3px 2px #ffffffcf,
              inset -3px -4px 4px -4px #a7a4a4, inset 3px 5px 9px -7px #f9e6e6;
            border: none;
            border-radius: 2rem;
            padding: 0.4rem 0;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
`;

export const PreviewModal = ({
  isOpen,
  closeModal,
  isLoading = false,
  contentType,
  notificationData,
  voteData,
}: Props) => {
  const { t } = useTranslation();

  const notificationTime = (publishDate?: any) => {
    const date = new Date(publishDate);
    const time = `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}年${month}月${day}日 ${time}`;
  };

  const renderNotification = (data?: any) => (
    <NotificationDetail>
      <p className="title">{data?.title}</p>
      <div className="time">
        <span>{notificationTime(data?.publishDate)}</span>
      </div>
      <hr className="divider"></hr>
      <div className="details">
        <p>
          {data?.content ? (data?.content?.content ? data?.content?.content : data?.content) : ''}
        </p>
        <div className="urlTitle">
          {data?.content?.urlTitle ? (
            <>
              <IonIcon icon={caretDownOutline} className="icon" />
              <span>{data?.content?.urlTitle}</span>
            </>
          ) : (
            ''
          )}
        </div>
        {data?.content?.url ? (
          <a target="_blank" rel="noreferrer">
            {data?.content?.url}
          </a>
        ) : (
          ''
        )}
        <div className="button-wrapper">
          <button>ホーム画面へ</button>
        </div>
      </div>
    </NotificationDetail>
  );

  const renderVote = (loading?: boolean, data?: SingleVoteResponse) => (
    <VoteDetail>
      <div className="vote-page-heading">
        <p className="subHeading">ブロンコスに意見を届けよう！</p>
        <p className="mainHeading">Vote</p>
      </div>
      <div className="vote-type-buttons">
        <button className="selected">投票受付中</button>
        <button>投票終了</button>
      </div>
      <div className="vote-body">
        {loading ? (
          <Loader hasWrapper />
        ) : (
          <div className="vote-content">
            <div className="avatar-wrapper">
              <div className="avatar">
                <div className="image-container">
                  <IonImg src="./assets/images/broncos_image.png" />
                </div>
              </div>
              <div className="text-wrapper">
                <p className="title">{data?.title}</p>
                <p className="subtitle">{data?.content}</p>
              </div>
            </div>
            <hr className="divider" />
            <div className="poll">
              {data?.selectionType === 'SingleSelect' ? (
                <>{voteForSingleSelect(data)}</>
              ) : data?.selectionType === 'MultipleSelect' ? (
                <>{voteForMultipleSelect(data)}</>
              ) : data?.selectionType === 'ImageSelect' ? (
                <>{voteForImageSelect(data)}</>
              ) : null}
              <div className="deadline-wrapper">
                <p>
                  <span>投票締切:</span>
                  <span>{moment(data?.closeDate).format('YYYY.MM.DD HH:mm')}</span>
                </p>
              </div>
              <div className="vote-button-wrapper">
                <button>投票する</button>
                <button>投票分布を見る</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </VoteDetail>
  );

  const voteForSingleSelect = (data?: SingleVoteResponse) => (
    <div className="single-select-options">
      {toVotingPollData(data)?.map((d, idx) => (
        <button key={idx}>{d}</button>
      ))}
    </div>
  );

  const voteForMultipleSelect = (data?: SingleVoteResponse) => (
    <div className="multi-select-options">
      {toVotingPollData(data)?.map((d, idx) => (
        <div className="option" key={idx}>
          <div className="checkcircle"></div>
          <p>{d}</p>
        </div>
      ))}
    </div>
  );

  const voteForImageSelect = (data?: SingleVoteResponse) => (
    <div className="image-select-options">
      {toVotingPollData(data)?.map((d, idx) => (
        <div className="option" key={idx}>
          <IonImg src={`${d}`} />
        </div>
      ))}
    </div>
  );

  const toVotingPollData = (data?: SingleVoteResponse): String[] => {
    if (data?.selections && isJSON(data?.selections)) {
      const dictionary: { [key: string]: string } = JSON.parse(data?.selections);
      return Object.values(dictionary);
    } else {
      return [];
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} onDidDismiss={closeModal}>
      <div className="heading">
        <p className="title">{t('Preview')}</p>
        <IonIcon icon={closeOutline} className="close-icon" onClick={closeModal} />
      </div>

      <div className="content">
        <div className="content-body">
          <div className="header">
            <div className="logo-wrapper">
              <IonImg src="./assets/images/logo.png" />
            </div>
            <div className="buttons-wrapper">
              <button disabled>
                <IonIcon icon={wallet} />
              </button>
              <button disabled>
                <IonIcon icon={notifications} />
              </button>
              <button disabled>
                <IonIcon icon={menu} />
              </button>
            </div>
          </div>
          <div className="body">
            {contentType === 'vote' ? (
              <>{renderVote(isLoading, voteData)}</>
            ) : (
              <>{isLoading ? <Loader hasWrapper /> : <>{renderNotification(notificationData)}</>}</>
            )}
          </div>
          <div className="footer">
            <div className="footer-menu">
              <div className="item">
                <IonIcon icon={home} className="icon" />
                <span>HOME</span>
              </div>
              <div className="item">
                <IonIcon src="./assets/icon/vote-icon.svg" className="icon" />
                <span>VOTE</span>
              </div>
              <div className="item">
                <IonIcon icon={person} className="icon" />
                <span>MY PAGE</span>
              </div>
              <div className="item disabled">
                <IonIcon src="./assets/icon/market-icon.svg" className="icon" />
                <span>MARKET</span>
              </div>
              <div className="item">
                <IonIcon src="./assets/icon/discord-logo.svg" className="icon" />
                <span>CHAT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
