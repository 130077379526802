import { API } from './api';
import { QueryFunctionContext } from 'react-query';
import { AddVotePayload, EditVotePayload, SingleVoteResponse } from '../types';

export const fetchTeamVoteList = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const page = queryKey[2];
  const tabType = queryKey[3];
  const searchWord = queryKey[4];
  return API.get(
    `/admin/vote/list?teamId=${teamId}&page=${page}&tabType=${tabType}&searchWord=${searchWord}`
  );
};

export const addTeamVote = async (payload: AddVotePayload) => {
  const { teamId, data } = payload;
  return API.post(`admin/vote?teamId=${teamId}`, data);
};

export const fetchSingleTeamVoteData = async ({ queryKey }: QueryFunctionContext) => {
  return API.get<SingleVoteResponse>(`/admin/vote?voteMasterId=${queryKey[1]}`);
};

export const fetchTeamVoteResultData = async ({ queryKey }: QueryFunctionContext) => {
  return API.get(`/admin/vote/result?voteMasterId=${queryKey[1]}`);
};

export const editTeamVote = async (payload: EditVotePayload) => {
  const { teamId, data } = payload;
  return API.put(`admin/vote?teamId=${teamId}`, data);
};
