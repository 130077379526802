import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../atoms';
import DateInput from '../../atoms/DateInput';
import { isoToDateArray, dateArrayToISO } from '../../../utils/dateUtils';
import moment from 'moment';

interface Props {
  id?: string | number;
  formik: any;
}

const FormWrapper = styled.form`
  .level-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .disabled {
    input {
      background-color: #cecaca;
    }
  }

  .input {
    margin-bottom: 10px;
  }

  @media (max-width: 1340px) {
    .level-wrapper {
      grid-template-columns: 0.7fr 0.6fr;
    }
  }

  @media (min-width: 1258px) and (max-width: 1265px) {
    .level-wrapper {
      grid-gap: 12px;
    }
  }

  .select__placeholder {
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }
`;

const FEES_DISABLED_CARDS = ['PLATINUM FIRE', 'DIAMOND'];

const Form: React.FC<Props> = ({ id, formik }) => {
  const { t } = useTranslation();

  const changeDateRange = (
    name: string,
    value: [string | Date, string | Date],
    id?: string | number
  ) => {
    formik.setFieldValue('sellingPeriodFrom', moment(value[0]).format('YYYYMMDD'));
    formik.setFieldValue('sellingPeriodTo', moment(value[1]).format('YYYYMMDD'));
    if (formik.values?.id === id) {
      return formik.setFieldValue(name, dateArrayToISO(value));
    }
  };

  const onLevelChange = (e: { label: string; value: string }, name: string) => {
    formik.setFieldValue(name, e);
  };

  return (
    <FormWrapper>
      <p className="selling-period">{t('Selling Period')}</p>
      <DateInput
        name="dateRange"
        hasDateRange
        dateRange={isoToDateArray(formik?.values?.dateRange)}
        onRangeChange={(name: string, value: any) => changeDateRange(name, value, formik.values.id)}
        id={id}
        disabled
      />

      <TextField
        name="issueLimit"
        label="Unit to sell"
        // className={`right-field input ${
        //   formik?.values?.statusNameEnglish === 'PLATINUM FIRE' && 'disabled'
        // }`}
        className="right-field input disabled"
        placeholder="Enter unit to sell"
        value={formik?.values?.issueLimit}
        // onChange={formik.handleChange}
        // disabled={formik?.values?.statusNameEnglish === 'PLATINUM FIRE'}
        disabled
      />
      <TextField
        name="membershipFee"
        label="Price"
        // className={`right-field input ${
        //   FEES_DISABLED_CARDS.includes(formik?.values?.statusNameEnglish) && 'disabled'
        // }`}
        className="right-field input disabled"
        placeholder="Enter price for Membership"
        value={formik?.values?.membershipFee}
        // onChange={formik.handleChange}
        // disabled={FEES_DISABLED_CARDS.includes(formik?.values?.statusNameEnglish)}
        disabled
      />
      <TextField
        name="annualFee"
        label="Annual Fee"
        // className={`right-field input ${
        //   FEES_DISABLED_CARDS.includes(formik?.values?.statusNameEnglish) && 'disabled'
        // }`}
        className="right-field input disabled"
        placeholder="Enter Annual fee"
        value={formik?.values?.annualFee}
        // onChange={formik.handleChange}
        // disabled={FEES_DISABLED_CARDS.includes(formik?.values?.statusNameEnglish)}
        disabled
      />
      <div className="level-wrapper">
        <TextField
          disabled
          name="levelFrom"
          label="Level up From"
          className="right-field input disabled"
          value={formik?.values?.levelFrom}
          onChange={(e: { label: string; value: string }) => onLevelChange(e, 'level_up_from')}
        />
        <TextField
          disabled
          name="levelTo"
          label="Level up To"
          className="right-field input disabled"
          value={formik?.values?.levelTo}
          onChange={(e: { label: string; value: string }) => onLevelChange(e, 'level_up_to')}
        />
      </div>
    </FormWrapper>
  );
};

export default Form;
