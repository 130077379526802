/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import React, { useState } from 'react';
import 'yet-another-react-lightbox/styles.css';
import { Lightbox } from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { Image } from '../Image';
import { ImageUpload } from '../../atoms/ImageUpload';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1700px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 2000px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 1020px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .image {
    margin-bottom: 25px;
  }
`;

interface Props {
  className?: string;
  formik: any;
  onDelete: (id: string) => void;
  handleUpload: (url: string, fileFormat: string, file: any, fetch: boolean) => void;
  loading?: boolean;
  hasClose?: boolean;
}
interface ImageValue {
  id: string;
  src: string;
}

const UploadSection: React.FC<Props> = ({
  onDelete,
  className,
  formik,
  handleUpload,
  loading = false,
  hasClose = false,
}) => {
  const [index, setIndex] = useState(-1);

  const images = formik.values.images;

  return (
    <Container className={className}>
      {images &&
        images?.map((image: ImageValue, index: number) => (
          <div key={index} className="image">
            <Image
              src={image?.src}
              onClose={() => onDelete(image.id)}
              onOpen={() => setIndex(index)}
              hasClose={hasClose}
            />
          </div>
        ))}
      {images?.length >= 5 ? (
        ''
      ) : (
        <ImageUpload
          color="#B80629"
          multiple
          className="image"
          formik={formik}
          handleUpload={handleUpload}
          loading={loading}
        />
      )}
      <Lightbox
        open={index >= 0}
        close={() => setIndex(-1)}
        index={index}
        plugins={[Zoom]}
        slides={images}
      />
    </Container>
  );
};

export { UploadSection };
