import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../utils/ToastContext';
import { addTeamNotification } from '../services';
import { AddAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/AddAdminNotificationTemplate';

const AddNotification = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { showToast } = useToast();

  const { mutate, isLoading: isMutating } = useMutation(addTeamNotification, {
    onSuccess: async () => {
      showToast('Notification added successfully', 'success');
      history.push({
        pathname: `/${pathname.split('/')[1]}/notification`,
        search: '?list=beforePublishing',
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  return (
    <>
      <AddAdminNotificationTemplate
        teamId={pathname?.split('/')[1]}
        mutate={mutate}
        isMutating={isMutating}
      />
    </>
  );
};

export default AddNotification;
