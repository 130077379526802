import React from 'react';
import styled from 'styled-components';
import { IonSpinner } from '@ionic/react';

interface Props {
  hasWrapper?: boolean;
  margin?: string;
}

const LoaderWrapper = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props?.margin ? props?.margin : '2em')};
`;

const Loader: React.FC<Props> = ({ hasWrapper = false, margin }) => {
  return (
    <>
      {hasWrapper ? (
        <LoaderWrapper margin={margin}>
          <IonSpinner />
        </LoaderWrapper>
      ) : (
        <IonSpinner />
      )}
    </>
  );
};

export { Loader };
