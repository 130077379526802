import axios from 'axios';
import { Auth } from 'aws-amplify';

axios.defaults.responseType = 'json';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  async (config: any) => {
    try {
      const auth = await Auth.currentSession();
      const token = auth.getIdToken().getJwtToken();

      if (token) {
        config.headers['Authorization'] = token;
      }
    } catch (err) {
      // Sentry log goes here
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { API };
