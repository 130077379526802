import React from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

import { authContext } from '../utils';
import { useToast } from '../utils/ToastContext';
import { addTeamNotification } from '../services';
import { AddAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/AddAdminNotificationTemplate';

const TeamAddNotification = () => {
  const history = useHistory();
  const { user } = authContext();
  const { showToast } = useToast();

  const { mutate, isLoading: isMutating } = useMutation(addTeamNotification, {
    onSuccess: async () => {
      showToast('Notification added successfully', 'success');
      history.push({
        pathname: `/notification`,
        search: '?list=beforePublishing',
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  return (
    <AddAdminNotificationTemplate mutate={mutate} isMutating={isMutating} teamId={user?.teamId} />
  );
};

export default TeamAddNotification;
