import React from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from '../components';
import { useToast } from './ToastContext';

const ToastContainer = () => {
  const { t } = useTranslation();
  const { toast, hideToast } = useToast();

  return (
    <Toast show={toast.show} setShow={hideToast} message={t(toast.message)} type={toast.type} />
  );
};

export default ToastContainer;
