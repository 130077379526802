import { useQuery } from 'react-query';
import React, { useState } from 'react';

import { authContext } from '../utils';
import { fetchTeamUserList } from '../services';
import { UserManagementTemplate } from '../components';

const TeamUserManagement = () => {
  const [searchText, setSearchText] = useState<any>('');
  const [currentPage, setCurrentPage] = useState<any>(1);

  const { user } = authContext();

  const { data, isLoading, isFetching } = useQuery(
    ['fetchTeamUser', user?.teamId, currentPage, searchText],
    fetchTeamUserList,
    {
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <UserManagementTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      redirectLink="/user-management"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setSearchText={setSearchText}
    />
  );
};

export default TeamUserManagement;
