import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { bookOutline, eyeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState, useEffect } from 'react';

import { toJpDate } from '../../../../utils';
import { PreviewModal } from '../../../../components/molecules/PreviewModal';
import { Header, Search, Pagination, TableComponent, VoteModal } from '../../../../components';

const Container = styled.div`
  .body {
    .column {
      display: flex;
      color: #898989;
      border-bottom: 1px solid #cccccc;

      span {
        margin-right: 15px;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        padding: 0 15px 10px;
      }

      .active {
        color: #b80629;
        border-bottom: 1px solid #b80629;
      }
    }
    .topRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-input {
      margin: 20px 20px 20px 0px;
    }

    .table {
      font-weight: 400;
      font-size: 14px;

      .paginate {
        margin-top: 30px;
      }

      .loader {
        width: 100%;
        margin: 50px auto;
      }
    }

    .eye-icon {
      color: black;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
    .preview-icon {
      margin-right: 16px;
    }
  }
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const differentStatus: any = {
  true: '#0fba53',
  false: '#B80629',
  ended: '#B80629',
};

const Text = styled.div<{ value?: string }>`
  color: ${(props) => differentStatus[`${props?.value?.toLowerCase()}`]};
  font-size: 14px;
`;

const VoteManagementTemplate = ({
  redirectLink,
  voteMemberLink,
  currentScreen,
  voteData,
  isLoading,
  isFetching,
  voteResultData,
  isLoadingResult,
  isFetchingResult,
  previewData,
  loadingPreview,
  fetchingPreview,
  currentPage,
  setCurrentPage,
  searchText,
  setSearchText,
  setVotePreviewId,
  modalData,
  setModalData,
}: any) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [resetSearch, setResetSearch] = useState<boolean>(false);
  // const [downloadList, setDownloadList] = useState([]);
  // const [allDeselect, setAllDeselect] = useState(true);

  // For Modal
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // For Preview Modal
  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const openPreview = () => setPreviewOpen(true);
  const closePreview = () => setPreviewOpen(false);

  const changeScreen = (screen: string) => {
    history.push(`${redirectLink}?list=${screen}`);
  };

  const renderVoteResult = (value: string | null | undefined) => {
    return value === null || value === undefined ? '-' : value === 'true' ? t('On') : t('Off');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Vote Title',
        accessor: 'title',
        width: '175px',
      },
      {
        Header: 'Type',
        accessor: 'selectionType',
        Cell: (props: { value: string }) => {
          return t(`${props.value}`);
        },
      },
      {
        Header: 'Status',
        accessor: (d: any) => d?.status?.toString(),
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>{props?.value === 'true' ? t('Public') : t('Private')}</Text>
          );
        },
        width: '100px',
      },
      {
        Header: 'Vote Distribution',
        accessor: (d: any) => d?.showResult?.toString(),
        Cell: (props: { value: string }) => {
          return <Text>{renderVoteResult(props?.value)}</Text>;
        },
      },
      {
        Header: 'Created date',
        accessor: 'createdAt',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Published date',
        accessor: 'publishDate',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span>
              <IonIcon
                icon={eyeOutline}
                className="preview-icon"
                onClick={() => {
                  openPreview();
                  setVotePreviewId(props?.row?.original?.id);
                }}
              />
              <IonIcon
                icon="./assets/icon/edit.svg"
                className="icon preview-icon"
                onClick={() =>
                  history.push(`${redirectLink}/edit/${props.row.original.id}?list=onGoingVote`)
                }
              />
              <IonIcon
                icon={bookOutline}
                className="icon"
                onClick={() => {
                  setModalData({
                    voteModalId: props?.row?.original?.id,
                    selectionType: props?.row?.original?.selectionType,
                    selections: props?.row?.original?.selections,
                    modalTitle: props?.row?.original?.title,
                  });
                  openModal();
                }}
              />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [redirectLink]
  );

  const endedColumns = useMemo(
    () => [
      {
        Header: 'Vote Title',
        accessor: 'title',
        width: '175px',
      },
      {
        Header: 'Type',
        accessor: 'selectionType',
        Cell: (props: { value: string }) => {
          return t(`${props.value}`);
        },
      },
      {
        Header: 'Status',
        accessor: (d: any) => d?.status?.toString(),
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>{props?.value === 'true' ? t('Public') : t('Private')}</Text>
          );
        },
        width: '100px',
      },
      {
        Header: 'Vote Distribution',
        accessor: (d: any) => d?.showResult?.toString(),
        Cell: (props: { value: string }) => {
          return <Text>{renderVoteResult(props?.value)}</Text>;
        },
      },
      {
        Header: 'Published',
        accessor: 'publishDate',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Ended',
        accessor: 'closeDate',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span>
              <IonIcon
                icon={eyeOutline}
                className="preview-icon"
                onClick={() => {
                  openPreview();
                  setVotePreviewId(props?.row?.original?.id);
                }}
              />
              <IonIcon
                icon={bookOutline}
                className="eye-icon"
                onClick={() => {
                  setModalData({
                    voteModalId: props?.row?.original?.id,
                    selectionType: props?.row?.original?.selectionType,
                    selections: props?.row?.original?.selections,
                    modalTitle: props?.row?.original?.title,
                  });
                  openModal();
                }}
              />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [currentScreen]
  );

  const privateColumns = useMemo(
    () => [
      {
        Header: 'Vote Title',
        accessor: 'title',
        width: '175px',
      },
      {
        Header: 'Type',
        accessor: 'selectionType',
        Cell: (props: { value: string }) => {
          return t(`${props.value}`);
        },
      },
      {
        Header: 'Status',
        accessor: (d: any) => d?.status?.toString(),
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>{props?.value === 'true' ? t('Public') : t('Private')}</Text>
          );
        },
        width: '100px',
      },
      {
        Header: 'Vote Distribution',
        accessor: (d: any) => d?.showResult?.toString(),
        Cell: (props: { value: string }) => {
          return <Text>{renderVoteResult(props?.value)}</Text>;
        },
      },
      {
        Header: 'Created date',
        accessor: 'createdAt',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Published date',
        accessor: 'publishDate',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span>
              <IonIcon
                icon={eyeOutline}
                className="preview-icon"
                onClick={() => {
                  openPreview();
                  setVotePreviewId(props?.row?.original?.id);
                }}
              />
              <IonIcon
                icon="./assets/icon/edit.svg"
                className="icon"
                onClick={() =>
                  history.push(`${redirectLink}/edit/${props.row.original.id}?list=privateVote`)
                }
              />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [redirectLink]
  );

  const beforeApplicationColumns = useMemo(
    () => [
      {
        Header: 'Vote Title',
        accessor: 'title',
        width: '175px',
      },
      {
        Header: 'Type',
        accessor: 'selectionType',
        Cell: (props: { value: string }) => {
          return t(`${props.value}`);
        },
      },
      {
        Header: 'Status',
        accessor: (d: any) => d?.status?.toString(),
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>{props?.value === 'true' ? t('Public') : t('Private')}</Text>
          );
        },
        width: '100px',
      },
      {
        Header: 'Vote Distribution',
        accessor: (d: any) => d?.showResult?.toString(),
        Cell: (props: { value: string }) => {
          return <Text>{renderVoteResult(props?.value)}</Text>;
        },
      },
      {
        Header: 'Created date',
        accessor: 'createdAt',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Published date',
        accessor: 'publishDate',
        Cell: (props: { value: string }) => {
          return toJpDate(props?.value);
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span>
              <IonIcon
                icon={eyeOutline}
                className="preview-icon"
                onClick={() => {
                  openPreview();
                  setVotePreviewId(props?.row?.original?.id);
                }}
              />
              <IonIcon
                icon="./assets/icon/edit.svg"
                className="icon"
                onClick={() =>
                  history.push(
                    `${redirectLink}/edit/${props.row.original.id}?list=beforeApplicationVote`
                  )
                }
              />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [redirectLink]
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  // const handleRowSelection = (selectedRows: any) => {
  //   if (selectedRows?.length !== 0) {
  //     setDownloadList(selectedRows);
  //     setAllDeselect(false);
  //   } else if (selectedRows?.length === 0 && downloadList?.length !== 0) {
  //     setDownloadList([]);
  //   }
  // };

  const transformObjectToArray = (
    obj: {
      [key: string]: { voteNumber: number };
    },
    selections?: string
  ): { options: string; votes: string }[] => {
    if (obj && selections) {
      let parsedSelections = JSON.parse(selections);
      let data = Object.keys(obj)?.map((key: string) => ({
        options: key === 'notVoted' ? 'Not Voted' : parsedSelections[key] ?? '-',
        votes: String(obj[key]?.voteNumber ?? '-'),
      }));
      return data;
    } else return [];
  };

  const renderTable = () => {
    if (currentScreen === 'accepting') {
      return (
        <TableComponent
          columns={columns}
          data={voteData?.votes ?? []}
          loading={isLoading || isFetching}
        />
      );
    } else if (currentScreen === 'reception_end') {
      return (
        <TableComponent
          columns={endedColumns}
          data={voteData?.votes ?? []}
          loading={isLoading || isFetching}
          // enableRowSelect
          // onRowSelection={handleRowSelection}
          // allDeselect={allDeselect}
        />
      );
    } else if (currentScreen === 'private') {
      return (
        <TableComponent
          columns={privateColumns}
          data={voteData?.votes ?? []}
          loading={isLoading || isFetching}
        />
      );
    } else if (currentScreen === 'before_reception') {
      return (
        <TableComponent
          columns={beforeApplicationColumns}
          data={voteData?.votes ?? []}
          loading={isLoading || isFetching}
        />
      );
    }
  };

  useEffect(() => {
    if (searchText === '') setResetSearch(true);
    else setResetSearch(false);
  }, [searchText]);

  return (
    <Container>
      <Header
        title="Vote List"
        addLabel="Add"
        onClick={() => history.push(`${redirectLink}/add`)}
      />
      <VoteModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        selectionType={modalData?.selectionType}
        data={transformObjectToArray(voteResultData, modalData?.selections)}
        title={modalData?.modalTitle}
        isLoading={isLoadingResult || isFetchingResult}
        modalId={modalData?.voteModalId}
        voteMemberLink={voteMemberLink}
      />
      <PreviewModal
        isOpen={isPreviewOpen}
        closeModal={closePreview}
        isLoading={loadingPreview || fetchingPreview}
        contentType="vote"
        voteData={previewData}
      />
      <div className="body body-border">
        <div className="column">
          <span
            onClick={() => changeScreen('beforeApplicationVote')}
            className={`${currentScreen === 'before_reception' && 'active'}`}
          >
            {t('Before Application')}
          </span>
          <span
            onClick={() => changeScreen('onGoingVote')}
            className={`${currentScreen === 'accepting' ? 'active' : ''}`}
          >
            {t('Ongoing Vote List')}
          </span>
          <span
            onClick={() => changeScreen('endedVote')}
            className={`${currentScreen === 'reception_end' && 'active'}`}
          >
            {t('Ended Votes')}
          </span>
          <span
            onClick={() => changeScreen('privateVote')}
            className={`${currentScreen === 'private' && 'active'}`}
          >
            {t('Private')}
          </span>
        </div>
        <div className="topRow">
          <Search
            setSearchText={setSearchText}
            setCurrentPage={setCurrentPage}
            placeholder="投票タイトルから検索"
            reset={resetSearch}
          />
          {/* {currentScreen === 'ended' && (
          <CSVWrapper selectedRows={downloadList}>
            <CSVLink
              data={downloadList ?? ''}
              className="download-wrapper"
              onClick={() => {
                setAllDeselect(true);
                setTimeout(() => {
                  setDownloadList([]);
                }, 200);
              }}
            >
              <IonIcon
                icon={
                  downloadList.length === 0
                    ? './assets/icon/disabled-download.svg'
                    : './assets/icon/red-download.svg'
                }
                className="icon"
              />
              <span>CSVダウンロード</span>
            </CSVLink>
          </CSVWrapper>
        )} */}
        </div>

        <div className="table">
          {renderTable()}
          <Pagination
            onPageChange={onPageChange}
            totalPages={voteData?.total ?? 0}
            currentPage={currentPage}
            pageSize={voteData?.votes?.length}
            totalCount={voteData?.votes?.length}
            className="paginate"
          />
        </div>
      </div>
    </Container>
  );
};

export { VoteManagementTemplate };
