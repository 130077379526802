import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Header, TextField } from '../components';

const Container = styled.div`
  .body {
    .memo-description {
      margin-top: 45px;
      width: 85%;
    }
    .button-wrapper {
      margin-top: 15px;
      padding: 12px;
      padding-right: 0px;
      display: flex;
      flex-direction: row-reverse;
      .submit-button {
        width: 250px;
      }
    }
    .detail-info {
      .inquiry-details {
        border-bottom: 1px solid #dedbd5;
        padding: 7px 0px;
      }
      .value {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
      .text {
        display: flex;
        width: 100%;

        .label {
          min-width: 140px;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
`;

const InquiryDetails = () => {
  const { t } = useTranslation();
  const [memo, setMemo] = useState('');

  const DATA: any = {
    Name: 'Jenny Wilson',
    Email: 'nevaeh.simmons@example.com',
    Title: 'Best NFT Seller',
    'Inquiry Details':
      'Lorem ipsum dolor sit amet consectetur. Eu amet tristique ullamcorper non at sit lacus vitae a. Viverra habitasse ut parturient pellentesque suscipit pretium quam. A sapien scelerisque eleifend id cras. Sit at tempor pellentesque aliquet potenti ultrices.',
    'Inquiry Date & Time': '2022年11月12日 14:12',
  };

  return (
    <Container>
      <Header title="View Inquiry" backButton={true} />
      <div className="body body-border">
        <div className="detail-info">
          {Object.keys(DATA).map((item, index) => {
            return (
              <div key={index} className="inquiry-details">
                <div className="text">
                  <p className="label">{`${t(item)}:`}</p>
                  <p className="value">{DATA[item]}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="memo-description">
          <TextField
            type="textarea"
            label="Memo"
            name="Memo"
            maxlength={500}
            value={memo}
            onChange={(e: any) => setMemo(e.target.value)}
            count={true}
          />
        </div>
        <div className="button-wrapper">
          <Button className="submit-button" size="large" onClick={() => console.log(memo)}>
            Save
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default InquiryDetails;
