import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';
import { alertCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '../components';
import { authContext } from '../utils';

const Wrapper = styled.div`
  display: flex;

  .main-body {
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .alert-icon {
      color: #faad14;
      font-size: 100px;
    }

    .title {
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
`;

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  const { authenticated } = authContext();

  useEffect(() => {
    document.title = '404 - Fanique';
  }, []);

  return (
    <Wrapper>
      {authenticated ? <Sidebar /> : ''}
      <div className="main-body">
        <IonIcon icon={alertCircle} className="alert-icon" />
        <p className="title">404 Error</p>
        <span>{t('Page not found')}</span>
      </div>
    </Wrapper>
  );
};

export default NotFound;
