import React, { forwardRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { calendarClearOutline } from 'ionicons/icons';
import { IonIcon, IonLabel, IonNote } from '@ionic/react';

import { convertToNDigits } from '../../../utils';

const Wrapper = styled.div<any>`
  margin-bottom: 10px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 9999;
    padding-top: 5px;
  }
  .react-datepicker__triangle {
    display: none;
  }
  ion-label {
    margin-right: 1em;
    margin-bottom: 0.6em;
    font-weight: 400;
    font-size: 14px;
  }
  .text-content {
    width: 100%;
    .label {
      margin-bottom: 10px;
    }
  }
  input {
    caret-color: black;
    font-size: 13px;
    width: 100%;
    background: ${(props: Props) => (props?.disabled ? '#cecaca' : 'rgba(255, 255, 255, 0.9)')};
    opacity: ${(props: Props) => (props?.disabled ? '0.4' : '1')};
    color: ${(props: Props) => (props?.disabled ? '#000' : 'inherit')};
    border: 1px solid #d9d9d9 !important;
    border-radius: 0.3em;
    padding: 11px 0px 10.5px 8px;
    box-sizing: border-box;
  }
  ion-note {
    color: red;
    padding-top: 0;
    font-weight: 100;
  }
  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .react-datepicker__close-icon {
    padding: 0px !important;
    padding-right: 12px !important;
  }
  .react-datepicker__calendar-icon {
    right: 5px;
    top: 5px;
  }
  .react-datepicker__header__dropdown--select {
    padding: 6px 0px 0px;
  }
  ion-label {
    margin-right: 1em;
    margin-bottom: 0.6em;
    font-weight: 400;
    font-size: 14px;

    .required {
      color: #b80629;
      margin-left: 3px;
    }
  }
  .react-datepicker__header {
    display: ${(props) => (props?.showMonthOnlyWithNoYear ? 'none' : 'block')};
  }
`;

const CustomdateInput = styled.div`
  ion-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    font-size: 18px;
    transform: translateY(-50%);
  }
`;

interface Props {
  name: string;
  label?: string;
  value?: Date | null;
  onChange?: (name: string, value: Date | null) => void;
  error?: any;
  required?: boolean;
  placeholder?: string;
  canClear?: boolean;
  disabled?: boolean;
  showTimeSelect?: boolean;
  showMonthYearPicker?: boolean;
  showMonthOnlyWithYearDropdown?: boolean;
  showMonthOnlyWithNoYear?: boolean;
  // For date range
  hasDateRange?: boolean;
  dateRange?: [Date | null, Date | null];
  onRangeChange?: (name: string, value: [Date | null, Date | null], id?: string | number) => void;
  id?: any;
  minDate?: any;
  maxDate?: any;
  minTime?: any;
  maxTime?: any;
  timeFilter?: any;
  customDateFormat?: string;
}

const DateInput: React.FC<Props> = ({
  name,
  label,
  value,
  onChange,
  error,
  required = false,
  placeholder,
  canClear = true,
  disabled = false,
  showTimeSelect = true,
  showMonthYearPicker = false,
  showMonthOnlyWithYearDropdown = false,
  showMonthOnlyWithNoYear = false,
  // For date range
  hasDateRange = false,
  dateRange,
  onRangeChange,
  id,
  minDate,
  maxDate,
  minTime,
  maxTime,
  timeFilter,
  customDateFormat,
}: Props) => {
  const { t } = useTranslation();
  // For Custom Year Select Header
  const yearRange: number[] = [];
  for (let year = 1900; year <= new Date().getFullYear(); year++) {
    yearRange.push(year);
  }
  // For Custom Month Select
  const renderMonthContent = (month: number, shortMonth: string, longMonth: string) => {
    const tooltipText = `${longMonth}`;
    return <span title={tooltipText}>{`${convertToNDigits(month + 1, 2)} ${t('month')}`}</span>;
  };

  const CustomDatePickerInput = forwardRef(
    ({ value, onClick, showIcon, placeholderText }: any, ref: any) => (
      <CustomdateInput>
        {showIcon && <IonIcon icon={calendarClearOutline} onClick={onClick} />}

        <input
          type="text"
          value={value}
          readOnly
          ref={ref}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
          placeholder={placeholderText}
        />
      </CustomdateInput>
    )
  );

  return (
    <Wrapper disabled={disabled} showMonthOnlyWithNoYear={showMonthOnlyWithNoYear}>
      <div className="text-content">
        <div className="label">
          {label && (
            <IonLabel>
              {t(label)}
              {required ? <span className="required">*</span> : null}
            </IonLabel>
          )}
        </div>
        {hasDateRange ? (
          <DatePicker
            name={name}
            selectsRange={hasDateRange}
            startDate={dateRange ? dateRange[0] : null}
            endDate={dateRange ? dateRange[1] : null}
            onChange={(update: [Date | null, Date | null]) => {
              if (onRangeChange && id) {
                onRangeChange(name, update, id);
              }
              if (onRangeChange) {
                onRangeChange(name, update);
              }
            }}
            isClearable={!disabled && canClear}
            autoComplete="off"
            dateFormat="yyyy/MM/dd"
            disabled={disabled}
            customInput={
              <CustomDatePickerInput
                showIcon={!dateRange || (dateRange[0] === null && dateRange[1] === null)}
                placeholderText={placeholder || 'YYYY年MM月DD日〜YYYY年MM月DD日'}
              />
            }
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
          />
        ) : (
          <DatePicker
            name={name}
            showTimeSelect={showTimeSelect}
            showMonthYearPicker={
              showMonthYearPicker || showMonthOnlyWithYearDropdown || showMonthOnlyWithNoYear
            }
            timeIntervals={5}
            timeCaption="Time"
            dateFormat={
              showMonthYearPicker || showMonthOnlyWithYearDropdown
                ? 'yyyy年MM月'
                : showMonthOnlyWithNoYear
                ? 'MM月'
                : customDateFormat
                ? customDateFormat
                : 'yyyy/MM/dd hh:mm aa'
            }
            selected={value}
            onChange={(date: Date) => {
              if (onChange) onChange(name, date);
            }}
            isClearable={canClear}
            autoComplete="off"
            disabled={disabled}
            filterTime={timeFilter}
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
            customInput={
              <CustomDatePickerInput
                placeholderText={placeholder || 'YYYY年MM月DD日 HH:MM'}
                showIcon={!value}
              />
            }
            // Note: showYearDropdown=true does not work when showMonthYearPicker=true, so creating a custom year picker.
            renderCustomHeader={
              showMonthOnlyWithYearDropdown
                ? ({ date, changeYear }) => (
                    <select
                      value={date.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(Number(value))}
                    >
                      {yearRange?.map((y, idx) => (
                        <option value={y} key={idx}>
                          {y}
                        </option>
                      ))}
                    </select>
                  )
                : showMonthOnlyWithNoYear
                ? () => <></>
                : undefined
            }
            // Custom Month
            renderMonthContent={
              showMonthOnlyWithNoYear || showMonthYearPicker ? renderMonthContent : undefined
            }
          />
        )}

        <div className="bottom-wrapper">
          <IonNote slot="error">{error && t(error)}</IonNote>
        </div>
      </div>
    </Wrapper>
  );
};

export default DateInput;
