import { useQuery } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { authContext, isJSON } from '../utils';
import { NotificationTemplate } from '../components';
import { fetchSingleNotificationTeam, fetchTeamNotifications } from '../services';

const TeamNotifications = () => {
  const { user } = authContext();
  const [searchText, setSearchText] = useState<any>('');
  const [currentScreen, setCurrentScreen] = useState('');
  const [currentPage, setCurrentPage] = useState<any>(1);
  const history = useHistory();
  const { search, pathname } = useLocation();

  const [notificationModalId, setNotificationModalId] = useState<string | undefined>('');

  const {
    data: ownersData,
    isLoading,
    isFetching,
  } = useQuery(
    ['fetchTeamNotification', user?.teamId, currentPage, currentScreen, searchText],
    fetchTeamNotifications,
    {
      enabled: currentScreen !== '',
      select: (response) => {
        return response?.data;
      },
    }
  );

  const {
    data: notification,
    isLoading: isLoadingNotification,
    isFetching: isFetchingNotification,
  } = useQuery(['fetchSingleNotification', notificationModalId], fetchSingleNotificationTeam, {
    enabled: !!notificationModalId,
    select: (response: any) => {
      return {
        ...response.data,
        content: isJSON(response?.data?.content)
          ? JSON.parse(response?.data?.content)
          : response?.data?.content?.replaceAll('\\n', '\n'),
      };
    },
  });

  useEffect(() => {
    const screen = search?.split('?')[1]?.split('=')[1];

    if (pathname === '/notification' && screen === undefined) {
      history.push(`/notification?list=beforePublishing`);
    }
  }, []);

  return (
    <NotificationTemplate
      redirectLink={`/notification`}
      ownersData={ownersData}
      isLoading={isLoading}
      isFetching={isFetching}
      currentScreen={currentScreen}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setCurrentScreen={setCurrentScreen}
      setSearchText={setSearchText}
      searchText={searchText}
      notification={notification}
      isLoadingNotification={isLoadingNotification}
      isFetchingNotification={isFetchingNotification}
      setNotificationModalId={setNotificationModalId}
    />
  );
};

export default TeamNotifications;
