import * as yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { UseMutateFunction, useMutation, useQuery } from 'react-query';

import Form from './Form';
import { authContext, toNormalDate } from '../../../utils';
import { EditMembershipCardPayload } from '../../../types';
import { useImageUpload } from '../../../hooks/useImageUpload';
import { BoxComponent, Loader, TextField, Toast } from '../../atoms';
import { UploadImageUrlResponse } from '../../../types/image-upload';
import { getMembershipUploadImageUrl, uploadMembershipCardImage } from '../../../services';

interface Props {
  data?: any;
  updateCard?: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    EditMembershipCardPayload,
    unknown
  >;
  loading?: boolean;
  updatingCardId?: string;
  setUpdatingCardId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  urlData: any;
  setUrlData: any;
  teamId?: string | null;
}

const Container = styled.div`
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  width: 90%;
  height: auto;

  .image-wrapper {
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .show-on-hover {
      display: none;
    }

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    :after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease;
    }

    :hover {
      :after {
        opacity: 1;
        transition: all 0.5s ease;
      }

      .show-on-hover {
        display: block;
      }
    }

    .hover-items {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .box2 {
        margin-left: 10px;
      }
    }

    .warning {
      position: absolute;
      bottom: 10px;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;

    span {
      color: #7d7d7d;
    }
  }

  .selling-period {
    color: #4d4d4d;
  }

  .updated {
    color: #7d7d7d;
  }

  .last-update {
    .icon {
      background-color: #e2f6f8;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      padding: 6px;
      cursor: pointer;
    }
  }

  .btn,
  .active-btn {
    width: 100%;
    height: 44px;
    background-color: #d9eeef;
    border-radius: 5px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    color: #817f7f;
    margin-top: 15px;
  }

  .active-btn {
    background-color: #0b969e;
    color: #ffffff;
    cursor: pointer;
  }

  .loading-upload {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .note {
    ion-label {
      color: #7d7d7d;
      font-size: 10px;
      opacity: 0.7;
    }
    margin-bottom: 10px;
  }

  .disabled {
    input {
      background-color: #cecaca;
    }
  }
`;

const MembershipCard: React.FC<Props> = ({
  data,
  updateCard,
  loading,
  updatingCardId,
  setUpdatingCardId,
  urlData,
  setUrlData,
  teamId,
}) => {
  const { t } = useTranslation();
  const { user } = authContext();

  // For Image Upload
  const {
    urlPath,
    setUrlPath,
    fileFormat,
    setFileFormat,
    file,
    setFile,
    fetchUpload,
    setFetchUpload,
  } = useImageUpload();

  const [showToast, setShowToast] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<null | string>(null);

  // const onDeleteHandler = () => {
  //   setUploadedImage(null);
  // };

  const handleUrlPath = (defaultUrl: string): string => {
    const path = 'broncos/image/';
    switch (data?.statusNameEnglish) {
      case 'PLATINUM FIRE':
        return `${path}PLATINUM+FIRE-01.png`;
      case 'RED':
        return `${path}RED-01.png`;
      case 'SILVER':
        return `${path}SILVER-01.png`;
      case 'GOLD':
        return `${path}GOLD-01.png`;
      case 'PLATINUM':
        return `${path}PLATINUM-01.png`;
      case 'DIAMOND':
        return `${path}DIAMOND-01.png`;
      default:
        return `${path}${defaultUrl}`;
    }
  };

  const handleImageUpload = (url: string, fileFormat: string, file: any, fetch: boolean) => {
    setUrlPath(handleUrlPath(url));
    setFileFormat(fileFormat);
    setFile(file);
    setFetchUpload(fetch);
  };

  const { data: imageUploadUrl, isFetching: isGettingUrl } = useQuery(
    [
      'getMembershipUploadImageUrl',
      teamId ? teamId : user?.teamId,
      urlPath,
      fileFormat,
      /*
      Even if "data?.id" is not used in this query, we pass it so as to make this query unique. 
      If we don't do so, one same api call will trigger all other same api calls. 
      */
      data?.id,
    ],
    getMembershipUploadImageUrl,
    {
      enabled: fetchUpload,
      select: (response) => {
        return response?.data;
      },
      onSuccess: (data) => {
        handleFormData(data);
      },
    }
  );

  const { mutate, isLoading: isUploading, isSuccess } = useMutation(uploadMembershipCardImage);

  const handleFormData = (data: UploadImageUrlResponse) => {
    const formData = new FormData();
    if (data?.fields) {
      formData.append('key', data?.fields?.key ?? '');
      formData.append('bucket', data?.fields?.bucket ?? '');
      formData.append('X-Amz-Algorithm', data?.fields['X-Amz-Algorithm'] ?? '');
      formData.append('X-Amz-Credential', data?.fields['X-Amz-Credential'] ?? '');
      formData.append('X-Amz-Date', data?.fields['X-Amz-Date'] ?? '');
      formData.append('X-Amz-Signature', data?.fields['X-Amz-Signature'] ?? '');
      formData.append('X-Amz-Security-Token', data?.fields['X-Amz-Security-Token'] ?? '');
      formData.append('Policy', data?.fields['Policy'] ?? '');
      formData.append('Content-Type', data?.fields['Content-Type'] ?? '');
      formData.append('file', file);
      mutate({ url: data?.url, data: formData });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setUploadedImage(`${imageUploadUrl?.url}${imageUploadUrl?.fields?.key}`);
    }
  }, [isSuccess]);

  const validationSchema = yup.object().shape({
    issueLimit: yup
      .string()
      .nullable()
      .matches(/^[0-9]+$/, '番号のみ許可'),
    membershipFee: yup
      .string()
      .matches(/^[0-9]+$/, '番号のみ許可')
      .required('Please enter'),
    annualFee: yup
      .string()
      .matches(/^[0-9]+$/, '番号のみ許可')
      .required('Please enter'),
    ticketSalesURL: yup
      .string()
      .nullable()
      .matches(
        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._~+#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
        'Please enter only URL'
      ),
  });

  const checkErrors = (): boolean => {
    if (Object?.keys(formik?.errors)?.length) return false;
    else return true;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...data,
      ticketSalesURL: data.ticketSalesURL ? atob(data.ticketSalesURL) : null,
      dateRange: [
        moment(data?.sellingPeriodFrom, 'YYYYMMDD').format('YYYY-MM-DD'),
        moment(data?.sellingPeriodTo, 'YYYYMMDD').format('YYYY-MM-DD'),
      ],
    },
    validationSchema,
    onSubmit: (values) => {
      if (setUpdatingCardId) setUpdatingCardId(values?.id);
      if (updateCard) {
        updateCard({
          statusId: values?.id,
          data: {
            sellingPeriodFrom: values?.sellingPeriodFrom,
            sellingPeriodTo: values?.sellingPeriodTo,
            unitToSell: values?.issueLimit ? parseInt(values?.issueLimit) : 0,
            price: parseInt(values?.membershipFee),
            annualFee: parseInt(values?.annualFee),
            ticketSalesURL: values?.ticketSalesURL,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.statusNameEnglish === 'PLATINUM') {
      setUrlData(formik?.values?.ticketSalesURL);
    }
  }, [formik?.values]);

  return (
    <Container>
      <Toast show={showToast} setShow={setShowToast} message={t('Upload images less than 2MB.')} />

      {isGettingUrl || isUploading ? (
        <div className="loading-upload">
          <Loader hasWrapper />
        </div>
      ) : (
        <div className="image-wrapper">
          <>
            {uploadedImage ? (
              <img src={`${uploadedImage}?q=${Date.now()}`} className="img" />
            ) : (
              <img
                src={
                  formik.values?.templateImageS3URL
                    ? `${formik.values?.templateImageS3URL}?q=${Date.now()}`
                    : ''
                }
                className="img"
              />
            )}
            <div className="show-on-hover">
              <div className="hover-items">
                <BoxComponent
                  label="Upload"
                  handleUpload={handleImageUpload}
                  setShowToast={setShowToast}
                />
                {/* {uploadedImage && (
                <BoxComponent
                  label="Delete image"
                  onClick={onDeleteHandler}
                  variant="delete"
                  bgColor="#B80629"
                  icon="/assets/icon/delete.svg"
                  className="box2"
                />
              )} */}
              </div>

              {/* {uploadedImage && (
                <Warning
                  message="Default Image will be restored if this image is deleted"
                  width="90%"
                  className="warning"
                  size="sm"
                />
              )} */}
            </div>
          </>
        </div>
      )}

      <div className="details">
        <p>
          <span>{t('Membership')} :</span> {data?.statusNameEnglish}
        </p>

        <div className="date">
          <Form formik={formik} />
        </div>
        <div>
          <TextField
            name="ticketSalesURL"
            label="Ticket sales URL"
            placeholder="https://xxxx.com/xxxx"
            value={
              formik?.values?.statusNameEnglish === 'PLATINUM FIRE'
                ? urlData
                : formik?.values?.ticketSalesURL
            }
            // value={url}
            onChange={formik.handleChange}
            disabled={formik?.values?.statusNameEnglish === 'PLATINUM FIRE'}
            className={`right-field input ${
              formik?.values?.statusNameEnglish === 'PLATINUM FIRE' && 'disabled'
            }`}
          />
        </div>
        <div className="note">
          <IonLabel>{t('*Please enter only URL')}</IonLabel>
        </div>
        <div className="last-update">
          <p className="updated">
            {t('Last Updated')} : {toNormalDate(data?.updatedAt)}
          </p>
        </div>

        <button
          className={
            data?.statusNameEnglish === 'PLATINUM FIRE' || !checkErrors()
              ? 'btn'
              : updatingCardId === formik?.values?.id && loading
              ? 'btn'
              : 'active-btn'
          }
          onClick={() => formik.handleSubmit()}
          disabled={
            data?.statusNameEnglish === 'PLATINUM FIRE' ||
            (checkErrors() && updatingCardId === formik?.values?.id && loading)
          }
          type="button"
        >
          {t('Save')}
        </button>
      </div>
    </Container>
  );
};

export { MembershipCard };
