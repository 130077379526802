import { API } from './api';
import { AddTeamUserPayload, EditTeamUserPayload, SingleTeamUserResponse } from '../types';
import { QueryFunctionContext } from 'react-query';

export const addNewUser: any = (values: any) => {
  return values;
};

// team
export const addNewTeamUser = (payload: AddTeamUserPayload) => {
  return API.post('/admin/teamUser', payload);
};

export const editTeamUser = (payload: EditTeamUserPayload) => {
  const { id, data } = payload;
  return API.put(`/admin/teamUser?adminId=${id}`, data);
};

export const disableTeamUser = (id?: string) => {
  return API.post(`/admin/teamUser/disableUser?adminId=${id}`);
};

export const enableTeamUser = (id?: string) => {
  return API.post(`/admin/teamUser/enableUser?adminId=${id}`);
};

export const fetchTeamUserList = ({ queryKey }: QueryFunctionContext) => {
  const teamId = queryKey[1];
  const page = queryKey[2];
  const searchWord = queryKey[3];

  return API.get(`/admin/teamUser/list?teamId=${teamId}&page=${page}&searchWord=${searchWord}`);
};

export const fetchSingleTeamUserData = async ({ queryKey }: QueryFunctionContext) => {
  return API.get<SingleTeamUserResponse>(`/admin/teamUser?adminId=${queryKey[1]}`);
};
