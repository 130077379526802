import { IonIcon } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';

interface Props {
  message: string;
  size?: 'sm' | 'md';
  opacity?: 'some' | 'none';
  width?: string;
  className?: string;
}

type StyledProps = Pick<Props, 'size' | 'opacity' | 'width'>;

const StyledWarning = styled.div<StyledProps>`
  width: ${(props: StyledProps) => props?.width ?? '300px'};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props: StyledProps) => (props?.size === 'sm' ? '10px' : '12px')};
  line-height: 17px;
  padding: ${(props: StyledProps) => (props?.size === 'sm' ? '4px 6px' : '6px 8px')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #ffffff;
  border: 1px solid #f18197;
  background: rgba(
    253,
    145,
    145,
    ${(props: StyledProps) => (props?.opacity === 'some' ? '0.4' : '1')}
  );
  .icon {
    font-size: ${(props: StyledProps) => (props?.size === 'sm' ? '16px' : '18px')};
    padding-right: 12px;
  }
`;

export const Warning: React.FC<Props> = ({
  message,
  size = 'sm',
  opacity = 'some',
  width = '300px',
  className,
}: Props) => {
  return (
    <StyledWarning size={size} opacity={opacity} width={width} className={className}>
      <IonIcon icon="./assets/icon/info.svg" className="icon" />
      <span>{message}</span>
    </StyledWarning>
  );
};
