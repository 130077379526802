import styled from 'styled-components';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonImg, IonModal } from '@ionic/react';
import { closeOutline, openOutline } from 'ionicons/icons';

import { Loader } from '../../atoms';
import { TableComponent } from '../TableComponent';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectionType?: 'SingleSelect' | 'MultipleSelect' | 'ImageSelect';
  title?: string;
  subTitle?: string;
  data?: Data[];
  isLoading?: boolean;
  modalId?: string;
  voteMemberLink: string;
}

interface Data {
  options?: string;
  votes?: string;
}

const ModalWrapper = styled(IonModal)`
  --width: 600px;
  --height: 700px;
  @media (max-width: 1024px) {
    --width: 500px;
    --height: 650px;
    .content .table {
      height: 400px !important;
    }
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #7c7c7c;
    .title {
      width: 169px;
      height: 24px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin: 13px 0px;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .content {
    padding: 27px 47px 15px 47px;
    .head {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      .title-wrapper {
        font-weight: 500;
        margin: 0px;
        color: #2966dc;
        text-decoration: none;
        display: flex;
        align-items: center;

        .title {
          border-bottom: 1px solid #2966dc;
        }

        .icon {
          color: #707070;
          font-size: 18px;
          margin-left: 6px;
          margin-top: 6px;
        }
      }
      .subtitle {
        margin: 0px;
      }
    }
    .table {
      border: 1px solid #cccccc;
      height: 480px;
      overflow-y: auto;
      th,
      td {
        padding: 10px 0px 10px 10px;
      }
      .vote-modal-img {
        height: 90px;
        width: 120px;
      }
      tbody td p {
        margin: 0px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      cursor: pointer;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 6px 12px;
      .btn-icon {
        width: 13px;
        height: 14px;
        color: #f4758c;
        margin-right: 8px;
      }
      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin: 0px;
      }
    }
    .btn-gray {
      border: 1px solid #b7b7b7;
      width: 98px;
    }
  }
`;

export const VoteModal = ({
  isOpen,
  closeModal,
  selectionType,
  title,
  subTitle,
  data,
  isLoading = false,
  modalId = '',
  voteMemberLink,
}: Props) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: 'Options',
        accessor: 'options',
        width: '472px',
        Cell: (props: { value: string }) => {
          return (
            <>
              {selectionType === 'ImageSelect' ? (
                props?.value === 'Not Voted' ? (
                  <p>{t(`${props?.value}`)}</p>
                ) : (
                  <IonImg src={props?.value} className="vote-modal-img" />
                )
              ) : selectionType === 'SingleSelect' || selectionType === 'MultipleSelect' ? (
                <p>{t(`${props?.value}`)}</p>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        Header: 'No. of Votes',
        accessor: 'votes',
      },
    ],
    [selectionType]
  );

  return (
    <ModalWrapper isOpen={isOpen} onDidDismiss={closeModal}>
      <div className="heading">
        <p className="title">{t('Vote Result Preview')}</p>
        <IonIcon icon={closeOutline} className="close-icon" onClick={closeModal} />
      </div>
      {isLoading ? (
        <Loader hasWrapper />
      ) : (
        <>
          <div className="content">
            <div className="head">
              {title && (
                <Link to={`${voteMemberLink}/${modalId}`} target="_blank" className="title-wrapper">
                  <span className="title">{title}</span>
                  <span>
                    <IonIcon icon={openOutline} className="icon" />
                  </span>
                </Link>
              )}
              {subTitle && <p className="subtitle">{subTitle}</p>}
            </div>
            <div className="table">
              <TableComponent columns={columns} data={data ?? []} />
            </div>
          </div>
          <div className="footer">
            <button className="btn btn-gray" onClick={closeModal}>
              <p className="label">{t('Close')}</p>
            </button>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};
