import moment from 'moment';

// Converts ISO Date String to Date Object: "2023-02-16T18:04" to "Wed Feb 16 2023 18:04:00 GMT+0000".
export const isoToDateObject = (date?: string | Date): Date | undefined => {
  if (date) return new Date(date);
  else undefined;
};

// Converts Date Object to ISO Date String: "Wed Feb 16 2023 18:04:00 GMT+0000" to "2023-02-16T18:04".
export const dateObjectToISO = (date?: string | Date): string | undefined => {
  if (date && date instanceof Date) return date?.toISOString();
  else if (date) return date;
  else undefined;
};

// For Date Range
export const isoToDateArray = (
  date?: [string | Date, string | Date]
): [Date | null, Date | null] | undefined => {
  if (date?.length === 2)
    return [date[0] ? new Date(date[0]) : null, date[1] ? new Date(date[1]) : null];
  else return undefined;
};

export const dateArrayToISO = (
  date?: [string | Date, string | Date]
): [string, string] | undefined => {
  if (date?.length === 2) {
    return [
      date[0] && date[0] instanceof Date ? date[0]?.toISOString() : date[0],
      date[1] && date[1] instanceof Date ? date[1]?.toISOString() : date[1],
    ];
  } else undefined;
};

export const toNormalDate = (date?: Date | string, format: string = 'YYYY/MM/DD') => {
  return moment(date)?.format(format);
};

export const toJpDate = (date?: Date | string) => {
  return moment(date)?.format('YYYY年MM月DD日 HH:mm');
};
