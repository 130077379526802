import moment from 'moment';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { authContext } from '../utils';
import { useSorting } from '../hooks/useSorting';
import { MemberManagementTemplate } from '../components';
import { fetchTeamMemberList, fetchTeamMemberQueryExecutionId } from '../services';

interface SearchFields {
  searchWord?: string;
  searchExDate?: string;
  searchBirthday?: string;
  searchStatus?: string;
}

const TeamMemberManagement = () => {
  const { user } = authContext();
  const { id } = useParams<{ id: string }>();

  const [currentPage, setCurrentPage] = useState<any>(1);
  const { sortField, sortDirection } = useSorting();
  const [searchFields, setSearchFields] = useState<SearchFields>({});

  // Get QueryExecutionID
  const {
    data: queryData,
    isLoading: queryLoading,
    isFetching: queryFetching,
  } = useQuery(
    [
      'fetchQueryExId',
      id ? id : user?.teamId,
      sortField,
      sortDirection,
      searchFields?.searchWord,
      searchFields?.searchExDate ? moment(searchFields?.searchExDate).format('YYYY-MM') : undefined,
      searchFields?.searchBirthday ? moment(searchFields?.searchBirthday).format('MM') : undefined,
      searchFields?.searchStatus,
    ],
    fetchTeamMemberQueryExecutionId,
    {
      select: (response) => {
        return response?.data;
      },
    }
  );

  const { data, isLoading, isFetching } = useQuery(
    ['fetchMembers', queryData?.queryExecutionId, currentPage],
    fetchTeamMemberList,
    {
      select: (response) => {
        return response?.data;
      },
      enabled: queryData?.queryExecutionId ? true : false,
    }
  );

  return (
    <MemberManagementTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      setCurrentPage={setCurrentPage}
      setSearchFields={setSearchFields}
      currentPage={currentPage}
      queryLoading={queryLoading}
      queryFetching={queryFetching}
      redirectLink={`/${id}/member-management`}
    />
  );
};

export default TeamMemberManagement;
