// import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { VoteManagementTemplate } from '../components';
import { fetchSingleTeamVoteData, fetchTeamVoteList, fetchTeamVoteResultData } from '../services';

interface ModalData {
  voteModalId?: string;
  selectionType?: 'SingleSelect' | 'MultipleSelect' | 'ImageSelect';
  selections?: string;
  modalTitle?: string;
}

// const CSVWrapper = styled.div<{ selectedRows?: string[] }>`
//   pointer-events: ${(props) => (props?.selectedRows?.length === 0 ? 'none' : 'normal')};

//   .download-wrapper {
//     cursor: pointer;
//     color: ${(props) => (props?.selectedRows?.length === 0 ? '#eee' : '#000')};

//     .icon {
//       margin-right: 15px;
//     }

//     span {
//       font-weight: 400;
//       font-size: 16px;
//       text-decoration: underline;
//     }
//   }
// `;

const AdminVoteManagement = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const savedLocation = useRef({ pathname: '', search: '' });
  const { search, pathname } = useLocation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentScreen, setCurrentScreen] = useState('');
  const [searchText, setSearchText] = useState<string>('');

  const [modalData, setModalData] = useState<ModalData>({
    voteModalId: '',
    selectionType: undefined,
    selections: '',
    modalTitle: '',
  });

  // For Preview Modal
  const [votePreviewId, setVotePreviewId] = useState<string | undefined>('');

  const {
    data: voteData,
    isLoading,
    isFetching,
  } = useQuery(
    ['fetchTeamVoteList', id, currentPage, currentScreen, searchText],
    fetchTeamVoteList,
    {
      enabled: currentScreen !== '',
      select: (response) => {
        return response?.data;
      },
    }
  );

  const {
    data: voteResultData,
    isLoading: isLoadingResult,
    isFetching: isFetchingResult,
  } = useQuery(['fetchTeamVoteResultData', modalData?.voteModalId], fetchTeamVoteResultData, {
    enabled: modalData?.voteModalId !== '',
    select: (response) => {
      return response?.data;
    },
  });

  const {
    data: previewData,
    isLoading: loadingPreview,
    isFetching: fetchingPreview,
  } = useQuery(['votePreview', votePreviewId], fetchSingleTeamVoteData, {
    enabled: !!votePreviewId,
    select: (response) => {
      return response?.data;
    },
  });

  const resetPageAndSearch = () => {
    setSearchText('');
    setCurrentPage(1);
  };

  useEffect(() => {
    const screen = search?.split('?')[1]?.split('=')[1];

    if (history.location.search)
      savedLocation.current = {
        pathname: history.location.pathname,
        search: history.location.search,
      };
    if (pathname === `/${pathname.split('/')[1]}/vote-management` && screen === undefined) {
      if (savedLocation.current.search) {
        history.push({
          pathname: savedLocation.current.pathname,
          search: savedLocation.current.search,
        });
      } else {
        history.push(`/${pathname.split('/')[1]}/vote-management?list=beforeApplicationVote`);
      }
    }
    if (screen === 'onGoingVote') {
      setCurrentScreen('accepting');
      resetPageAndSearch();
    } else if (screen === 'endedVote') {
      setCurrentScreen('reception_end');
      resetPageAndSearch();
    } else if (screen === 'privateVote') {
      setCurrentScreen('private');
      resetPageAndSearch();
    } else if (screen === 'beforeApplicationVote') {
      setCurrentScreen('before_reception');
      resetPageAndSearch();
    }
  }, [search]);

  return (
    <VoteManagementTemplate
      redirectLink={`/${id}/vote-management`}
      voteMemberLink={`/${id}/vote-members`}
      currentScreen={currentScreen}
      voteData={voteData}
      isLoading={isLoading}
      isFetching={isFetching}
      voteResultData={voteResultData}
      isLoadingResult={isLoadingResult}
      isFetchingResult={isFetchingResult}
      previewData={previewData}
      loadingPreview={loadingPreview}
      fetchingPreview={fetchingPreview}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      searchText={searchText}
      setSearchText={setSearchText}
      setVotePreviewId={setVotePreviewId}
      modalData={modalData}
      setModalData={setModalData}
    />
  );
};

export default AdminVoteManagement;
