export const fetchInquiryList = () => {
  const data = [
    {
      id: 11,
      inquiry_title: 'Best NFt seller',
      name: 'Dianne Russell',
      email: 'jackson.graham@example.com',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 12,
      inquiry_title: 'Junior Creator',
      name: 'Jane Cooper',
      email: 'michelle.rivera@example.com',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 13,
      inquiry_title: 'NFT Creator',
      name: 'Floyd Miiles',
      email: 'debbie.baker@example.com',
      last_logged_in: '2022年11月12日 14:12',
    },
    {
      id: 14,
      inquiry_title: 'NFT Creator',
      name: 'Senior Creator',
      email: 'curtis.weaver@example.com',
      last_logged_in: '2022年11月12日 14:12',
    },
  ];
  return data;
};
