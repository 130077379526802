import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { addNewTeamUser } from '../services';
import { useToast } from '../utils/ToastContext';
import { AddAdminUserTemplate } from '../components/organisms/CommonTemplate/UserManagement/AddAdminUserTemplate';

const AddAdminUser = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const id = useParams<{ id: string }>();

  const { mutate, isLoading: isMutating } = useMutation(addNewTeamUser, {
    onSuccess: () => {
      showToast('User added successfully', 'success');
      history.push(`/${id.id}/user-management`);
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  const handleFormSubmit = (values: any) => {
    mutate({ ...values, teamId: id.id });
  };

  return <AddAdminUserTemplate isMutating={isMutating} handleSubmit={handleFormSubmit} />;
};

export default AddAdminUser;
