import React from 'react';
import { IonIcon } from '@ionic/react';
import styled from 'styled-components';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

import { usePagination } from '../../../hooks/usePagination';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const List = styled.ul<any>`
  display: flex;
  align-items: center;
  margin: 0;

  .buttons {
    display: flex;
    width: auto;
    list-style: none;
    margin-left: 20px;
  }

  .prevBtn,
  .nextBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
  }

  .prevBtn {
    color: ${(props) => (props.currentPage === 1 ? '#D9D9D9' : '')};
    pointer-events: ${(props) => (props.currentPage === 1 ? 'none' : '')};
  }

  .nextBtn {
    color: ${(props) => (props.currentPage === props.totalPages ? '#D9D9D9' : '')};
    pointer-events: ${(props) => (props.currentPage === props.totalPages ? 'none' : '')};
  }

  li {
    padding: 5px 12px;
    cursor: pointer;
    margin: 0 5px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    user-select: none;

    .icon {
      padding: 0px;
      font-size: 18px;
    }
  }

  .active {
    border: 1px solid #29c8d2;
    color: #29c8d2;
  }
`;

interface Props {
  onPageChange: (val: number) => void;
  totalPages: number; // Total number of Pages
  siblingCount?: string;
  className?: string;
  currentPage: number; // Current active page
  totalCount?: number; // Total number of data
  pageSize: number; // Total number of data in a single page
}

const Pagination: React.FC<Props> = (props) => {
  const {
    onPageChange,
    totalPages,
    siblingCount = 1,
    currentPage,
    totalCount,
    pageSize,
    className,
  } = props;

  const DOTS = '...';

  const paginationRange: any = usePagination({
    currentPage,
    totalPages,
    siblingCount,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <Wrapper className={className}>
      <List currentPage={currentPage} totalPages={totalPages}>
        <span>
          {totalCount}件, {currentPage * pageSize - pageSize + 1} - {currentPage * pageSize}
          件名を表示
        </span>
        <div className="buttons">
          <li onClick={onPrevious} className="prevBtn">
            <IonIcon icon={chevronBackOutline} className="icon" />
          </li>
          {paginationRange?.map((pageNumber: any) => {
            // If the pageItem is a DOT, render the DOTS unicode character

            if (pageNumber === DOTS) {
              return (
                <li key={pageNumber} className="pagination-item dots">
                  {DOTS}
                </li>
              );
            }

            return (
              <li
                key={pageNumber}
                onClick={() => onPageChange(pageNumber)}
                className={currentPage === pageNumber ? 'active' : ''}
              >
                {pageNumber}
              </li>
            );
          })}
          <li onClick={onNext} className="nextBtn">
            <IonIcon icon={chevronForwardOutline} className="icon" />
          </li>
        </div>
      </List>
    </Wrapper>
  );
};

export { Pagination };
