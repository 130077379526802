import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { fetchTeamUserList } from '../services';
import { UserManagementTemplate } from '../components';

const AdminUserManagement = () => {
  const { id } = useParams<{ id: string }>();
  const [searchText, setSearchText] = useState<any>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, isLoading, isFetching } = useQuery(
    ['fetchTeamUser', id, currentPage, searchText],
    fetchTeamUserList,
    {
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <UserManagementTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      redirectLink={`/${id}/user-management`}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setSearchText={setSearchText}
    />
  );
};

export default AdminUserManagement;
