import React from 'react';
import styled from 'styled-components';
import { Redirect, Route } from 'react-router-dom';

import { Loader } from '../components';
import { authContext } from './AuthContext';

const Wrapper = styled.div`
  display: flex;
  width: calc(100% - 18em);
  float: right;

  .main-body {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
`;

const PageWrapper = ({ children }: any) => {
  return (
    <Wrapper>
      <div className="main-body">{children}</div>
    </Wrapper>
  );
};

interface Props {
  component?: React.ElementType | '';
  allowedRoles: string[];
  superAdminComponent?: React.ElementType | '';
  exact?: boolean;
  path: string;
}

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  allowedRoles,
  superAdminComponent: SuperComponent,
  ...rest
}) => {
  const { user, authenticated, loading } = authContext();

  if (loading) {
    return <Loader hasWrapper />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticated) {
          return <Redirect to={{ pathname: '/' }} />;
        }
        if (authenticated) {
          if (user?.role === 'admin' && SuperComponent !== '') {
            return <PageWrapper>{SuperComponent && <SuperComponent {...props} />}</PageWrapper>;
          } else if (allowedRoles.includes(user?.role)) {
            return <PageWrapper>{Component && <Component {...props} />}</PageWrapper>;
          }
        }
        return <Redirect to={{ pathname: '/404' }} />;
      }}
    />
  );
};

export default ProtectedRoute;
