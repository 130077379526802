import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { eyeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import React, { useState, useMemo, useEffect } from 'react';

import { isJSON } from '../../../../utils';
import { PreviewModal } from '../../../../components/molecules/PreviewModal';
import { Header, Search, TableComponent, Pagination } from '../../../../components';

interface Props {
  redirectLink: string;
  ownersData: any;
  isLoading: boolean;
  isFetching: boolean;
  currentScreen: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  searchText: string;
  notification: any;
  isLoadingNotification: boolean;
  isFetchingNotification: boolean;
  setNotificationModalId: React.Dispatch<React.SetStateAction<any>>;
}

const Container = styled.div`
  .table {
    .paginate {
      margin-top: 30px;
    }
    .preview-icon {
      margin-right: 16px;
    }
  }

  .column {
    display: flex;
    color: #898989;
    border-bottom: 1px solid #cccccc;

    span {
      margin-right: 15px;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      padding: 0 15px 10px;
    }

    .active {
      color: #b80629;
      border-bottom: 1px solid #b80629;
    }
  }

  .search-input {
    margin: 20px 20px 20px 0px;
  }
`;

const Span = styled.div`
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
`;

const Email = styled.span`
  white-space: pre-line;
`;

const differentStatus: any = {
  true: '#0fba53',
  false: '#B80629',
  undefined: '#B80629',
};

const Text = styled.div<{ value?: string }>`
  color: ${(props) => differentStatus[`${props?.value?.toLowerCase()}`]};
  font-size: 14px;
`;

const NotificationTemplate = ({
  redirectLink,
  ownersData,
  isLoading,
  isFetching,
  currentScreen,
  currentPage,
  setCurrentPage,
  setCurrentScreen,
  setSearchText,
  searchText,
  notification,
  isLoadingNotification,
  isFetchingNotification,
  setNotificationModalId,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();

  const [resetSearch, setResetSearch] = useState<boolean>(false);

  // For Modal
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        width: '160px',
      },
      {
        Header: 'Contents',
        accessor: 'content',
        Cell: (props: { value: string }) => {
          return (
            <Email>{isJSON(props?.value) ? JSON.parse(props?.value)?.content : props.value}</Email> // remove this condition once all the notifications are deleted
          );
        },
        width: '160px',
      },
      {
        Header: 'Status',
        accessor: (d: any) => d?.status?.toString(),
        Cell: (props: { value: string }) => {
          return (
            <Text value={props?.value}>{props?.value === 'true' ? t('Public') : t('Private')}</Text>
          );
        },
        width: '100px',
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: '80px',
        Cell: (props: { value: string; row: any }) => {
          return (
            <Span>
              <IonIcon
                icon={eyeOutline}
                className="preview-icon"
                onClick={() => {
                  openModal();
                  setNotificationModalId(props?.row?.original?.id);
                }}
              />
              <IonIcon
                icon="./assets/icon/edit.svg"
                onClick={() => {
                  history.push(
                    `${redirectLink}/edit/${props.row.original.id}?list=${currentScreen}`
                  );
                }}
              />
            </Span>
          );
        },
        textAlign: 'center',
      },
    ],
    [redirectLink, currentScreen]
  );

  const changeScreen = (screen: string) => {
    history.push(`${redirectLink}?list=${screen}`);
  };

  const resetPageAndSearch = () => {
    setSearchText('');
    setCurrentPage(1);
  };

  useEffect(() => {
    const screen = search?.split('?')[1]?.split('=')[1];

    if (screen === 'beforePublishing') {
      setCurrentScreen('beforePublishing');
      resetPageAndSearch();
    } else if (screen === 'published') {
      setCurrentScreen('published');
      resetPageAndSearch();
    } else if (screen === 'private') {
      setCurrentScreen('private');
      resetPageAndSearch();
    }
  }, [search]);

  const renderTable = () => {
    if (currentScreen === 'beforePublishing') {
      return (
        <TableComponent
          columns={columns}
          data={ownersData?.notifications ?? []}
          loading={isLoading || isFetching}
        />
      );
    } else if (currentScreen === 'published') {
      return (
        <TableComponent
          columns={columns}
          data={ownersData?.notifications ?? []}
          loading={isLoading || isFetching}
        />
      );
    } else if (currentScreen === 'private') {
      return (
        <TableComponent
          columns={columns}
          data={ownersData?.notifications ?? []}
          loading={isLoading || isFetching}
        />
      );
    }
  };

  useEffect(() => {
    if (searchText === '') setResetSearch(true);
    else setResetSearch(false);
  }, [searchText]);

  return (
    <Container>
      <Header
        title="Notification List"
        addLabel="Add"
        onClick={() => history.push(`${redirectLink}/add`)}
      />
      <PreviewModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        isLoading={isLoadingNotification || isFetchingNotification}
        contentType="notification"
        notificationData={notification}
      />
      <div className="body-border">
        <div className="column">
          <span
            onClick={() => changeScreen('beforePublishing')}
            className={`${currentScreen === 'beforePublishing' && 'active'}`}
          >
            {t('Before Publishing')}
          </span>
          <span
            onClick={() => changeScreen('published')}
            className={`${currentScreen === 'published' ? 'active' : ''}`}
          >
            {t('Published')}
          </span>
          <span
            onClick={() => changeScreen('private')}
            className={`${currentScreen === 'private' && 'active'}`}
          >
            {t('Private')}
          </span>
        </div>
        <Search
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          placeholder={'お知らせタイトルから検索'}
          reset={resetSearch}
        />

        <div className="table">
          <>
            {renderTable()}
            <Pagination
              onPageChange={onPageChange}
              totalPages={ownersData?.total ?? 0}
              currentPage={currentPage}
              pageSize={ownersData?.notifications?.length}
              totalCount={ownersData?.notifications?.length}
              className="paginate"
            />
          </>
        </div>
      </div>
    </Container>
  );
};

export { NotificationTemplate };
