import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';

import { useToast } from '../utils/ToastContext';
import { AccountStatusModal, Header, Loader } from '../components';
import { editAdminUser, fetchSingleAdminUserData } from '../services';
import { SuperAdminUserManagementForm } from '../components/organisms/SuperAdminUserManagementForm';

const Container = styled.div`
  .add-section {
    border: none;
    .add-icon {
      display: none;
    }
    .label {
      color: #b80629;
      font-weight: 400;
      font-size: 16px;
      text-decoration: underline;
    }
  }
`;

const SuperAdminEditUserManagement = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];
  const { showToast } = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    username: '',
    name_sei: '',
    name_mei: '',
    furigana_sei: '',
    furigana_mei: '',
    email: '',
    phone: '',
    department: '',
    password: '',
    // adminType: '',
    teamId: '',
  });

  const validationSchema = yup.object().shape({
    username: yup.string().required('Please enter'),
    name_sei: yup.string().required('Please enter'),
    name_mei: yup.string().required('Please enter'),
    furigana_sei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    furigana_mei: yup
      .string()
      .matches(/^[ぁ-んー]+$/, { message: 'Invalid furigana' })
      .required('Please enter'),
    email: yup.string().email('Invalid email address').required('Please enter'),
    phone: yup.number().required('Please enter').typeError('Please enter a number'),
    department: yup.string().required('Please enter'),
    password: yup.string().min(8, '※Please enter more than 8 characters'),
    // HIDE FOR NOW
    // adminType: yup.string().required('Please enter'),
    // teamId: yup.string().when('adminType', {
    //   is: 'faniqueAdmin',
    //   then: yup.string().notRequired(),
    //   otherwise: yup.string().required('Please enter'),
    // }),
    teamId: yup.string().required('Please enter'),
  });

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        id,
        data: {
          id,
          username: values?.username,
          name_sei: values?.name_sei,
          name_mei: values?.name_mei,
          furigana_sei: values?.furigana_sei,
          furigana_mei: values?.furigana_mei,
          email: values?.email,
          phone: values?.phone,
          department: values?.department,
          password: values?.password,
          // admin_type: values?.adminType,
          teamId: values?.teamId,
        },
      };
      if (!values?.password) delete payload?.data?.password;
      mutate(payload);
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleUserData', id],
    fetchSingleAdminUserData,
    {
      enabled: !!id,
      select: (response) => {
        return response?.data;
      },
    }
  );

  const { mutate, isLoading: isMutating } = useMutation(editAdminUser, {
    onSuccess: () => {
      showToast('User updated successfully', 'success');
      history.push('/user-management');
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  useEffect(() => {
    if (data) {
      setInitialValues({ ...data });
    }
  }, [data]);

  // HIDE FOR NOW
  // const handleModalState = () => {
  //   setIsModalOpen(!isModalOpen);
  // };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <AccountStatusModal isOpen={isModalOpen} onCloseModal={onCloseModal} formik={formik} />
      <Header
        title="Edit user information"
        backButton
        // addLabel="Change Account Status"
        // onClick={handleModalState}
      />
      <div className="body-border">
        {isLoading || isFetching ? (
          <Loader hasWrapper />
        ) : (
          <SuperAdminUserManagementForm formik={formik} type="edit" isMutating={isMutating} />
        )}
      </div>
    </Container>
  );
};

export default SuperAdminEditUserManagement;
