import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import { addNewUser } from '../services';
import { Header, RadioButton, Button } from '../components';

const Container = styled.div`
  .textfield {
    margin-bottom: 20px;

    ion-radio {
      --color-checked: #29c8d2;
    }
  }

  .button-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    ion-button {
      width: 245px;
    }
  }
`;

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 19% 81%;
  grid-gap: 2%;
  .label {
    font-size: 14px;
  }
  .value {
    font-weight: 500;
    font-size: 14px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const Divider = styled.div`
  background-color: #dedbd5;
  height: 1px;
  margin-bottom: 15px;
`;

const EditOwner: React.FC = () => {
  const { t } = useTranslation();

  const initialValues = {
    name: '',
    email: '',
    account_status: '',
    role: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const { mutate }: any = useMutation(addNewUser, {
    onSuccess: (values) => {
      console.log(values);
    },
  });

  return (
    <Container>
      <Header title="Owner Details" backButton />
      <div className="body-border">
        <form onSubmit={formik.handleSubmit}>
          <GridRow>
            <p className="label">{t('Name')}</p>
            <p className="value">Guy Hawkins</p>
          </GridRow>
          <Divider />
          <GridRow>
            <p className="label">{t('Email')}</p>
            <p className="value">guy.hawkins@example.com</p>
          </GridRow>
          <Divider />
          <GridRow>
            <p className="label">{t('Account Status')}</p>
            <RadioButton
              name="account_status"
              required
              options={[
                { value: 'using', label: 'Using' },
                { value: 'suspended', label: 'Suspended' },
              ]}
              onChange={formik.handleChange}
              value={formik.values.account_status}
              className="textfield"
              error={formik.touched.account_status && formik.errors.account_status}
            />
          </GridRow>

          <Divider />
          <GridRow>
            <p className="label">{t('Last Logged in')}</p>
            <p className="value">XXXXXXXX</p>
          </GridRow>
          <Divider />
          <div className="button-wrapper">
            <Button size="large" onClick={formik.handleSubmit}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default EditOwner;
