import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';

import { useToast } from '../utils/ToastContext';
import { editTeamNotification, fetchSingleNotificationTeam } from '../services';
import { EditAdminNotificationTemplate } from '../components/organisms/CommonTemplate/Notification/EditAdminNotificationTemplate';

const TeamEditNotification = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const id = useParams<{ id: string; slug: string }>();
  const { search, pathname } = useLocation();

  const { mutate, isLoading: isMutating } = useMutation(editTeamNotification, {
    onSuccess: async () => {
      showToast('Notification updated successfully', 'success');
      history.push({
        pathname: `/${pathname.split('/')[1]}/notification`,
        search: `?list=${search?.split('?list=')[1] || 'beforePublishing'}`,
      });
    },
    onError: (e: any) => {
      showToast(e?.response?.data?.message || 'Something went wrong', 'error');
    },
  });

  const { data, isLoading, isFetching } = useQuery(
    ['fetchSingleNotification', id.slug],
    fetchSingleNotificationTeam,
    {
      enabled: !!id.slug,
      select: (response) => {
        return response?.data;
      },
    }
  );

  return (
    <EditAdminNotificationTemplate
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      isMutating={isMutating}
      mutate={mutate}
    />
  );
};

export default TeamEditNotification;
