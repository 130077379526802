import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SubMenuObjectBody, SubMenuProps, SubNavType } from '../../../types';

const Container = styled.div`
  .icon-size {
    font-size: 24px;
  }

  .custom-icon {
    font-size: 19px;
  }
`;

const SidebarLink = styled(Link)<{ isactive?: string; to: string }>`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  padding: 0 15px;

  & div {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #26bcc5;
    cursor: pointer;
  }

  background: ${(props) => props?.isactive === 'true' && '#26bcc5'};
`;

const LogoutButton = styled.div`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  padding: 0 15px;

  & div {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: #26bcc5;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const DropdownLink = styled(Link)<{ isselected?: string; to?: any }>`
  background: ${(props) => (props?.isselected === 'true' ? 'white' : '#26bcc5')};
  height: 60px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${(props) => (props?.isselected === 'true' ? '#b80629' : '#f5f5f5')};
  font-size: 18px;
  box-shadow: ${(props) =>
    props?.isselected === 'true' &&
    '-3px -2px 3px #f9f4f4 inset, 2px 2px 3px #cccccc inset, -2px -2px 3px #cccccc, 3px 2px 3px #ffffff'};
  border-radius: ${(props) => props?.isselected === 'true' && '30px 0px 0px 30px'};

  & div {
    display: flex;
    align-items: center;
  }

  .deepSubNav {
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
`;

const Wrap = styled.div<{ isselected?: string }>`
  background-color: #26bcc5;

  a {
    margin-left: 20px;
    width: auto;
    padding-left: 0px;
    margin-left: 15px;

    ion-icon {
      filter: ${(props) =>
        props?.isselected === 'true' &&
        'brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(6615%) hue-rotate(343deg) brightness(72%) contrast(101%)'};
    }
  }
`;

const DownlinkWrap = styled.div<{ isselected?: string }>`
  background-color: #26bcc5;

  a {
    margin-left: 20px;
    ion-icon {
      filter: ${(props) =>
        props?.isselected === 'true' &&
        'brightness(0) saturate(100%) invert(15%) sepia(78%) saturate(6615%) hue-rotate(343deg) brightness(72%) contrast(101%)'};
    }
  }

  .right {
    padding-right: 15px;
  }
`;

const SubMenu = ({ item }: SubMenuProps) => {
  const [subnav, setSubnav] = useState<SubNavType>({
    Fanique: false,
    チーム: false,
  });
  const location = useLocation();
  const { t } = useTranslation();

  const [deepSubnav, setDeepSubnav] = useState<SubNavType>({});

  const showSubnav = (title: string) => {
    setSubnav((prevState: SubNavType) => {
      return { ...prevState, [title]: !prevState[title] };
    });
  };

  const showDeepSubnav = (id: string) => {
    if (Object.keys(deepSubnav).includes(id)) {
      setDeepSubnav((value: SubNavType) => {
        return { ...value, [id]: !value[id] };
      });
    } else {
      setDeepSubnav((value: SubNavType) => {
        return { ...value, [id]: true };
      });
    }
  };

  const logoutHandler = async () => {
    await Auth.signOut();
  };

  useEffect(() => {
    if (item?.title === 'チーム') {
      const result: SubNavType = {};
      item?.subNav?.forEach((team: SubMenuObjectBody) => {
        if (location.pathname.split('/')[1] === team?.id) {
          result[team?.id] = true;
        } else {
          result[team?.id!] = false;
        }
      });
      setDeepSubnav((value: SubNavType) => {
        return { ...value, ...result };
      });
    }
  }, [item]);

  useEffect(() => {
    if (location.pathname.split('/').length === 2) {
      setSubnav((prevState: SubNavType) => {
        return { ...prevState, Fanique: true };
      });
    } else {
      if (location.pathname.split('/')[1] === 'user-management') {
        setSubnav((prevState: SubNavType) => {
          return { ...prevState, Fanique: true };
        });
      } else {
        setSubnav((prevState: SubNavType) => {
          return { ...prevState, チーム: true };
        });
      }
    }
  }, [location.pathname]);

  return (
    <Container>
      {item?.path === '/logout' ? (
        <LogoutButton onClick={logoutHandler}>
          <div>
            <IonIcon
              slot="start"
              icon={item?.icon}
              className={`icon-size ${item?.className && item?.className}`}
            />
            <SidebarLabel>{item?.title}</SidebarLabel>
          </div>
        </LogoutButton>
      ) : (
        <SidebarLink
          to={item?.subNav ? '#' : item?.path ?? ''}
          onClick={() => item?.subNav && showSubnav(item?.title)}
          isactive={item?.subNav && subnav[item?.title] ? 'true' : 'false'}
        >
          <div>
            <IonIcon
              slot="start"
              icon={item?.icon}
              className={`icon-size ${item?.className && item?.className}`}
            />
            <SidebarLabel>{t(item?.title)}</SidebarLabel>
          </div>
          <div>
            {item?.subNav && subnav[item?.title] ? (
              <IonIcon icon={item?.iconOpened} />
            ) : item?.subNav ? (
              <IonIcon icon={item?.iconClosed} />
            ) : null}
          </div>
        </SidebarLink>
      )}
      {subnav[item?.title] &&
        item?.subNav?.map((item: SubMenuObjectBody, index: number) => {
          return (
            <div key={index}>
              <DownlinkWrap
                isselected={
                  item?.path?.split('/')[1] === location.pathname.split('/')[1] ? 'true' : 'false'
                }
              >
                <DropdownLink
                  to={item?.subNav ? '#' : item.path}
                  onClick={() => {
                    item?.subNav && showDeepSubnav(item?.id!);
                  }}
                  isselected={
                    item?.path?.split('/')[1] === location.pathname.split('/')[1] ? 'true' : 'false'
                  }
                  className="right"
                >
                  <div>
                    <IonIcon
                      slot="start"
                      icon={item?.icon}
                      className={`icon-size ${item?.className && item?.className}`}
                    />
                    <SidebarLabel>{item.title}</SidebarLabel>
                  </div>

                  <div>
                    {item?.subNav && deepSubnav[item?.id!] ? (
                      <IonIcon icon={item?.iconOpened} />
                    ) : item?.subNav ? (
                      <IonIcon icon={item?.iconClosed} />
                    ) : null}
                  </div>
                </DropdownLink>
              </DownlinkWrap>
              {item?.subNav &&
                deepSubnav[item?.id!] &&
                item?.subNav?.map(
                  (i: { icon: string; path: string; title: string }, ind: number) => {
                    return (
                      <Wrap
                        key={ind}
                        isselected={
                          i.path.split('/')[1] === location.pathname.split('/')[1] &&
                          i.path.split('/')[2].includes(location.pathname.split('/')[2])
                            ? 'true'
                            : 'false'
                        }
                      >
                        <DropdownLink
                          to={i.path}
                          isselected={
                            i.path.split('/')[1] === location.pathname.split('/')[1] &&
                            i.path.split('/')[2].includes(location.pathname.split('/')[2])
                              ? 'true'
                              : 'false'
                          }
                        >
                          <div className="deepSubNav">
                            <IonIcon slot="start" icon={i?.icon} />

                            <SidebarLabel>{t(i.title)}</SidebarLabel>
                          </div>
                        </DropdownLink>
                      </Wrap>
                    );
                  }
                )}
            </div>
          );
        })}
    </Container>
  );
};

export default SubMenu;
